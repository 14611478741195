<template>
    <div class="top-search">
        <div class="search-bg">
            <video loop="loop" autoplay="autoplay" preload="" muted="muted"
                :src="videoUrl"
                poster="https://a.sinaimg.cn/mintra/pic/2112130400/18weibo_login.png" class="video-poster">
                抱歉，您的浏览器不支持内嵌视频 </video>
        </div>
        <!-- <img class="logo" src="../assets/logo.png" alt=""> -->
        <div class="search-box">
            <div class="search-input-box">
                <img src="../assets/image/pison/sousuoM.png" class="search-icon">
                <input type="text" v-model="inputValue" placeholder="搜索工厂" class="search-input">
            </div>
            <div class="search-button" @click="searchAction">
                搜索
            </div>
        </div>
    </div>


</template>

<script>
import EventBus from '../eventBus';
import $ from 'jquery'
import { config } from '@/config/axios/config'

const { base_url } = config

import {
	getWelComeVideoinfoRequest,
} from '@/api/index.ts'
export default {
    components: {
        
    },
    data() {
        return {
            res: [],
            msg: "搜索工厂",
            inputValue: "",
            videoUrl:"https://a.sinaimg.cn/mintra/pic/2112130543/weibo_login.mp4"
        }
    },
    computed: {
     
    },
    created() {
       this.getWelComeVideoinfo()
    },
    methods: {
        searchAction() {
            $(document).scrollTop(330)
            EventBus.emit('changeMenu', 4);
            EventBus.emit('searchAction', this.inputValue);
        },
        getWelComeVideoinfo() {
            getWelComeVideoinfoRequest().then(resp => {
                console.log("封面视频 resp   ", resp);
                this.videoUrl = base_url + "/upimg/" + resp.info.VideoUrl
                console.log("封面视频  -- ", this.videoUrl);
            }).catch(err => {
                console.error('封面视频 失败:', err);
            });
        }
    }
}


</script>

<style scoped>
.top-search {
    width: 100%;
    height: 330px;
    background-color: #212425;
    position: relative;
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

.search-bg {
    width: 100%;
    overflow: hidden;
    min-width: 2200px;
    height: 330px;
    position: absolute;
    bottom: 0;
    z-index: 0;
}

.video-poster {
    height: 330px;
    width: 100%;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: 0;
    z-index: 0;
    object-fit: cover;
}

.LoginTopNav_searchbox_B8NS4 {
    position: relative;
    margin: 38px 0 0;
    z-index: 1;
}

.LoginTopNav_logoS_wOXns {
    position: absolute;
    top: -10px;
    right: -8px;
    height: 40px;
}

.Logo_main_2QxW2 {
    height: 100%;
    vertical-align: top;
}

.search-box {
    position: relative;
    max-width: 704px;
    width: 80%;
    height: 48px;
    border-radius: 24px;
    overflow: hidden;
    background-color: #212425;
    display: flex;
    margin-bottom: 100px;
}

.search-input-box {
    width: 100%;
    height: 48px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    border-radius: 24px;
    /* background-color: #212425;
    border: 1px solid #CF8D25;
    border-radius: 24px;
    border-color: rgb(204, 104, 0);
    border-color: var(--w-border-color);
    border-radius: 24px;
    border-radius: var(--w-border-radius); */
}

.search-input {
    flex: 2;
    margin-left: 10px;
    height: 48px;
    background-color: transparent;
    font-size: large;
    color: white;
}

.search-icon {
    width: 24px;
    height: 24px;
}

.logo {
    width: 120px;
    height: 120px;
    z-index: 1;
}

.search-button {
    position: absolute;
    top: 0;
    right: 0;
    width: 80px;
    height: 48px;
    padding: 0;
    font-size: 20px;
    font-weight: 400;
    border-top-right-radius: 24;
    border-bottom-right-radius: 24;
    background-color: #D18F25;
    text-align: center;
    line-height: 48px;
    color: #fff;
    z-index: 1;
}
</style>