<template>
    <div id="leftNav" class="left-nav" @click=leftNavClick>
        <div class="title">{{ title }}</div>
        <!-- <ComponyMenu v-if="topMenuIndex == 1"></ComponyMenu> -->
        <Contact v-if="topMenuIndex == 2"></Contact>
        <span v-else class="pointer-cursor" v-for="(item, index) in menues" :key="index" @click="itemAction(index)">
            <router-link :to="`/${item.name}`">
                <div :class="setupItemStyle(index)">{{ item.label }}</div>
            </router-link>
        </span>

    </div>

</template>


<script>
import $ from 'jquery'
import EventBus from '../eventBus';
import { getChatRoomRequest } from '@/api/index.ts'
import Contact from '@/views/contact/contact.vue'
// import ComponyMenu from '@/views/company-category/company-menu.vue'
export default {
    components: {
        Contact,
        // ComponyMenu
    },
    data() {
        return {
            menuSelectIndex: 0,
            title: '首页',
            menues: [
                { name: 'Home', label: '动态' },
                { name: 'Topic', label: '圈子' },
                { name: 'Renmai', label: '大厂人脉' },
                { name: 'Company', label: '精选工厂' },
            ],
            topMenuIndex: 0,
            lianxiren: [],
        }
    },
    methods: {
        leftNavClick: function (event) {
            let even = event || window.event,
                tag = even.target || even.srcElement;
            $("#leftNav ul").children().children().removeClass("hong")
            $(tag).addClass("hong");
        },
        setupItemStyle(index) {
            return index == this.menuSelectIndex ? "hong" : "default-color"
        },
        itemAction(index) {
            this.menuSelectIndex = index
            EventBus.emit('changeLeftMenu', this.menuSelectIndex);
        },
        handleChangeMenu(index) {
            console.log("index", index)
            this.menuSelectIndex = 0
            this.topMenuIndex = index
            switch (index) {
                case 0:
                    this.title = '首页'
                    this.menues = [
                        { name: 'Home', label: '动态' },
                        { name: 'Topic', label: '圈子' },
                        { name: 'Renmai', label: '大厂人脉' },
                        { name: 'Company', label: '精选工厂' },
                    ]
                    break;
                case 1:
                    this.title = '即刻工厂'
                    this.menues = [
                        { name: 'Home', label: '动态圈' },
                        { name: 'Topic', label: '话题组' },
                        { name: 'Topic', label: '收藏' },
                        { name: 'Topic', label: '关注' },
                        { name: 'Topic', label: '消息' }
                    ]
                    break;
                case 2:
                    this.title = '消息'
                    this.menues = [{ name: 'Home', label: '全部消息' },]
                    break;
                case 3:
                    this.title = '个人主页'
                    this.menues = [
                        { name: 'UserHome', label: '我的主页' },
                        { name: 'UserFollow', label: '我的关注' },
                        { name: 'UserCollect', label: '我的收藏' },
                    ]
                    break;
                case 4:
                    this.title = '搜索'
                    this.menues = [
                        { name: 'Search', label: '企业' },
                        { name: 'Search', label: '用户' },
                        { name: 'Search', label: '动态' },
                    ]
                    this.$router.push({
                        name: 'Search',
                    });
                    break;
            }
        },
        getChatRoom() {
            getChatRoomRequest({
                "UserPhone": this.userPhone
            }).then(resp => {
                this.lianxiren = resp.chat_rooms
                console.log("联系人 -- ", this.lianxiren);
            }).catch(err => {
                console.error('联系人 list失败:', err);
            });
        },
    },
    created() {
        // 滚动悬浮左侧的导航栏
        $(document).scroll(function () {
            let res = $(this).scrollTop();
            // window.innerHeight
            if (res >= (330 + 20)) {
                $("#leftNav").css("position", "fixed")
                $("#leftNav").css("top", "60px")
                // $("#plantContent").css("margin-left","60px")
            } else {
                $("#leftNav").css("position", "static")
                // $("#plantContent").css("margin-left","30px")
            }
        });
    },
    mounted() {
        EventBus.on('changeMenu', this.handleChangeMenu);
    },
    beforeDestroy() {
        EventBus.off('changeMenu', this.handleChangeMenu);
    }
}
</script>

<style scoped>
.pointer-cursor {
    cursor: pointer;
}

.title {
    font-size: 24px;
    font-weight: 500;
    color: #000000;
    padding-left: 20px;
    padding-top: 10px;
}

#leftNav {
    width: 180px;
    height: 100vh;
    float: left;
    background-color: #fff;
    border-radius: 5px;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

#leftNav span {
    list-style: none;
    text-align: left;
    width: 180px;
    height: 40px;
    line-height: 40px;
    margin-top: 10px;
    color: #000;
    padding-left: 20px;
}

/* 
#leftNav span a {
    border-radius: 5px;
    display: inline-block;
    width: 180px;
    height: 40px;
    font-size: 18px;
    font-weight: 500;
    padding-left: 20px;
} */

/* #leftNav span a:hover {
    color: #D18F25;

} */

.left-nav {
    padding-top: 10px;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)
}

.default-color {
    color: #000000;
    /* 设置为你想要的颜色 */
}

.hong {
    color: #D18F25;
    /* 设置为你想要的颜色 */
}

#leftNav span:hover {
    background-color: #F2F2F2;
}
</style>