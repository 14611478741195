import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store'; // 如果你也使用了 Vuex
import { Toast } from 'vant';
import 'vant/lib/index.css';
import Vant from 'vant';
import VueAgile from 'vue-agile'

const app = createApp(App);
app.use(VueAgile);
app.use(Vant);
app.use(router);
app.use(Toast);
app.use(store);
app.use(router);
// 在应用启动时检查用户登录状态
store.dispatch('checkUser');
app.mount('#app');
