import { config } from '@/config/axios/config'
const { base_url } = config

export const getImageFullUrl = (imageUr: string) => {
    return base_url + imageUr;
}

export const getUpImageUrl = (imageUrl: string) => {
    return base_url + "/upimg/" + imageUrl;
}

