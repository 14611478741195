import { createStore } from 'vuex';
import * as authUtil from '@/utils/auth'
import { useWebSocketService } from '@/services/websocket';
const imBaseUrl = 'wss://49.235.172.25/chat/';

export default createStore({
  state: {
    user: authUtil.getLoginForm() || null,
    isLoggedIn: !!authUtil.getLoginForm(),
    userPhone: authUtil.getUserPhone() || 88888888888,
    socketService: null,
    topNavIndex: 0,
  },
  mutations: {
    setUser(state, user) { 
      state.user = user;
      state.isLoggedIn = !!user;
      if (user) {
        authUtil.setLoginForm(user);
        const { sendMessage, message,chatUserInfo, closeWebSocket } = useWebSocketService(imBaseUrl, user.UserPhone);
        state.socketService = { sendMessage, message,chatUserInfo, closeWebSocket };
      } else {
        authUtil.removeLoginForm()
        state.socketService?.closeWebSocket();
        state.socketService = null;
      }
    },
    setTopNavIndex(state, index) {
      state.topNavIndex = index;
    },
    clearUser(state) {
      state.user = null;
      state.isLoggedIn = false;
      authUtil.removeLoginForm();
      authUtil.removeUserPhone();
      state.socketService?.closeWebSocket();
      state.socketService = null;
    },
  },
  actions: {
    login({ commit }, user) {
      commit('setUser', user);
    },
    logout({ commit }) {
      console.log("退出登录")
      commit('clearUser');
    },
    checkUser({ commit }) {
      const user = authUtil.getLoginForm();
      if (user) {
        commit('setUser', user);
      } else {
        commit('clearUser');
      }
    },
    updateTopNavIndex({ commit }, index) {
      commit('setTopNavIndex', index);
    },
  },
  getters: {
    user: state => state.user,
    isLoggedIn: state => state.isLoggedIn,
    userPhone: state => state.userPhone,
    socketService: (state) => state.socketService,
    topNavIndex: state => state.topNavIndex,
  },
});
