<template>
  <div class="remen">
    <div 
      :class="[setupMenuItemStyle(item.chater_id)]" 
      v-for="(item, index) in lianxiren" 
      :key="index" 
      @click="setupMenuItemClick(item.chater_id)"
    >
      <div class="renmai-item-content">
        <img class="renmai-item-img" :src="showUpImg(item.chater_head_img)">
        <div class="renmai-item-content-title">
          <span>{{ item.chater_name}}</span>
          <span>{{ item.chater_company_name}}</span>
        </div>
      </div>
      <div class="renmai-item-content-title-bottom">
        <span class="renmai-item-content-descr">
          {{ showLastMessage(item.unread_message_response) }}
        </span>
        <div class="renmai-item-unread-count">{{ item.UnreadMsgNum }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getChatRoomRequest } from '@/api/index.ts';
import { mapState } from 'vuex';
import * as imgUtil from '@/utils/imgUtils';

export default {
  props: {
    contacts: {
      type: Array,
      required: true
    },
    selectedContactIndex: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapState(['isLoggedIn', 'userPhone'])
  },
  data() {
    return {
      lianxiren: [],
      chaterId: 0
    };
  },
  created() {
    this.getChatRoom();
  },
  methods: {
    getChatRoom() {
      getChatRoomRequest({ "UserPhone": this.userPhone })
        .then(resp => {
          this.lianxiren = resp.chat_rooms;
          console.log("联系人 -- ", this.lianxiren);
          if (this.lianxiren.length > 0) {
            this.setupMenuItemClick(this.lianxiren[0].chater_id);
          }
        })
        .catch(err => {
          console.error('联系人 list失败:', err);
        });
    },
    showUpImg(image) {
      return imgUtil.getUpImageUrl(image);
    },
    showLastMessage(unreadMessageResponse) {
      if (!unreadMessageResponse) return '';
      return unreadMessageResponse.latest_message_type === "location" ? "【位置】" :
             unreadMessageResponse.latest_message_type === "image" ? "【图片】" :
             unreadMessageResponse.unread_message_content;
    },
    setupMenuItemStyle(chaterId) {
      return chaterId === this.chaterId ? "remen1-item left-line" : "remen1-item";
    },
    setupMenuItemClick(chaterId) {
      this.chaterId = chaterId;
      console.log(chaterId);
      this.$router.push({
				name: 'ChatDetail',
				params: { chatId:chaterId }
			});
      // this.$emit('change', chaterId);
    }
  }
};
</script>

<style lang="less" scoped>
.remen {
  box-sizing: border-box;
  width: 100%;
  background-color: white;
  overflow: hidden;
  margin-top: 15px;
  padding: 0;
  border-top: solid 1px #efefef;
}

.remen1-item {
  display: flex;
  flex-direction: column;
  border-bottom: solid 1px #efefef;
  margin: 0;
  padding: 10px 10px;
}

.left-line {
  border-left: solid 2px #ff8140;
}

.renmai-item-content {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  box-sizing: border-box;
  margin: 0 0;
  padding: 0;

  .renmai-item-img {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    object-fit: cover;
    margin: 0;
    padding: 0;
  }

  .renmai-item-content-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    padding: 0;
    font-size: 12px;

    :first-child {
      font-size: 16px;
      font-weight: bold;
      color: black;
    }

    color: #c6aa87;
    margin-left: 5px;
  }
}

.renmai-item-content-title-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  margin: 15px 0 0 0;
  padding: 0;
  width: 100%;
}

.renmai-item-unread-count {
  background-color: red;
  padding: 5px;
  border-radius: 100%;
  font-size: 12px;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
}

.renmai-item-content-descr {
  font-size: 14px;
  color: #666;
  margin-top: 0;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
