<template>
	<no-data v-if="isEmpty" description="暂无动态~~" padding="100rpx 0"></no-data>
	<div v-else class="course-content">
		<div class="course-item" v-for="(item, index) in list" :index="index" :key="index">
			<dynamicItem :item="item"></dynamicItem>
		</div>
	</div>
</template>


<script>
import dynamicItem from '../../components/dynamic-item/dynamic-item.vue';
import noData from "@/components/no-data/index"

export default {
	props: {
		list: {
			type: Array,
			default: function () {
				return [];
			}
		},

	},
	components: {
		dynamicItem,
		noData
	},
	computed: {
		isEmpty() {
			return !this.list || this.list.length == 0
		},
	},
	data() {
		return {

		};
	},
	mounted() {
		console.log('list -----: ', this.list);
	},
	onPullDownRefresh() {
		console.log('触发了下拉刷新')
		// this.currentPage = 1;
		// this.getList();
	},
	onReachBottom() {
		console.log('页面触底了')
		// this.currentPage += 1;
		// this.getList();
	},
	methods: {

	}
};
</script>

<style lang="less">
.course-content {
	position: relative;
	display: block;
	padding-top: 12rpx;
	background-color: #F4F6F8;
	// background-color: white;
	padding-bottom: 64rpx;

	.course-item {
		background-color: white;
		margin: 10rpx 20rpx 0 20rpx;
		// box-shadow: 0 1px 5px 1px #0000001A;
		border-radius: 5px;

	}
}
</style>

