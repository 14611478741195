<template>
  <div class="chat-input">
    <div class="top-tool">
      <div class="custom-file-input-item">
        <input class="custom-file-input" type="file" accept="image/*" @change="handleFileChange('image', $event)"
          :disabled="!isImageUploaded" multiple>
        <div class="custom-button bottom-bar-item" :disabled="!isImageUploaded">
          <img class="bottom-bar-icon" src="@/assets/image/pison/tupian.png">
        </div>
      </div>
      <div>
        <img class="bottom-bar-icon" src="@/assets/image/pison/dingwei-3.png" alt="">
      </div>
      <div class="send-button" @click="sendTextMessage">
        发送
      </div>
    </div>
    <textarea v-model="newMessage" class="auto-expand" @input="adjustHeight" ref="textarea"
      placeholder="请输入..."></textarea>
  </div>
</template>

<script>
import { showFailToast, showSuccessToast } from 'vant'
import { mapState } from 'vuex';
import { v4 as uuidv4 } from 'uuid'; // 使用uuid库生成唯一ID
export default {
  name: 'ChatInput',
  components: {
  },
  computed: {
    ...mapState(['socketService','userPhone'])
  },
  data() {
    return {
      newMessage: '',
      isImageUploaded: true,
    };
  },
  methods: {
    handleFileChange(type, event) {
      const files = event.target.files;
      if (files.length === 0) return;

      // const file = files[0];
      // const reader = new FileReader();
      // reader.onload = (e) => {
      //   const content = e.target.result;
      //   this.$emit('send-message', { type, content });
      // }
    },
    sendTextMessage() {
      if (this.newMessage.trim() !== '') {
        var id = uuidv4()
        var timestamp = Date().timestamp

        const msgMap = {
          id: id,
          timestamp: timestamp,
          sender_id: this.userPhone,
          receiver_id: this.socketService.chatId,
          type: "text",
          message: this.newMessage,
          imageurl: this.newMessage,
          location_latitude: 111.1,
          location_longitude: 111.1,
          chater_id: this.socketService.chatId,
          chater_name: this.socketService.chatUserInfo.username,
          chater_head_img:  this.socketService.chatUserInfo.userHeadImg,
          chater_company_id:  this.socketService.chatUserInfo.companyId,
          chater_company_name:  this.socketService.chatUserInfo.companyName
        };

        const jsonMsg = JSON.stringify(msgMap);
        this.socketService.sendMessage(jsonMsg);
        const localMsgMap = {
          id: id,
          timestamp: timestamp,
          sender: this.userPhone,
          receiver: this.socketService.chatId,
          messageType: "text",
          content: this.newMessage,
          imageurl: this.newMessage,
          locationLatitude: 111.1,
          locationLongitude: 111.1,
          chater_id: this.socketService.chatId,
          chater_name: this.socketService.chatUserInfo.username,
          chater_head_img:  this.socketService.chatUserInfo.userHeadImg,
          chater_company_id:  this.socketService.chatUserInfo.companyId,
          chater_company_name:  this.socketService.chatUserInfo.companyName
        };
        this.$emit('send-message', { type: 'text', content: localMsgMap });

        this.newMessage = '';

      } else {
        showFailToast("消息不能为空");
      }
    },
    sendImageMessage(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.$emit('send-message', { type: 'image', content: e.target.result });
        };
        reader.readAsDataURL(file);
      }
    },
    sendLocationMessage() {
      // 示例：发送一个固定的地理位置
      const location = '51.5074, 0.1278'; // 伦敦的经纬度
      this.$emit('send-message', { type: 'location', content: location });
    },
    adjustHeight() {
      const textarea = this.$refs.textarea;
      textarea.style.height = 'auto'; // 先将高度设置为auto以计算内容的实际高度
      textarea.style.height = `${textarea.scrollHeight}px`; // 根据内容高度设置textarea高度
    }
  },
  mounted() {
    this.adjustHeight(); // 确保初始渲染时高度正确
  }
};
</script>

<style scoped>
.auto-expand {
  width: 100%;
  max-height: 120px;
  /* 设置最大高度 */
  overflow-y: auto;
  /* 超过最大高度时显示滚动条 */
  resize: none;
  /* 禁用手动调整大小 */
  box-sizing: border-box;
  /* 包括内边距和边框在内的总高度 */
  padding: 10px;
  border-radius: 5px;
  border: none;
  font-size: 16px;
  line-height: 1.5;
}

.send-button {
  position: absolute;
  right: 10px;
  padding: 5px 10px;
  background-color: #C6AA87;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
}

.top-tool {
  display: flex;
  padding: 10px;
  position: relative;

}

.custom-file-input-item {
  position: relative;
  display: inline-block;
  overflow: hidden;
  margin-right: 30px;
}

.custom-file-input {
  position: relative;
  display: inline-block;
  overflow: hidden;
}

.chat-input-field {
  padding: 10px;
  border: none;
  max-height: 120px;
}

.bottom-bar-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  /* margin-right: 40px; */
}

.bottom-bar-icon {
  width: 24px;
  height: 24px;
}

.chat-input {
  display: flex;
  flex-direction: column;
  height: 180px;
  border-top: solid 1px #ddd;
}

input[type="text"] {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

button {
  margin-left: 10px;
  padding: 10px;
  border: none;
  background-color: #C6AA87;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

input[type="file"] {
  display: none;
}

button:hover {
  background-color: #C6AA87;
}
</style>