<template>
	<!-- -->
	<div class="company-home">

		<NavBar>
			<template v-slot:title>
				详情
			</template>
		</NavBar>
		<NetImage class="bgc_pic" :src="getImageFullUrl(companyInfo.ComHeadImg)"/>
		<!-- :style="{transform:`translateY(${translateY}px)`}" -->
		<div class="content">
			<text class="company-name">{{ companyInfo.companyName }}</text>
			<text class="company-companyNameDetail">{{ companyInfo.CompanyNameDetail }}</text>
			<div class="tag">
				<text class="tagChild">{{ typeInfo.ComPanyRegisName }}</text>
				<text class="tagChild">{{ typeInfo.CompanyOneTypeName }}</text>
				<text class="tagChild">{{ typeInfo.CompanySecendTypeName }}</text>
			</div>
			<div class="company-star">
				<div class="company-star-content">
					<!-- <img class="star-image" src="../../static/images/leftbean.png" mode=""> -->
					<text>{{ companyInfo.CompanyStar }}</text>
					<!-- <img class="star-image" src="../../static/images/rightbean.png" mode=""> -->
				</div>
				<text class="star-title">综合评分</text>
			</div>
			<text class="company-companyIntroduce">{{ companyInfo.CompanyIntroduce }}</text>
			<div class="scroll">
				<div class="box1">
					<div class="item">
						<text>2015</text>
						<text>创立</text>
					</div>
					<div class="item">
						<text>{{ companyInfo.CompanyShowTimes }}</text>
						<text>查阅</text>
					</div>
					<div class="item">
						<text>{{ companyInfo.CompanyCollectTimes }}</text>
						<text>粉丝</text>
					</div>
					<div class="item">
						<text>5</text>
						<text>案例</text>
					</div>
					<div class="item">
						<text>5</text>
						<text>员工</text>
					</div>
				</div>
			</div>
			<div class="address" @click="addressAction">
				<!-- <img src="../../static/images/LocationIcon.png" mode="aspectFit"></img> -->
				<text>{{ addressInfo.ProviceName + "·" + addressInfo.CompanyCAdressText }}</text>
			</div>
			<div class="wonderful-case-title">
				<text class="wonderful-case">精彩案例</text>
				<!-- <div class="wonderful-case-look-all">
					<text @click="lookAllCase">查看全部</text>
					<img class="wonderful-case-look" src="@/assets/image/pison/youcejiantou.png">
				</div> -->
			</div>
			<!-- <div class="newpeopleView">
					<div class="item-content">

						<div class="swiper-item" v-for="(item, index) in companyCases" :key="index"
							@click="previewImges(item.CompanyShowImages)">
							<div class="item-bg">
								<img class="item-image" :src="getImageFullUrl(item.CompanyShowImages[0])">
								<div class="item-info-text">
									<text>{{ item.CompanyShowImages.length }}</text>
								</div>

							</div>
							<text class="item-info-title">{{ item.CompanyShowTitle }}</text>
						</div> -->
			<!-- <div v-if="showLoadMore">
							<div class="load-more" @click="loadMore">查看全部案例</div>
						</div> -->
			<!-- </div>
			</div> -->
			<RefreshLoadMore :refresh="onRefresh" :loadMore="onLoadMore" :finished="finished">
				<CompanyCase></CompanyCase>
			</RefreshLoadMore>

			<div class="follow-button-bg">
				<div class="follow-button" @click="buttonAction">
					关注
				</div>
			</div>
		</div>
		<VueEasyLightbox :visible="visible" :imgs="currentImages" :index="currentIndex" @hide="handleHide" />

	</div>

</template>

<script>
import {
	companydetailRequest,
	getCompanyShowRequest
} from "@/api/index.ts"
import { config } from '@/config/axios/config'
import NavBar from "@/components/navbar/navbar.vue";
import VueEasyLightbox from 'vue-easy-lightbox';
import CompanyCase from '@/views/company-home/compny-case/compny-case.vue'
import RefreshLoadMore from '@/components/RefreshLoadMore/RefreshLoadMore.vue';
import NetImage from "@components/NetImage/NetImage.vue";

const { base_url } = config
export default {
	// props: {
	// 	companyId: {
	// 			type: any,
	// 			required: false 
	// 		}

	// 	},
	components: {
		NavBar,
		VueEasyLightbox,
		RefreshLoadMore,
		CompanyCase,
		NetImage
	},
	data() {
		return {
			isModalVisible: false,
			visible: false,
			currentIndex: 0,
			currentImages: [],
			// 用户操作
			clientData: {
				// 用户手指Y轴
				clientMoveY: '', //按下
				clientEndY: '', //抬起
				// 页面Y轴
				pageY: ''
			},
			translateY: -230,
			startY: 0,
			companyId: 0,
			sheight: 562, //高度
			isPlay: 0, //是否播放动画
			companyInfo: {},
			typeInfo: {},
			addressInfo: {},
			companyCases: [],
			showLoadMore: false,
			navbarTop: 0,
			disableTrigger: false,
			isRight: false,
			finished: false
		}
	},
	onPageScroll(e) { //nvue暂不支持滚动监听，可用bindingx代替
		console.log(e);
		this.clientData.pageY = e.scrollTop;
	},
	mounted() {

	},
	//生命周期函数
	created() {
		// 获取传过来的数据，并赋值给本页面中的数据
		if (this.$route.params.companyId) {
			this.companyId = this.$route.params.companyId
			this.companydetail();
			this.onRefresh();
		}
	},
	watch: {
		companyId(newCompanyId, oldCompanyId) {
			console.log('Type ID changed from', oldCompanyId, 'to', newCompanyId);
			// this.companydetail();
			// this.getCompanyShowRequest();
		}
	},
	onShow() {
		this.disableTrigger = false;
		this.isRight = false;
	},
	methods: {
		previewImges(companyShowImages) {
			let photoList = companyShowImages.map((item) => {
				return this.getImageFullUrl(item);
			});
			this.currentImages = photoList;
			this.currentIndex = 0;
			this.visible = true;
		},
		handleHide() {
			this.visible = false;
		},
		showModal() {
			this.isModalVisible = true;
		},
		hideModal() {
			this.isModalVisible = false;
		},
		navBack() {
			// uni.navigateBack();
		},
		onScroll(event) {
			if (event.detail.deltaX < 0) {
				// console.log('右滑');
				this.isRight = true;
			} else {
				// console.log('左滑');
				this.isRight = false;
			}
		},
		addressAction() {
			let params = {
				"lat": this.addressInfo.CompanyCAdressYpositon,
				"lon": this.addressInfo.CompanyCAdressXpositon,
				"name": this.companyInfo.CompanyNameDetail
			};
			let query = Object.keys(params)
				.map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
				.join('&');
			// uni.navigateTo({
			// 	url: `/pages/map/map-page?${query}`
			// });
		},
		buttonAction() {
			// uni.showToast({
			// 	title: "请下载App操作",
			// 	icon: 'none'
			// });
		},
		touchStart(event) {
			this.startY = event.touches[0].pageY;
		},
		move(e) {
			let moveY = e.touches[0].pageY;

			console.log("move", moveY, this.startY);
			// 先判断用户是否到达了顶部
			if (this.clientData.pageY < 1) {
				// 本次手指移动的位置和上次移动的位置对比 <1 证明用户在下拉
				// if (this.clientData.clientMoveY - e.changedTouches[0].clientY < 1) 
				if (moveY > this.startY) {
					// 拿到下拉的距离
					let distance = this.clientData.clientMoveY - e.changedTouches[0].clientY;
					console.log(distance);
					// 最大下拉到800px ,放大1.8倍大小
					if (this.sheight < 1862) {
						this.sheight -= distance;
						// this.sheight = 1862
						// 执行动画
						this.isPlay = 1;
					} else {
						//超过800或者1.8倍就return
						return;
					}
				} else {
					// 获取触摸移动的Y坐标
					const touchY = e.touches[0].clientY;
					// 计算新的translateY值
					const newTranslateY = moveY - this.startY
					// this.translateY + touchY;
					// 更新translateY值
					this.translateY = -230 + newTranslateY;
					console.log("偏移", this.translateY);
				}
				// 上边的代码执行结束之后再把本次手指的位置赋值给data中,用来下一次对比
				this.clientData.clientMoveY = e.changedTouches[0].clientY;
			} else {
				setTimeout(() => {
					this.sheight = 562;
					// 并把动画的class名取消掉
					this.isPlay = 2;
				}, 350);
			}
		},
		// 手指抬起
		end(e) {
			// this.clientData.clientEndY = e.changedTouches[0].clientY;
			// 当图片的高度大于400的时候手指抬起再调用函数
			if (this.sheight > 562) {
				// 执行动画
				this.isPlay = 1;
				// 动画结束后把顶部图片的高度和放大比例该会原来的值
				setTimeout(() => {
					this.sheight = 562;
					// 并把动画的class名取消掉
					this.isPlay = 2;
					this.translateY = -230;
				}, 350);
			}
		},
		handleChange(event) {
			if (this.disableTrigger == false && this.isRight == true) {
				this.disableTrigger = true;
				this.isRight = false;
				// uni.navigateTo({
				// 	url: "/pages/company-home/compny-case/compny-case?companyid=" + this.companyid,
				// });
			}
		},
		lookAllCase() {
			console.log("查看全部案例", this.companyId);
			this.$router.push({
				name: 'CompanyCase',
				params: { companyId: this.companyId }
			});
		},
		getImageFullUrl(imageUrl) {
			return base_url + imageUrl;
		},

		companydetail() {
			companydetailRequest({
				"Companyid": this.companyId,
				'UserPhone': "18180802828"
			}).then(resp => {
				this.companyInfo = resp.Companyinfo;
				this.typeInfo = resp.TypeInfo;
				this.addressInfo = resp.AddressInfo;
				console.log("公司详情 -- ", this.companyInfo);
			}).catch(err => {
				console.error('公司详情失败:', err);
			});
		},

		onRefresh() {
			return new Promise((resolve) => {
				this.finished = false;
				this.currentPage = 1;
				this.getCompanyShowRequest().then(resolve);
			});
		},
		onLoadMore() {
			return new Promise((resolve) => {
				this.currentPage += 1;
				this.getCompanyShowRequest().then(resolve);
			});
		},

		getCompanyShowRequest() {
			return getCompanyShowRequest({
				"companyid": this.companyId,
				'pagenumber': this.currentPage
			}).then(resp => {
				console.log("案例 -- ", resp);
				if (resp.info.length <= 0) {
					this.finished = true;
				}
				if (this.currentPage == 1) {
					this.topList = resp.info;

				} else {
					if (resp.info.length > 0) {
						this.topList = this.topList.concat(resp.info);
					} else {
						// uni.showToast({
						// 	title: "暂无更多数据",
						// 	icon: 'none'
						// });
					}
				}
				console.log("案例 topList -- ", this.topList);
			}).catch(err => {
				console.error('公司案例失败:', err);
				this.finished = true;
			});
		}
	}
}
</script>

<style lang="less" scoped>
.pages {
	width: 100%;
	color: #191919;
	display: flex;
	flex-direction: column;
}
.company-home {
	width: 100%;
	display: flex;
	flex-direction: column;
}
.scroll {
	white-space: nowrap;
	// width: calc(100% - 20px);
	padding: 20px 0;
	width: 100%;
	height: 60px;
	/* 你可以根据需要调整高度 */
	overflow-x: auto;
	overflow-y: hidden;
	white-space: nowrap;

	/* 防止内容换行 */
	.box1 {
		white-space: nowrap;
		display: inline-flex;
		align-items: center;

		.item {
			display: inline-flex;
			flex-direction: column;
			width: 80px;
			height: 60px;
			margin-right: 20px;
			padding: 5px;
			background-color: white;
			justify-content: center;
			align-items: center;
			border: 1px solid #0000001F;
			border-radius: 6px;
			font-size: 14px;
			box-shadow: #0000001F;
			color: #000000DD;

			:first-child {
				font-size: 20px;
				font-weight: bold;
				color: #C6AA87;
			}
		}
	}
}

.bgc_pic {
	width: 100%;
	height: 462px;
	/* height: 100%; */
	position: relative;
	/* margin-top: -100px; */
}

.nav-left-back {
	position: absolute;
}

.nav-left-back-icon {
	margin-right: 16px;
}

.bgc_pic>image {
	width: 100%;
}

.isPlay {
	animation: big 0.2s 1 alternate ease-in-out forwards;
}

@keyframes big {
	100% {
		/* margin-top: 100px; */
	}
}

.isPlaasf {
	animation: biasd 0.5s 1 alternate ease-in-out forwards;
}

@keyframes biasd {
	0% {
		/* margin-top: 100px; */
	}

	100% {
		margin-top: 0;
	}
}

.isPlatwp {
	animation: bis 0.2s 1 alternate ease-in-out forwards;
}

@keyframes bis {
	100% {
		/* height: 1862px; */
	}
}

.isPlafh {
	animation: dgsdf 0.5s 1 alternate ease-in-out forwards;
}

@keyframes dgsdf {
	0% {
		/* height: 1862px; */
	}

	100% {
		height: 562px;
	}
}

.content {
	background-color: white;
	display: flex;
	position: relative;
	flex-direction: column;
	width: 100%;
	padding-left: 20px;
	padding-right: 20px;
	box-sizing: border-box;
}

.company-name {
	color: black;
	font-size: 25px;
	padding-top: 15px;
}

.company-companyNameDetail {
	color: grey;
	font-size: 15px;
	padding-top: 10px;
}

.tag {
	display: flex;
	flex-direction: row;
	/* position: absolute; */
	padding-top: 15px;
}

.tagChild {
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 5px;
	padding-bottom: 5px;
	margin-right: 10px;
	background-color: #ccc;
	color: white;
	font-size: 14px;
	text-align: center;
}

.company-star {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: absolute;
	right: 10px;
	top: 40px;
}

.star-image {
	width: 100px;
	height: 100px;
}

.company-star-content {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 40px;
	color: #C6AA87;
}

.star-title {
	color: #00000030;
	font-size: 20px;
}

.company-companyIntroduce {
	font-size: 15px;
	color: black;
	padding-top: 20px;
}

// .scroll {
// 	white-space: nowrap;
// 	width: calc(100% - 20px);
// 	padding: 20px 20px 20px 20px;

// 	.box1 {
// 		white-space: nowrap;
// 		display: inline-flex;
// 		align-items: center;

// 		.item {
// 			display: inline-flex;
// 			flex-direction: column;
// 			width: 80px;
// 			height: 60px;
// 			margin-right: 20px;
// 			padding: 5px;
// 			background-color: white;
// 			justify-content: center;
// 			align-items: center;
// 			border: 1px solid #0000001F;
// 			border-radius: 6px;
// 			font-size: 14px;
// 			box-shadow: #0000001F;
// 			color: #000000DD;

// 			:first-child {
// 				font-size: 20px;
// 				font-weight: bold;
// 				color: #C6AA87;
// 			}
// 		}
// 	}
// }

.address {
	height: 30px;
	margin-top: 10px;
	padding-top: 5px;
	width: fit-content;
	padding-bottom: 5px;
	padding-left: 25px;
	padding-right: 25px;
	border-radius: 20px;
	background-color: #C6AA8755;
	display: flex;
	align-items: center;

	image {
		width: 30px;
		height: 30px;
		margin-right: 10px;
	}

	text {
		font-size: 15px;
		color: white;
	}
}

.wonderful-case-title {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.wonderful-case {
	color: black;
	font-size: 18px;
	// margin-left: 30px;
	margin-top: 15px;
	margin-bottom: 15px;
}

.wonderful-case-look-all {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 14px;
}

.wonderful-case-look {
	width: 14px;
}

.newpeopleView {
	white-space: nowrap;
	width: calc(100% - 20px);
	padding: 0 20px 20px 10px;
	width: 100%;
	/* 你可以根据需要调整高度 */
	overflow-x: auto;
	overflow-y: hidden;
	white-space: nowrap;
}

.newpeopleViewTitle {
	font-size: 38px;
	color: #000000;
}

.newpeopleViewSubTitle {
	font-size: 30px;
	color: #999;
	padding-top: 10px;
	padding-bottom: 30px;
}

.swiper {
	// height: px;
	// white-space: nowrap;/
	display: flex;
	justify-content: start;
	width: 100%;

}

.item-content {
	display: flex;
	padding-left: 8px;
	align-items: start;
}

.item-bg {
	display: flex;
	flex-direction: column;
	align-items: start;
	justify-content: start;
	position: relative;
}

.swiper-item {
	display: flex;
	flex-direction: column;
	justify-content: start;
	box-shadow: 1px 1px 3px 3px rgba(0, 0, 0, 0.03);
	margin: 10px;
	padding-bottom: 10px;
}


.item-image {
	height: 120px;
}

.item-info-text {
	padding: 30px 20px;
	color: white;
	position: absolute;
	left: 10px;
	bottom: 10px;
	display: flex;
	flex-direction: column;
	font-size: 16px;
	font-weight: bold;
}

.item-info-title {
	font-size: 16px;
	color: black;
	margin: 10px;
	text-align: center;
}

.load-more {
	background-color: white;
	color: #C6AA87;
	font-size: 14px;
	writing-mode: vertical-lr;
	/* 从上到下，从左到右 */
	/* 或者 */
	/* writing-mode: vertical-rl; 从上到下，从右到左 */
	text-align: center;
	/* 可根据需要调整文本水平对齐方式 */
	height: 200px;
	/* 可根据需要调整文本容器的高度 */
	line-height: 1.5;
	/* 可根据需要调整文本行高 */
}

.follow-button-bg {
	display: flex;
	align-items: center;
	justify-content: center;
	padding-bottom: 30px;
}

.follow-button {
	font-size: 15px;
	flex: 1;
	background-color: #C6AA87;
	color: white;
	border-radius: 20px;
	height: 44px;
	margin-left: 30px;
	margin-right: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
}
</style>