<template>
    <textarea 
      v-model="content" 
      class="auto-expand" 
      :style="{
        borderColor: borderColor,
        backgroundColor: backgroundColor,
        borderRadius: borderRadius
      }" 
      @input="adjustHeight" 
      ref="textarea" 
      placeholder="写下你的评论...">
    </textarea>
  </template>
  
  <script>
  export default {
    props: {
      borderColor: {
        type: String,
        default: '#ccc',
      },
      backgroundColor: {
        type: String,
        default: 'white',
      },
      borderRadius: {
        type: String,
        default: '5px',
      },
      defaultHeight: {
        type: String,
        default: 'auto'
      }
    },
    data() {
      return {
        content: ''
      };
    },
    methods: {
      adjustHeight() {
        const textarea = this.$refs.textarea;
        textarea.style.height = 'auto'; // 先将高度设置为auto以计算内容的实际高度
        textarea.style.height = `${textarea.scrollHeight}px`; // 根据内容高度设置textarea高度
        if (this.content.trim() === '') {
          this.updateHeight(); // 如果内容为空，恢复默认高度
        }
      },
      updateHeight() {
        const textarea = this.$refs.textarea;
        textarea.style.height = this.defaultHeight; // 恢复到默认高度
      },
      clearContent() {
      this.content = ''; // 清空内容
    }
    },
    watch: {
      content(newVal) {
        if (newVal.trim() === '') {
          this.updateHeight(); // 如果内容为空，恢复默认高度
        }
      }
    },
    mounted() {
      this.updateHeight(); // 确保初始渲染时高度正确
    }
  };
  </script>
  
  <style scoped>
  .auto-expand {
    width: 100%;
    max-height: 200px; /* 设置最大高度 */
    overflow-y: auto; /* 超过最大高度时显示滚动条 */
    resize: none; /* 禁用手动调整大小 */
    box-sizing: border-box; /* 包括内边距和边框在内的总高度 */
    padding: 10px;
    border: 1px solid;
    font-size: 16px;
    line-height: 1.5;
  }
  </style>
  