<template>
	<view class="y-no-data" :style="innerStyle">
		<img class="image-wrapper" src="@/assets/image/pison/nodata_state.png" />
		<view class="desc-wrapper">{{ description }}</view>
	</view>
</template>

<script>
export default {
	props: {
		// 图片下方的描述文字
		description: {
			type: String,
			default: "暂无数据"
		},
		// 容器内边距
		padding: {
			type: String,
			default: "40px 0"
		}
	},
	computed: {
		innerStyle() {
			return {
				padding: this.padding
			}
		}
	},
	data() {
		return {
		}
	}
}
</script>

<style lang="less" scoped>
.y-no-data {
	text-align: center;
	width: 100%;
	height: 600px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: white;
	// border-radius: 5px;

	.image-wrapper {
		width: 300px;
	}

	.desc-wrapper {
		font-size: 16px;
		padding: 12px 32px;
		color: #666;
		text-align: center;
	}
}
</style>
