<template>
	<div class="component-container">
		<!-- <NavBar>
			<template v-slot:title>
				公司案例
			</template>
		</NavBar> -->
		<no-data v-if="isEmpty" description="暂无案例~~" padding="100px 0"></no-data>
			<van-grid :column-num="3" :gutter="0.1">
				<van-grid-item v-for="(item, index) in topList" :key="index"
				@click="previewImges(item.CompanyShowImages)" >
					<div class="swiper-item" >
						<div class="item-bg">
							<img class="item-image" :src="getImageFullUrl(item.CompanyShowImages[0])" mode="aspectFill">

							<div class="item-info-text">
								<text>{{ item.CompanyShowImages.length }}</text>
							</div>

						</div>
						<div class="item-info-title">
							<text class="single-line">
								{{ item.CompanyShowTitle }}
							</text>
						</div>
					</div>
				</van-grid-item>
			</van-grid>
		<VueEasyLightbox :visible="visible" :imgs="currentImages" :index="currentIndex" @hide="handleHide" />


		<!-- </uni-grid-item>
		</uni-grid> -->
	</div>
</template>

<script>
import { config } from '@/config/axios/config'

const { base_url } = config

import {
	getCompanyShowRequest
} from "@/api/index.ts"
import noData from "@/components/no-data/index.vue"
// import NavBar from "@/components/navbar/navbar.vue";
import VueEasyLightbox from 'vue-easy-lightbox';

export default {
	components: {
		noData,
		// NavBar,
		VueEasyLightbox
	},
	props: {
		// 当前选中内容区域的下标
		activeIndex: {
			type: Number,
			default: 0
		}
	},
	data() {
		return {
			topList: [],
			companyid: 0,
			currentPage: 1,
			isModalVisible: false,
			visible: false,
			currentIndex: 0,
			currentImages: []
		}
	},
	computed: {
		isEmpty() {
			return !this.topList || this.topList.length == 0
		},
	},
	//生命周期函数
	created() {
		// 获取传过来的数据，并赋值给本页面中的数据
		if (this.$route.params.companyId) {
			this.companyid = this.$route.params.companyId
			this.getCompanyShow();
		}
	},
	methods: {
		getCompanyShow() {
			getCompanyShowRequest({
				"companyid": this.companyid,
				'pagenumber': this.currentPage
			}).then(resp => {
				console.log("案例 -- ", resp);
				if (this.currentPage == 1) {
					this.topList = resp.info;

				} else {
					if (resp.info.length > 0) {
						this.topList = this.topList.concat(resp.info);
					} else {
						// uni.showToast({
						// 	title: "暂无更多数据",
						// 	icon: 'none'
						// });
					}
				}
				console.log("案例 topList -- ", this.topList);
			}).catch(err => {
				console.error('公司案例失败:', err);
			});
		},
		getImageFullUrl(imageUrl) {
			return base_url + imageUrl;
		},
		previewImges(imageUrls) {
			let photoList = imageUrls.map((item) => {
				return this.getImageFullUrl(item);
			});
			this.currentImages = photoList;
			this.currentIndex = 0;
			this.visible = true;
		},
		handleHide() {
			this.visible = false;
		},
		onRefresh() {
		console.log('触发了下拉刷新')
		this.finished = false
		this.currentPage = 1;
		this.getCompanyShow();
	},
	onLoadMore() {
		console.log('页面触底了')
		this.finished = false
		this.currentPage += 1;
		this.getCompanyShow();
	},
	},
	
}
</script>

<style lang="less" scoped>
.component-container {
	position: relative;
	display: block;
	// padding-top: 12px;
	
	box-sizing: border-box;
}

.item-image {
	height: 140px;
	object-fit: cover;
	width: 100%;
}

.swiper-item {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: start;
	// box-shadow: 1px 1px 3px 3px rgba(0, 0, 0, 0.03);
	// padding-bottom: 16px;
	// width: 100%;
	// padding: 0;
	// margin: 0;
	// height: 100%;
	// margin: 5px;
}


.item-info-text {
	padding: 30px 20px;
	color: white;
	position: absolute;
	left: 10px;
	bottom: 90px;
	display: flex;
	flex-direction: column;
	font-size: 18px;
	font-weight: bold;
}

.item-info-title {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 16px;
	padding: 0 5px;
	color: black;
	height: 44px;
	text-align: center;
}

.single-lineX {
	white-space: nowrap;
	/* 保证文本不换行 */
	overflow: hidden;
	/* 超出容器部分隐藏 */
	text-overflow: ellipsis;
	/* 超出部分显示省略号 */
	display: -webkit-box;
	overflow: hidden;
	text-overflow: ellipsis;
	word-wrap: break-word;
	white-space: normal !important;
	max-lines: 1;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
}
</style>