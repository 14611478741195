<!-- src/components/CommentModal.vue -->
<template>
    <div class="comment-view" v-if="visible">
        <div class="comment-view-content">
            <!-- <span class="close" @click="close">&times;</span>
            <h2>发表评论</h2> -->
            <!-- <AutoExpandTextarea v-model="comment" placeholder="说点什么吧~" /> -->
            <AutoExpandTextarea  v-model="comment" placeholder="说点什么吧~"  borderColor="#F5F5F5" backgroundColor="#F5F5F5" defaultHeight="48px" borderRadius="24px" ref="autoTextarea" />
            <button v-if="comment != ''" class="button" @click="submitComment">评论</button>
        </div>
    </div>
</template>

<script>
import AutoExpandTextarea from '../AutoExpandTextarea/AutoExpandTextarea.vue';
import { showFailToast } from 'vant'

export default {
    props: {
        visible: {
            type: Boolean,
            default: true
        }
    },
    components: {
        AutoExpandTextarea
    },
    data() {
        return {
            comment: ''
        }
    },
    watch: {
        visible(newVal) {
            if (newVal) {
                document.body.classList.add('no-scroll');
            } else {
                document.body.classList.remove('no-scroll');
            }
        }
    },
    methods: {
        close() {
            this.$emit('close');
        },
        submitComment() {
            if (!this.comment.trim()) {
                showFailToast('请输入评论内容');
                return;
            }
            this.$emit('submit', this.comment);
            this.comment = '';
            this.$refs.autoTextarea.clearContent();
        }
    },
    beforeDestroy() {
        // document.body.classList.remove('no-scroll');
    }
}
</script>

<style>
.no-scroll {
    overflow: hidden;
}

.comment-view {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
	margin-bottom: 15px;
    /* background-color: #F5F5F5; */
	/* display: flex;
	align-items: center;
	border-radius: 22px;

	font-size: 16px;
	color: #999999; */
    /* position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); */
}
.button {
    color: white;
    background-color: #C6AA87;
}
.comment-view-content {
    /* background-color: #fefefe; */
    /* padding: 20px; */
    width: 100%;
    /* background-color: #F5F5F5;
	display: flex;
	align-items: center;
	border-radius: 22px;
	margin-top: 15px;
	margin-bottom: 15px;
	font-size: 16px;
	color: #999999; */
    /* border: 1px solid #888; */
    /* width: 80%;
    max-width: 600px;
    border-radius: 5px; */
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

button {
    padding: 10px 20px;
    margin-top: 20px;
    background-color: #C6AA87;
   color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    float: right;
}

button:hover {
    background-color: #C6AA87;
    }
</style>
