<template>
	<div>
		<!-- <navbar leftText1="JiKeGongChang" :leftText2="info.TitleWords" /> -->
		<!-- <div class="search" :style="{ marginTop: navbarTop + 'px' }" @click="searchAction"> -->
		<!-- <uni-icons type="search" size="20" color="#ccc"></uni-icons> -->
		<!-- <text>Search</text> -->
		<!-- </div> -->
		<div class="conetent">
			<text class="title">{{ info.BeanWordsLoginIndex }}</text>
			<div class="userHeadCard" @click="companyAction">
				<NetImage class="header-image" :src="getImageFullUrl(info.SuggestCompany?.ComAdverImg)"/>
				<div class="infoTopText">
					<text>{{ info.SuggestCompany?.CompanyNameDetail }}</text>
					<text>{{ info.SuggestCompany?.CompanyAdressCIty }}</text>
				</div>
				<div class="tag">
					<text class="tagChild">{{ info.SuggestCompany?.PartnerTypeName }}</text>
					<text class="tagChild">{{ info.SuggestCompany?.CompanyOneType }}</text>
					<text class="tagChild">{{ info.SuggestCompany?.CompanySecendType }}</text>
				</div>
			</div>
			<div class="threeIconInfo">
				<div class="threeIconInfoChild">
					<span class="iconfont1">&#xe622;</span>
					<text>{{ info.BeanWordsOne }}</text>
				</div>
				<div class="threeIconInfoChild">
					<span class="iconfont1">&#xe62d;</span>
					<text>{{ info.BeanWordsTwo }}</text>
				</div>
				<div class="threeIconInfoChild">
					<span class="iconfont1">&#xe62b;</span>
					<text>{{ info.BeanWordsThree }}</text>
				</div>
			</div>

			<!-- <div class="adInfo" @click="adAction">
				<img class="adInfo-image" :src="getImageFullUrl(info.LoginImg)" mode="aspectFill">
				<text class="adInfoText">{{ info.BeanWordsLoginIndex }}</text>
			</div>

			<div class="lineView">
				<div class="line1"></div>
				<div class="text">智造·中国</div>
				<div class="line2"></div>
			</div> -->
		</div>
	</div>
</template>

<script setup>
// import {
// 	onLoad,
// 	onShow,
// } from "@dcloudio/uni-app";
// import navbar from "../../components/navbar/navbar.vue";
import {
	onBeforeMount,
	onMounted,
	ref
} from 'vue'
import {
	getOnepageInfoRequest
} from '@/api/index.ts';
import { config } from '@/config/axios/config'
import NetImage from "@components/NetImage/NetImage.vue";

const { base_url } = config
var list = ref([])
var info = ref({})

const showLoadMore = ref(true)

const handleChange = (event) => {
	loadMore()
}

const loadMore = () => {

}



function getOnepageInfo() {
	getOnepageInfoRequest({
		"UserPhone": "13198511889"
	}).then(resp => {
		info.value = resp.data;
		console.log("推荐信息 info -- ", info.value);
	}).catch(err => {
		console.error('推荐信息失败:', err);
	});
}

function getImageFullUrl(imageUrl) {
	return base_url + imageUrl;
}

function getImageUrl(imageUrl) {
	return base_url + "/upimg/" + imageUrl;
}

function searchAction() {
	// uni.navigateTo({
	// 	url: "/pages/search/search"
	// });
}

function adAction() {
	let title = info.value.TitleWords;
	console.log("title ---- ", title);
	// uni.navigateTo({
	// 	url: "/pages/company-category/company-category?titleWords=" + title
	// });
}

function companyAction() {
	// uni.navigateTo({
	// 	url: "/pages/company-home/company-home?companyid=" + info.value.SuggestCompany.CompanyId
	// });
}

function userAction(item) {
	// uni.navigateTo({
	// 	url: "/pages/user/user-home/user-home?userId=" + item.userPhone
	// });
}
onBeforeMount(() => {
	console.log("onLoad ===");
	getOnepageInfo();
	// onePageSuperUser();
});

</script>

<style lang="less" scoped>
@charset "utf-8";
@import '@/assets/css/rightNav.css';

.conetent {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: white;
	border-radius: 5px;
	margin-bottom: 10px;
}

.title {
	color: red;
	font-weight: bold;
	font-size: 18px;
	margin-top: 10px;
}

.userHeadCard {
	position: relative;
	margin-top: 10px;
	margin-bottom: 10px;
	width: 100%;
	display: flex;
	justify-content: center;

}

.infoTopText {
	padding: 15px 10px;
	color: white;
	position: absolute;
	top: 0;
	left: 20px;
	display: flex;
	flex-direction: column;
	font-size: 16px;
	font-weight: 500;

	:first-child {
		margin-bottom: 20px;
		font-size: 18px;
	}
}

.header-image {
	width: calc(100% - 20px);
}

.tag {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	width: calc(100% - 20px);
	// padding-left: 30px;
	// padding-right: 30px;
	left: 10px;
	bottom: 40px;
}

.tagChild {
	padding-left: 10px;
	padding-right: 10px;
	margin-right: 10px;
	background-color: #FFFFFF8A;
	color: white;
	font-size: 14px;
	text-align: center;
}

.threeIconInfo {
	display: flex;
	flex-direction: row;
	width: calc(100% - 20px);
	margin: 20px 0;
	justify-content: space-between;
}

.threeIconInfoChild {
	color: #000000DD;
	font-size: 10px;
	font-weight: bold;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;

	text {
		margin-left: 10px;
	}
}


.adInfo {
	position: relative;
	width: 100%;
	margin-top: 10px;
	margin-bottom: 10px;
	display: flex;
	justify-content: center;
}

.adInfoText {
	color: white;
	padding: 0 10px;
	position: absolute;
	top: 100px;
	display: flex;
	align-items: center;
	font-size: 14px;
	text-align: center;
	font-weight: bold;
	background-color: #FFFFFF3D
}

.adInfo-image {
	height: 195px;
	width: calc(100% - 80px);
}

.lineView {
	display: flex;
	width: calc(100% - 80px);
	padding: 20px 40px;
	margin-top: 20px;
	margin-bottom: 10px;
	align-items: center;
}

.line1 {
	flex: 1;
	height: 1px;
	background: linear-gradient(to left, #C6AA87, #fff);
}

.line2 {
	flex: 1;
	height: 1px;
	background: linear-gradient(to right, #C6AA87, #fff);
}

.text {
	margin: 0 10px;
	color: #C6AA87;
	font-size: 24px;
}

.newpeopleView {
	display: flex;
	flex-direction: column;
	width: calc(100% - 80px);
}

.newpeopleViewTitle {
	font-size: 42px;
	color: #000000;
}

.newpeopleViewSubTitle {
	font-size: 30px;
	color: #00000061;
	padding-top: 10px;
	padding-bottom: 30px;
}

.swiper {
	// height: px;
	white-space: nowrap;
	width: 100%;
	/* 设置swiper容器的高度 */
	margin-bottom: 30px;
}

.item-content {
	display: flex;

}

.item {
	margin-right: 16px;
	height: fit-content;
	align-items: center;
	justify-content: center;
	position: relative;
}

.item-image {
	height: 360px;
	max-width: calc((100vw - 8*6px) / 3);
}


.item-info-text {
	padding: 10px;
	color: white;
	position: absolute;
	left: 0px;
	bottom: 10px;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	font-size: 34px;
	font-weight: bold;

	.item-info-text-company {
		font-size: 30px;
		margin-top: 5px;
	}
}

.load-more {
	background-color: white;
	color: #C6AA87;
	font-size: 14px;
	writing-mode: vertical-lr;
	/* 从上到下，从左到右 */
	/* 或者 */
	/* writing-mode: vertical-rl; 从上到下，从右到左 */
	text-align: center;
	/* 可根据需要调整文本水平对齐方式 */
	height: 200px;
	/* 可根据需要调整文本容器的高度 */
	line-height: 1.5;
	/* 可根据需要调整文本行高 */
}
</style>