<template>
    <div>
        <button @click="startCountdown" :disabled="isCounting">
            {{ isCounting ? `${remainingTime}s后重新获取` : '获取验证码' }}
        </button>
    </div>
</template>

<script>
import { sendCodeRequest } from '@/api/index.ts'

export default {
    props: {
        phoneNumber: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            remainingTime: 0,
            intervalId: null,
        };
    },
    computed: {
        isCounting() {
            return this.remainingTime > 0;
        },
        isPhoneNumberValid() {
            const phoneRegex = /^[1][3-9][0-9]{9}$/;
            return phoneRegex.test(this.phoneNumber);
        },
    },
    methods: {
        startCountdown() {
            if (!this.isPhoneNumberValid) {
                return;
            }
            this.remainingTime = 60; // 设置倒计时时间为60秒
            this.intervalId = setInterval(() => {
                if (this.remainingTime > 0) {
                    this.remainingTime--;
                } else {
                    clearInterval(this.intervalId);
                }
            }, 1000);

            // 发送验证码请求
            this.sendVerificationCode();
        },
        async sendVerificationCode() {
            // 在这里添加你的发送验证码逻辑
            console.log('验证码已发送');
            try {
                const res = await sendCodeRequest({ "UserPhone": this.phoneNumber })
                console.log(res);
            } catch (error) {
                console.log('验证码发送', error);
            }
        }
    },
    beforeDestroy() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    },
};
</script>

<style scoped>
button {
    box-sizing: border-box;
    font-size: 12px;
    width: 100px;
    height: 44px;
    background-color: #f2f2f5;
    border: 1px solid #efefef;
    border-radius: 2px;
    color: black;
    margin: 0;
    padding: 0;
}

button:hover {
    cursor: pointer;
    background-color: #9c9c9c;
}
</style>
