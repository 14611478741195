
<template>
	<div class="company-category">
		<!-- <navbar leftText1="JiKeGongChang" :leftText2="title" /> -->
		<!-- <div class="nav-placeholder" :style="{marginTop: navbarTop + 'px'}"> -->
		<!-- </div> -->
		<!-- <y-tabs v-model="activeIndex"  sticky :offsetTop="offsetTop" color="#C6AA87" titleActiveColor="#C6AA87" titleInactiveColor="#9E9E9E" @change="tabChange">
			<y-tab v-for="(title, index) in tabs" :title="title" :key="index">
				<div class="line"></div> -->
		<companyList :isCategory="true" :list="companys"></companyList>
		<!-- </y-tab>
		</y-tabs> -->
	</div>
</template>

<script>
// import navbar from "../../components/navbar/navbar.vue";
import companyList from "@/views/company-list/company-list.vue";
// import storage from "../../utils/storage";
import {
	getOneTypeListRequest,
	getTypeCompanyListRequest
} from '@/api/index.ts'
// import {
// 	getRect,
// 	getHeaderHeight
// } from "/utils/uitls.js"

export default {
	props: {
		currentTypeId: {
			type: String,
			required: false // 如果参数是可选的，可以设置为 false
		}
	},
	data() {
		return {
			types: [],
			companys: [],
			currentPage: 1,
			tabs: [],
			activeIndex: 0,
			title: "",
			offsetTop: 0,
			navbarTop: 0

		};
	},
	computed: {
		isEmpty() {
			return !this.companys || this.companys.length == 0
		},
	},
	components: {
		companyList,
	},
	onReady() {
		// this.setOffsetTop();
	},
	mounted() {
		// this.title = option.titleWords;
		// console.log("titleWords", this.title);
		this.getTypeCompanyList();
	},
	watch: {
		currentTypeId(newCurrentTypeId, oldCurrentTypeId) {
			console.log('Type ID changed from', newCurrentTypeId, 'to', oldCurrentTypeId);
			this.getTypeCompanyList();
		}
	},
	methods: {
		async setOffsetTop() {
			// H5端通过查询节点信息获取导航栏高度作为吸顶距离
			// // #ifdef H5
			// const rect = await getRect('.navBar', this)
			// this.navHeight = rect?.height || 0;
			// // #endif

			// // 非H5端通过状态栏+导航栏高度计算offset
			// // #ifndef H5
			// this.navHeight = getHeaderHeight().navHeight;
			// #endif		
		},

		// getOneTypeList() {
		// 	getOneTypeListRequest().then(resp => {
		// 		console.log("行业一级分类 -- ", resp);
		// 		this.types = resp.data.info;
		// 		this.tabs = this.types.map((item) => item.BaseTypeName)
		// 		if (this.types.length > 0) {
		// 			this.currentTypeId = this.types[0].id;
		// 			this.getTypeCompanyList();
		// 		}
		// 		console.log("行业一级分类 types -- ", this.tabs);
		// 	});
		// },
		getTypeCompanyList() {
			getTypeCompanyListRequest({
				"page": this.currentPage,
				"pagecount": 10,
				"UserPhone": "18180802828"
			}).then(resp => {
				console.log("行业一级分类下公司 -- ", resp);
				if (this.currentPage == 1) {
					this.companys = resp.data.companyinfoHot;
				} else {
					if (resp.data.companyinfoHot.length > 0) {
						this.companys = this.companys.concat(resp.data.companyinfoHot);
					}
				}
				console.log("行业一级分类下公司 topList -- ", this.companys);
			}).catch(err => {
				console.error('行业一级分类下公司 list失败:', err);
			});
		}
	},
	// 页面滚动触发事件
	onPageScroll(e) {
		//页面滚动事件
		// uni.$emit('onPageScroll', e)
	},
	onPullDownRefresh() {
		console.log('触发了下拉刷新')
		this.currentPage = 1;
		this.getTypeCompanyList();
	},
	onReachBottom() {
		console.log('页面触底了')
		this.currentPage += 1;
		this.getTypeCompanyList();
	},
}
</script>

<style lang="less">
.company-category {
	position: relative;
	background-color: white;
}

.line {
	border-top: 0.5px solid #F2F3F5;
	/* 分割线颜色和宽度 */
	// margin: 10px 0;
	/* 上下间距 */

}

// .y-tab-virtual {
//     position: absolute !important;
// 	top: 100px;
//     // flex-shrink: 0;
//     width: 100%;
// 	height:100vh;
// 	background-color: red;
// }

// .content-wrap{
//     height:100vh;
// 	// background-color: red;
// }</style>