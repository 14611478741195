<template>
	<div class="component-container">
		<no-data v-if="isEmpty" description="暂无话题~~" padding="100px 0"></no-data>
		<RefreshLoadMore v-else :refresh="onRefresh" :loadMore="onLoadMore" :finished="finished">
			<van-grid :column-num="3">
				<van-grid-item v-for="(item, index) in topList" :key="index"
					@click="topicAction(item.TopicId, item.TopicTitle)">
					<img class="item-image" :src="getImageFullUrl(item.TopicImage)">
					<div class="item-info-text">
						<text>{{ item.TopicTitle }}</text>
					</div>
				</van-grid-item>
			</van-grid>
		</RefreshLoadMore>
	</div>
</template>

<script>
import { config } from '@/config/axios/config'
const { base_url } = config
import {
	getTopicListRequest,
} from '@/api/index.ts'
import noData from "@/components/no-data/index"
import RefreshLoadMore from '../../components/RefreshLoadMore/RefreshLoadMore.vue';
export default {
	components: {
		noData,
		RefreshLoadMore
	},
	data() {
		return {
			topList: [],
			finished: false,
			currentPage: 1
		}
	},
	computed: {
		isEmpty() {
			return !this.topList || this.topList.length == 0
		},
	},
	mounted() {
		this.getTopicList();
	},
	methods: {
		topicAction(topicId, topicTitle) {
			// uni.navigateTo({
			// 	url: "/pages/topic/topic-home/topic-home?topicId=" + topicId
			// });
			console.log("话题 topicTitle -- ", topicTitle);
			this.$router.push({
				name: 'TopicDetail',
				params: { topicId:topicId, topicTitle:topicTitle }
			});
		},
		getTopicList() {
		return getTopicListRequest({
				"page_number": this.currentPage
			}).then(resp => {
				// console.log("话题 -- ", resp);
				if (resp.info.length <= 0) {
					this.finished = true
				}
				if (this.currentPage == 1) {
					this.topList = resp.info;
				} else {
					if (resp.info.length > 0) {
						this.topList = this.topList.concat(resp.info);
					} else {
						this.finished = true
					}
				}
				// console.log("话题 topList -- ", this.topList);
			}).catch(err => {
                console.error('话题 list失败:', err);
            });
		},
		getImageFullUrl(imageUrl) {
			return base_url + imageUrl;
		},
		onRefresh() {
			return new Promise((resolve) => {
				this.finished = false;
				this.currentPage = 1;
				this.getTopicList().then(resolve);
			});
		},
		onLoadMore() {
			return new Promise((resolve) => {
				this.currentPage += 1;
				this.getTopicList().then(resolve);
			});
		},
	},

}
</script>

<style lang="less" scoped>
.component-container {

	display: block;
	padding-top: 12px;
	border-radius: 5px;
	margin: 0;
	padding: 0;
}

.item-image {
	background-color: white;
	padding: 8px;
	height: 240px;
	object-fit: cover;
	width: 100%;
}

.item-info-text {
	padding: 30px 20px;
	color: white;
	position: absolute;
	left: 10px;
	bottom: 10px;
	display: flex;
	flex-direction: column;
	font-size: 24px;
	font-weight: bold;
}
</style>