<template>
    <div ref="videoContainer" class="video-container">
        <video ref="video" :src="videoSrc" controls @play="handlePlay"></video>
    </div>
</template>

<script>
export default {
    props: {
        videoSrc: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            observer: null,
        };
    },
    mounted() {
        this.observer = new IntersectionObserver(this.handleIntersection, {
            threshold: 0.5,
        });
        this.observer.observe(this.$refs.videoContainer);
    },
    methods: {
        handleIntersection(entries) {
            entries.forEach(entry => {
                if (!entry.isIntersecting) {
                    if (this.$refs.video) {
                        this.$refs.video.pause();
                    }
                }
            });
        },
        handlePlay() {
            this.$emit('video-playing', this.$refs.video);
        },
    },
    beforeUnmount() {
        if (this.observer) {
            this.observer.disconnect();
        }
    },
};
</script>

<style scoped>
.video-container {
    width: 100%;
    height: 450px;
}

video {
    width: 100%;
    height: 450px;
}
</style>
