<template>
    <div id="headNav">
        <div class="headNav1">
            <div class="left-search">
                <h1 class="fl"><a href="#" :style="{backgroundImage:'url('+logoUrl+')'}">即刻工厂</a></h1>
                <div class="kuang fl" @click=getClick>
                    <img src="../assets/image/pison/sousuoM.png" class="nav-search-icon">
                    <input v-model="inputValue" id="inputSoSuo" :placeholder=inputMessage() @keyup.enter="handleEnter" >
                    <!-- 隐藏部分 -->
                    <!-- <div class="searchHide">
                        <div class="reso">
                            查看完整的热搜榜>>
                        </div>
                        <ul>
                            <li><a href="#"><i></i><span style="background-color: red;">1</span> {{ res[1].title }}</a>
                            </li>
                            <li><a href="#"><span style="background-color: #ff8140;">2</span> {{ res[2].title }}</a>
                            </li>
                            <li><a href="#"><span>3</span> {{ res[3].title }}</a></li>
                            <li><a href="#"><span>4</span> {{ res[4].title }}</a></li>
                            <li><a href="#"><span>5</span> {{ res[5].title }}</a></li>
                            <li><a href="#"><span>6</span> {{ res[6].title }}</a></li>
                            <li><a href="#"><span>7</span> {{ res[7].title }}</a></li>
                            <li><a href="#"><span>8</span> {{ res[8].title }}</a></li>
                            <li><a href="#"><span>9</span> {{ res[9].title }}</a></li>
                            <li><a href="#"><span>10</span> {{ res[10].title }}</a></li>
                        </ul>
                    </div> -->
                </div>
            </div>
            <div class="nav-menu fr">
                <!-- <ul> -->
                <router-link to="/">
                    <div :class=setupMenuItemStyle(0) @click="setupMenuItemClick(0)">
                        <img class="item-image" src="@/assets/image/pison/shouye.png">
                    </div>
                </router-link>
                <!-- <router-link to="/CompanyCategory">
                    <div :class=setupMenuItemStyle(1) @click="setupMenuItemClick(1)">
                        <img class="item-image" src="@/assets/image/pison/fenlei.png">
                    </div>
                </router-link> -->
                <router-link to="/Home">
                    <div :class=setupMenuItemStyle(2) @click="setupMenuItemClick(2)">
                        <img class="item-image" src="@/assets/image/pison/xiaoxi.png">
                    </div>
                </router-link>
                
               
                <!-- <div v-if="isLoggedIn" :class=setupMenuItemStyle(5) @click="setupMenuItemClick(5)">
                    <img class="edit-item-image" src="@/assets/image/pison/bianji-2.png">
                </div> -->

            </div>
            <div class="slog">
                <text>
                    即刻工厂,打造全产业链优势
                </text>
            </div>
           <div class="user">
            <router-link  v-if="isLoggedIn" to="/UserHome">
                    <div :class=setupMenuItemStyle(3) @click="setupMenuItemClick(3)">
                        <img class="user-image" :src="getImageUrl(user.UserHeadImg)">
                    </div>

                </router-link>
           </div>
            <div class="right-space">

            </div>
        </div>

        <PublishModal :visible="isModalVisible" @close="hideModal" @submit="handleCommentSubmit" />

    </div>
</template>


<script>
import $ from 'jquery'
import remen from '@/./assets/remen.json'
import EventBus from '../eventBus';
import { mapState, mapActions, mapGetters } from 'vuex';
import { config } from '@/config/axios/config'
import PublishModal from '@/components/PublishModal/PublishModal.vue';
const { base_url } = config
import {
	getLogoIconinfoRequest,
} from '@/api/index.ts'

export default {
    components: {
        PublishModal
    },
    data() {
        return {
            res: [],
            msg: "搜索工厂",
            menuSelectIndex: 0,
            isModalVisible: false,
            visible: false,
            inputValue:"",
            logoUrl:"@/assets/image/pison/shouye.png"

        }
    },
    computed: {
        ...mapState(['isLoggedIn']), // 映射 isLoggedIn 到组件的计算属性
        ...mapGetters(['user']),
    },
    methods: {
        ...mapActions(['logout']),
        ...mapActions(['updateTopNavIndex']),
        getWelComeLogoinfo() {
            getLogoIconinfoRequest().then(resp => {
                console.log("logo resp   ", resp);
                this.logoUrl = base_url + "/upimg/" + resp.info.VideoUrl
                console.log("logo  -- ", this.videoUrl);
            }).catch(err => {
                console.error('logo 失败:', err);
            });
        },
        // 输入框的点击事件
        getClick: function () {
            // $(".searchHide").css("display", "block")
            // $("#inputSoSuo").attr("placeholder", this.res[1].title)
            if (this.menuSelectIndex != 4 ) {
                this.setupMenuItemClick(4)
            }
            
        },
        handleEnter() {
            EventBus.emit('searchAction', this.inputValue);
        },
        inputMessage() {
            return this.msg
            // this.res[1].title
        },
        hideModal() {
            this.isModalVisible = false;
        },
        handleCommentSubmit(comment) {
            console.log('提交的评论:', comment);

        },
        setupMenuItemStyle(index) {
            return index == this.menuSelectIndex ? "nav-menu-item indicator" : "nav-menu-item"
        },
        setupMenuItemClick(index) {
            $(document).scrollTop(330)
            this.menuSelectIndex = index
            this.updateTopNavIndex(index)
            if (index == 5) {
                this.isModalVisible = true;
                return
            } else {
                this.isModalVisible = false;
            }
            console.log(index)
            EventBus.emit('changeMenu', this.menuSelectIndex);
        },
        getImageUrl(imageUrl) {
            return base_url + "/upimg/" + imageUrl;
        },
        loginOutAction() {

        }
    },
    mounted() {
        if (this.user) {
            console.log('User Info:', this.user);
        } else {
            console.log('No user is logged in.');
        }
    },
    created() {
        const us = this.user
        console.log("user", us)
        this.getWelComeLogoinfo()
        // 获取搜索框的推荐内容
        this.res = remen;
        // document点击事件，用于隐藏，隐藏部分内容。
        document.addEventListener("click", function (event) {
            let even = event || window.event,
                tag = even.target || even.srcElement;
            if (tag.id != "inputSoSuo") {
                // document.getElementsByClassName("searchHide")[0].style.display = "none";
                // $("#inputSoSuo").attr("placeholder", "搜索即刻工厂");
                // remen[1].title
            }
        }, false)
        // 滚动出现盒子下面的阴影
        $(document).scroll(function () {
            let res = $(this).scrollTop();
            if (res >= 5) {
                $("#headNav").css("box-shadow", "0px 2px 3px #888888")
            } else {
                $("#headNav").css("box-shadow", "none")
            }
        })
    }
}
</script>


<style lang="less" scoped>
@charset "utf-8";
@import '../assets/css/headNav.css';

.user-image {
    width: 30px;
    height: 30px;
    border-radius: 15px;
}

.item-image {
    width: 30px;
    height: 30px;
}

.edit-item-image {
    width: 30px;
    height: 30px;
}

#headNav {
    position: fixed;
    z-index: 9999 !important;
    top: 0px;
    left: 0px;
    width: 100%;
    max-width: 100%;
    height: 60px;
    overflow-x: auto;
    /* background-color: aqua; */
    border-bottom: 1px solid #efefef;
    border-top: 2px solid #ff8140;
    background-color: rgba(255, 255, 255, .9);
    display: flex;
    justify-content: center;
    background-color: white;
}

.nav-search-icon {
    width: 24px;
    height: 24px;
    position: absolute;
    left: 10px;
}

.slog {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: red;
    line-height: 100%;
    text-align: center;
    font-size: 22px;
    width: calc(1280px/4);
    font-weight: bold;
    flex-shrink: 0;
    margin-left: 40px;
}

.user {
    background-color: white;
}


.nav-menu {
   
    height: 100%;
    // width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
}

.indicator {
    list-style: none;
    margin-left: 20px;
}

.indicator a {
    font-size: 16px;
    color: #333;
}

.indicator a:hover {
    color: #ff8140;
}

.nav-menu-item {
    color: #333;
    width: 70px;
    height: 60px;
    margin-left: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* padding-bottom: 15px; */
}

.indicator {
    border-bottom: 3px solid #C6AA87;
}


#headNav .headNav1 {
    width: 1280px;
    /* width: calc(100% - 40px); */
    overflow-x: auto;
    overflow-y: hidden;
    height: 60px;
    margin: 0 20px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    column-gap: 50px;
    background-color: white;
}

.left-search {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 40px;
}

/* 左边logo */
#headNav .headNav1 h1 a {
    display: block;
    width: 48px;
    height: 48px;
    text-indent: -9999px;
    background-image: url(../assets/image/logo.jpg);
    background-size: contain;
    background-size: 48px 48px;
    overflow: hidden;
}

/* 右边导航 */
#headNav .headNav1 .nav ul li {
    float: left;
    list-style: none;
    height: 48px;
    line-height: 48px;
    text-align: center;
    margin-left: 25px;
}

#headNav .headNav1 .nav ul li a {
    font-size: 16px;
}

#headNav .headNav1 .nav ul li a span {
    font-weight: bold;
    font-size: 20px;
}

#headNav .headNav1 .nav ul li a:hover,
#headNav .headNav1 .nav ul li a:hover span {
    color: #ff8140;
}

/* 中间搜索框 */
#headNav .headNav1 .kuang {
    width: 180px;
    height: 40px;
    margin-top: 0;
    margin-left: 10px;
    position: relative;
    border-radius: 22px;
    background-color: #F0F2F5;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

#headNav .headNav1 .kuang input:focus {
    outline: none;
}

#headNav .headNav1 .kuang input {
    height: 40px;
    width: 250px;
    margin-left: 40px;
    border-radius: 20px;
    border: none;
    font-size: 14px;
    background-color: transparent;
}

.right-space {
    width: 360px;
}

#headNav .headNav1 .kuang .iconfont a {
    font-weight: bold;
    font-size: 22px;
    position: absolute;
    left: 10px;
    top: 11px
}

#headNav .headNav1 .kuang span a:hover {
    cursor: pointer;
    color: #ff8140;
}

/* 中间搜索框隐藏部分 */
#headNav .headNav1 .kuang .searchHide {
    box-sizing: border-box;
    width: 470px;
    height: 335px;
    background-color: #fff;
    /* border: 1px solid #efefef; */
    margin-top: 2px;
    padding: 1px;
    display: none;
    position: relative;
    z-index: 9999 !important;
}

#headNav .headNav1 .kuang .searchHide .reso {
    width: 250px;
    height: 30px;
    line-height: 30px;
    /* padding: 1px; */
    padding-left: 15px;
    margin-left: 1px;
    box-sizing: border-box;
    color: #ff8140;
}

#headNav .headNav1 .kuang .searchHide .reso:hover {
    cursor: pointer;
    background-color: #efefef;
}

#headNav .headNav1 .kuang .searchHide ul li {
    list-style: none;
    box-sizing: border-box;
    width: 250px;
    height: 30px;
    padding: 1px;
    padding-right: 1px;
    line-height: 30px;
    /* padding-left: 15px; */
}

#headNav .headNav1 .kuang .searchHide ul li a {
    display: block;
    width: 250px;
    height: 30px;
}

#headNav .headNav1 .kuang .searchHide ul li a span {
    display: inline-block;
    margin-left: 15px;
    color: #fff;
    line-height: 15px;
    text-align: center;
    font-weight: bold;
    background-color: #9c9c9c;
    width: 15px;
    height: 15px;
    border-radius: 50%;
}

#headNav .headNav1 .kuang .searchHide ul li:hover a {
    cursor: pointer;
    color: #ff8140;
    background-color: #efefef;
}
</style>