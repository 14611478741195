const config: {
  base_url: string | undefined
  result_code: number | string
  default_headers: AxiosHeaders
  request_timeout: number
} = {
  /**
   * api请求基础路径 "/api"
   */
  base_url: process.env.NODE_ENV === 'production' ? "https://api.jikegongchang.com" :  "https://api.jikegongchang.com" ,
  /**
   * 接口成功返回状态码
   */
  result_code: 200,

  /**
   * 接口请求超时时间
   */
  request_timeout: 30000,

  /**
   * 默认接口请求类型
   * 可选值：application/x-www-form-urlencoded multipart/form-data
   */
  default_headers: 'application/json'
}

export { config }
