<template>
	<div class="company-category">
		<!-- <navbar leftText1="JiKeGongChang" :leftText2="title" /> -->
		<!-- <div class="nav-placeholder" :style="{marginTop: navbarTop + 'px'}"> -->
		<!-- </div> -->
		<!-- <y-tabs v-model="activeIndex"  sticky :offsetTop="offsetTop" color="#C6AA87" titleActiveColor="#C6AA87" titleInactiveColor="#9E9E9E" @change="tabChange">
			<y-tab v-for="(title, index) in tabs" :title="title" :key="index">
				<div class="line"></div> -->
		<RefreshLoadMore :refresh="onRefresh" :loadMore="onLoadMore" :finished="finished">
			<companyList :isCategory="true" :list="companys"></companyList>
		</RefreshLoadMore>
		<!-- </y-tab>
		</y-tabs> -->
	</div>
</template>

<script>
// import navbar from "../../components/navbar/navbar.vue";
import companyList from "../company-list/company-list.vue";
// import storage from "../../utils/storage";
import RefreshLoadMore from '../../components/RefreshLoadMore/RefreshLoadMore.vue';

import {
	getTypeCompanyListRequest
} from '@/api/index.ts'
// import {
// 	getRect,
// 	getHeaderHeight
// } from "/utils/uitls.js"

export default {
	props: {
		currentTypeId: {
			type: String,
			required: false // 如果参数是可选的，可以设置为 false
		},
		city: {
			type: String,
			required: false // 如果参数是可选的，可以设置为 false
		}
	},
	data() {
		return {
			types: [],
			companys: [],
			currentPage: 1,
			tabs: [],
			activeIndex: 0,
			title: "",
			offsetTop: 0,
			navbarTop: 0,
			finished: false

		};
	},
	computed: {
		isEmpty() {
			return !this.companys || this.companys.length == 0
		},
	},
	components: {
		companyList,
		RefreshLoadMore
	},
	onReady() {
		// this.setOffsetTop();
	},
	mounted() {
		// this.title = option.titleWords;
		// console.log("titleWords", this.title);
		this.onRefresh();
	},
	watch: {
		currentTypeId(newCurrentTypeId, oldCurrentTypeId) {
			console.log('Type ID changed from', newCurrentTypeId, 'to', oldCurrentTypeId);
			this.onRefresh();
		},
		city(newCity, oldCity) {
			console.log('Type ID changed from', oldCity, 'to', newCity);
			this.onRefresh();
		}
	},
	methods: {
		onRefresh() {
			this.companys = []
			return new Promise((resolve) => {
				this.finished = false;
				this.currentPage = 1;
				this.getTypeCompanyList().then(resolve);
			});
		},
		onLoadMore() {
			return new Promise((resolve) => {
				this.currentPage += 1;
				this.getTypeCompanyList().then(resolve);
			});
		},
		getTypeCompanyList() {
			return getTypeCompanyListRequest({
				"OneType": this.currentTypeId,
				"page": this.currentPage,
				"pagecount": 10,
				"UserPhone": "18180802828"
			}).then(resp => {
				console.log("行业一级分类下公司 -- ", resp);
				if (resp.data.companyinfoHot.length <= 0) {
					this.finished = true
				}
				if (this.currentPage == 1) {
					this.companys = resp.data.companyinfoHot;
				} else {
					if (resp.data.companyinfoHot.length > 0) {
						this.companys = this.companys.concat(resp.data.companyinfoHot);
					}
				}
				console.log("行业一级分类下公司 topList -- ", this.companys);
			}).catch(err => {
				console.error('行业一级分类下公司 list失败:', err);
				
			});
		}
	},
	// 页面滚动触发事件
	onPageScroll(e) {
		//页面滚动事件
		// uni.$emit('onPageScroll', e)
	},
	
}
</script>

<style lang="less">
.company-category {
	position: relative;
	background-color: white;
}

.line {
	border-top: 0.5px solid #F2F3F5;
	/* 分割线颜色和宽度 */
	// margin: 10px 0;
	/* 上下间距 */

}

// .y-tab-virtual {
//     position: absolute !important;
// 	top: 100px;
//     // flex-shrink: 0;
//     width: 100%;
// 	height:100vh;
// 	background-color: red;
// }

// .content-wrap{
//     height:100vh;
// 	// background-color: red;
// }</style>