<template>
  <div>
    <Field v-model="textValue" v-bind="$attrs" :name="$attrs.text" :placeholder="placeholder" :rules="rules"
      :required="required" :readonly="readonly" :is-link="islink" @click="showAreaPicker" />
    <Popup :show="showPicker" position="bottom" overlay close-on-click-overlay>
      <Area :area-list="areaList" :columns-num="columnsNum" :columns-placeholder="columnsplaceholder"
        @confirm="onConfirm" @cancel="hideAreaPicker" />
    </Popup>
  </div>
</template>

<script>
import { areaList } from "@vant/area-data";
import { Popup, Area, Field,showFailToast } from 'vant';

export default {
  props: {
    required: Boolean,
    readonly: Boolean,
    islink: Boolean,
    columnsNum: Number,
    rules: Array,
    selectValue: String,
    keyValue: String,
  },
  components: {
    Popup,
    Area,
    Field
  },
  data() {
    return {
      areaList,
      show: false,
      textValue: "",
      selectOptions: [],
      placeholder: "",
      columnsplaceholder: ["请选择", "请选择", "请选择"],
      provinceList: null,
      cityList: null,
      countyList: null,
      province: "",
      city: "",
      county: "",
      showPicker:false
    };
  },
  methods: {
    showAreaPicker() {
      this.showPicker = true;
    },
    hideAreaPicker() {
      this.showPicker = false;
    },
    onConfirm(areaData) {
      console.log("选择地址",areaData)
      // 判断打开选择器后是否必填省/市/区
      if (this.columnsNum === 1) {
        this.provinceOn(areaData.selectOptions);
      } else if (this.columnsNum === 2) {
        this.provinceCityOn(areaData.selectOptions);
      } else {
        this.provincesMunicipalitiesOn(areaData.selectedOptions);
      }
    },
    onAreaConfirm(area) {
      this.selectedArea = area;
      console.log('选中的地区：', area);
      console.log("areaList ---",this.areaList)
      this.showPicker = false; // 确认选择后关闭地区选择器
    },
    // 省时必填校验
    provinceOn(areaData) {
      const province = areaData[0];
      if (!province.text) {
        this.showFailToast("请选择省");
        return;
      }
      this.acquireOn(areaData);
    },
    // 省市时必填校验
    provinceCityOn(areaData) {
      const province = areaData[0];
      const city = areaData[1];
      if (!province.text) {
        this.showFailToast("请选择省");
        return;
      }
      if (!city.text) {
        this.showFailToast("请选择市");
        return;
      }
      this.acquireOn(areaData);
    },
    // 省市区时必填校验
    provincesMunicipalitiesOn(areaData) {
      console.log("选择地址----",areaData)
      const province = areaData[0];
      const city = areaData[1];
      const area = areaData[2];
      if (!province.text) {
        this.showFailToast("请选择省");
        return;
      }
      if (!city.text) {
        this.showFailToast("请选择市");
        return;
      }
      if (!area.text) {
        this.showFailToast("请选择区");
        return;
      }
      this.acquireOn(areaData);
    },
    // 校验完后执行确定方法赋值name/code
    acquireOn(areaData) {
      // 获取选中name/code值
      let names = "";
      let codes = "";
      areaData.forEach((item, index) => {
        if (!item) {
          return "";
        }
        if (index > 0) {
          names += "/" + item.text;
          codes += "/" + item.value;
        } else {
          names += item.text;
          codes += item.value;
        }
      });
      if (this.keyValue === "code") {
        this.textValue = codes;
      } else {
        this.textValue = names;
      }
      this.$emit("confirm", this.textValue);
      // 更新回显值
      this.getValue(this.keyValue);
      this.hideAreaPicker()
    },
    // 通过code值获取地区name
    getNameByCode(value, list) {
      let name = "";
      if (!value) {
        return;
      }
      Object.keys(list).forEach((key) => {
        if (value.toString() === key.toString()) {
          name = list[key];
        }
      });
      return name;
    },
    // 地区回显
    getValue(type) {
      if (!this.selectValue || !this.selectValue.length) {
        this.textValue = "";
        return;
      }
      const selectList = this.selectValue.split("/");
      let provinceName,
        cityName,
        countyName = "";
      switch (type) {
        case "code":
          if (Number(this.columnsNum) === 1) {
            provinceName = this.getNameByCode(selectList[0], this.provinceList);
            this.textValue = provinceName;
          } else if (Number(this.columnsNum) === 2) {
            provinceName = this.getNameByCode(selectList[0], this.provinceList);
            cityName = this.getNameByCode(selectList[1], this.cityList);
            this.textValue = provinceName + "/" + cityName;
          } else {
            provinceName = this.getNameByCode(selectList[0], this.provinceList);
            cityName = this.getNameByCode(selectList[1], this.cityList);
            countyName = this.getNameByCode(selectList[2], this.countyList);
            this.textValue = provinceName + "/" + cityName + "/" + countyName;
          }
          break;
        case "name":
          this.textValue = this.selectValue;
          break;
      }
    },
  },
  watch: {
    // json数据
    areaList: {
      handler(newVal) {
        if (newVal) {
          const length = Object.keys(newVal).length;
          if (length) {
            this.cityList = newVal.city_list;
            this.countyList = newVal.county_list;
            this.provinceList = newVal.province_list;
          }
        }
      },
      immediate: true,
      deep: true,
    },
    // 控制选择完整省市区还是部分
    columnsNum: {
      handler(newVal) {
        switch (newVal) {
          case 1:
            this.placeholder = "省";
            this.columnsplaceholder = ["请选择"];
            break;
          case 2:
            this.placeholder = "省/市";
            this.columnsplaceholder = ["请选择", "请选择"];
            break;
          default:
            this.placeholder = "请选择地区";
            this.columnsplaceholder = ["请选择", "请选择", "请选择"];
        }
      },
      immediate: true,
    },
    selectValue: {
      handler(newValue) {
        this.$nextTick(() => {
          if (this.keyValue) {
            this.getValue(this.keyValue);
          }
        });
      },
      immediate: true,
    },
    keyValue: {
      handler(newValue) {
        if (newValue) {
          this.getValue(newValue);
        }
      },
      immediate: true,
    },
  },
};
</script>
