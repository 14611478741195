<template>
    <div class="profile">
      <h2 @click="logoutAction">退出登录</h2>
      <!-- Profile content here -->
    </div>
  </template>
  
  <script>
  import { mapState, mapActions, mapGetters } from 'vuex';

  export default {
    name: 'Profile',
    methods: {
      ...mapActions(['logout']),
      logoutAction() {
        // Perform logout logic here
        this.logout()
      }
    }
  };
  </script>
  
  <style scoped>
  /* Add your styles here */
  .profile {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    padding: 20px;
    background-color: white;
  }
  </style>
  