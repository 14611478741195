import { createRouter, createWebHistory } from 'vue-router';
import PlantContent from '../components/PlantContent.vue'
import Home from '../views/Home.vue';
import Profile from '../views/Profile.vue';
import Login from '../views/Login.vue';
import Topic from '@/views/topic/topic.vue';
import TopicDetail from '@/views/topic/topic-home/topic-home.vue';
import Renmai from '@/views/renmai/renmai.vue';
import ChatDetail from '@/views/contact/chat-detail.vue';
import CompanyCategory from '@/views/company-category/company-category.vue';
import Search from '@/views/search/search.vue';
import UserHome from '@/views/user/user-home/user-home.vue';
import UserFollow from '@/views/user/user-follow/user-follow.vue';
import UserCollect from '@/views/user/user-collect/user-collect.vue';
import CompanyHome from '@/views/company-home/company-home.vue';
import CompanyCase from '@/views/company-home/compny-case/compny-case.vue';
import Company from '@/views/company/company.vue';

const routes = [
  {
    path: '/',
    name: '/',
    component: Home,
  },
  {
    path: '/Home',
    name: 'Home',
    component: Home,
  },
  {
    path: '/PlantContent',
    name: 'PlantContent',
    component: PlantContent,
  },
  {
    path: '/Profile',
    name: 'Profile',
    component: Profile,
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/Topic',
    name: 'Topic',
    component: Topic,
  },
  {
    path: '/Renmai',
    name: 'Renmai',
    component: Renmai,
  },
  {
    path: '/topic-detail/:topicId/:topicTitle',
    name: 'TopicDetail',
    component: TopicDetail,
    props: true
  },
  {
    path: '/ChatDetail/:chatId',
    name: 'ChatDetail',
    component: ChatDetail,
    props: true
  },
  {
    path: '/CompanyCategory/:currentTypeId?',
    name: 'CompanyCategory',
    component: CompanyCategory,
    props: true
  }, {
    path: '/Search/:type?',
    name: 'Search',
    component: Search,
  }, {
    path: '/UserHome/:userId?',
    name: 'UserHome',
    component: UserHome,
  },
  {
    path: '/UserFollow',
    name: 'UserFollow',
    component: UserFollow,
  },
  {
    path: '/UserCollect',
    name: 'UserCollect',
    component: UserCollect,
  },
  {
    path: '/CompanyHome/:companyId?',
    name: 'CompanyHome',
    component: CompanyHome,
  },
  {
    path: '/CompanyCase/:companyId?',
    name: 'CompanyCase',
    component: CompanyCase,
  },
  {
    path: '/Company',
    name: 'Company',
    component: Company,
  }
  
  // Add other routes as needed
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
