import { useCache } from '@/hooks/web/useCache'

export type TokenType = {
  id: number // 编号
  accessToken: string // 访问令牌
  refreshToken: string // 刷新令牌
  userId: number // 用户编号
  userType: number //用户类型
  clientId: string //客户端编号
  expiresTime: number //过期时间
}

const { wsCache } = useCache()

const AccessTokenKey = 'ACCESS_TOKEN'
const RefreshTokenKey = 'REFRESH_TOKEN'

// 获取token
export const getAccessToken = () => {
  // 此处与TokenKey相同，此写法解决初始化时Cookies中不存在TokenKey报错
  return wsCache.get(AccessTokenKey) ? wsCache.get(AccessTokenKey) : wsCache.get('ACCESS_TOKEN')
}

// 刷新token
export const getRefreshToken = () => {
  return wsCache.get(RefreshTokenKey)
}

// 设置token
export const setToken = (token: TokenType) => {
  wsCache.set(RefreshTokenKey, token.refreshToken, { exp: token.expiresTime })
  wsCache.set(AccessTokenKey, token.accessToken)
}

// 删除token
export const removeToken = () => {
  wsCache.delete(AccessTokenKey)
  wsCache.delete(RefreshTokenKey)
}

/** 格式化token（jwt格式） */
export const formatToken = (token: string): string => {
  return 'Bearer ' + token
}
// ========== 账号相关 ==========

const LoginFormKey = 'LOGINFORM'

// export type LoginFormType = {
//   tenantName: string
//   username: string
//   password: string
//   rememberMe: boolean
// }

export const getLoginForm = () => {
  const loginForm = wsCache.get(LoginFormKey)
  if (loginForm) {
    console.log('getLoginForm', loginForm)
  }
  return loginForm
}

export const setLoginForm = (loginForm: any) => {
  wsCache.set(LoginFormKey, loginForm, { exp: 30 * 24 * 60 * 60 })
  setUserPhone(loginForm.UserPhone)
}

export const removeLoginForm = () => {
  wsCache.delete(LoginFormKey)
}


const UserPhoneKey = 'UserPhone'
export const getUserPhone = () => {
  return wsCache.get(UserPhoneKey)
}

export const setUserPhone = (userPhone: string) => {
  wsCache.set(UserPhoneKey, userPhone, { exp: 30 * 24 * 60 * 60 })
}

export const removeUserPhone = () => {
  wsCache.delete(UserPhoneKey)
  console.log("removeUserPhone")
}

// ========== 租户相关 ==========

const TenantIdKey = 'TENANT_ID'
const TenantNameKey = 'TENANT_NAME'

export const getTenantName = () => {
  return wsCache.get(TenantNameKey)
}

export const setTenantName = (username: string) => {
  wsCache.set(TenantNameKey, username, { exp: 30 * 24 * 60 * 60 })
}

export const removeTenantName = () => {
  wsCache.delete(TenantNameKey)
}

export const getTenantId = () => {
  return wsCache.get(TenantIdKey)
}

export const setTenantId = (username: string) => {
  wsCache.set(TenantIdKey, username)
}

export const removeTenantId = () => {
  wsCache.delete(TenantIdKey)
}
