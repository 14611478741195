<template>
    <div class="search">
        <SearchComponent @search="handleSearch" @citySelected="citySelectedSearch" />
        <div class="line"></div>
        <div v-if="menuSelectIndex == 0">
            <companyList :list="companys"></companyList>
        </div>
        <div v-else-if="menuSelectIndex == 1">
            <user :list="users"></user>
        </div>
        <div v-else>
            <groupList :list="dynamicList"></groupList>
        </div>
    </div>

</template>


<script>
import $ from 'jquery'
import EventBus from '@/eventBus';
import companyList from "../company-list/company-list.vue";
import groupList from "@/views/group/group-list.vue";
import user from "../user/user.vue";
import SearchComponent from '@/components/SearchHeader/SearchHeader.vue';

import {
    companySearchRequest,
    userSearchRequest,
    get_dynamic_ByQueryRequest
} from '@/api/index.ts'

export default {
    components: {
        groupList,
        user,
        companyList,
        SearchComponent
    },
    data() {
        return {
            menuSelectIndex: 0,
            menues: [

            ],
            search_input: '',
            lianxiren: [],
            companyPage: 1,
            userPage: 1,
            dynamicPage: 1,
            dynamicList: [],
            users: [],
            companys: [],
            proviceCode: "",
            cityCode: ""
        }
    },
    methods: {
        handleSearch(query) {
            // 在这里处理搜索逻辑，例如发起API请求获取搜索结果
            console.log('搜索内容:', query);
            this.inputValue = query;
            this.refreshAction(query ?? "")
        },
        citySelectedSearch(areaData) {
            var areaDatas = areaData.split('/');
                this.proviceCode = areaDatas[0]
            this.cityCode = areaDatas[1]
            this.refreshAction(this.inputValue ?? "")
        },
        leftNavClick: function (event) {
            let even = event || window.event,
                tag = even.target || even.srcElement;
            $("#leftNav ul").children().children().removeClass("hong")
            $(tag).addClass("hong");
        },
        setupItemStyle(index) {
            return index == this.menuSelectIndex ? "hong" : "default-color"
        },
        itemAction(index) {
            this.menuSelectIndex = index
            this.$router.push({
                name: 'CompanyCategory',
                params: { currentTypeId: this.menues[index].id }
            });
        },
        handleChangeMenu(index) {
            console.log("index", index)
            this.menuSelectIndex = index

            this.refreshAction(this.inputValue ?? "")
        },
        refreshAction(inputValue) {
            this.search_input = inputValue;
            this.companyPage = 1;
            this.userPage = 1;
            this.dynamicPage = 1;
            this.companySearch();
            this.userSearch();
            this.get_dynamic_ByQuery();
        },
        companySearch() {
            var param = {
                "query": this.search_input,
                "UserPhone": "18180802828",
                "page": this.companyPage,
            }
            if (this.proviceCode != "") {
                param['ProviceCode'] = this.proviceCode;
            }
            if (this.cityCode != "") {
                param['CityCode'] = this.cityCode;
            }
            console.log("搜索 ",param)
            companySearchRequest(param).then(resp => {
                console.log("公司 -- ", resp);
                if (this.companyPage == 1) {
                    this.companys = resp.data;
                } else {
                    if (resp.data.length > 0) {
                        this.companys = this.companys.concat(resp.data);
                    }
                }
                console.log("公司 companys -- ", this.companys);
            }).catch(err => {
                console.error('公司 list失败:', err);
            });
        },
        get_dynamic_ByQuery() {
            var param = {
                "query": this.search_input,
                "UserPhone": "18180802828",
                "page": this.companyPage,
            }
            if (this.proviceCode != "") {
                param['ProviceCode'] = this.proviceCode;
            }
            if (this.cityCode != "") {
                param['CityCode'] = this.cityCode;
            }
            get_dynamic_ByQueryRequest(param).then(resp => {
                console.log("动态 -- ", resp);
                if (this.dynamicPage == 1) {
                    this.dynamicList = resp.info;

                } else {
                    if (resp.info.length > 0) {
                        this.dynamicList = this.dynamicList.concat(resp.info);
                    }
                }
                console.log("动态 dynamicList -- ", this.dynamicList);
            }).catch(err => {
                console.error('动态 list失败:', err);
            });
        },

        userSearch() {
            var param = {
                "query": this.search_input,
                "UserPhone": "18180802828",
                "page": this.companyPage,
            }
            if (this.proviceCode != "") {
                param['ProviceCode'] = this.proviceCode;
            }
            if (this.cityCode != "") {
                param['CityCode'] = this.cityCode;
            }
            userSearchRequest(param).then(resp => {
                console.log("用户 -- ", resp);
                if (this.userPage == 1) {
                    this.users = resp.info;
                } else {
                    if (resp.info.length > 0) {
                        this.users = this.users.concat(resp.info);
                    }

                }
                console.log("用户 users -- ", this.users);
            }).catch(err => {
                console.error('用户 list失败:', err);
            });
        },
    },
    created() {
        // 滚动悬浮左侧的导航栏
        $(document).scroll(function () {
            let res = $(this).scrollTop();
            if (res >= (330 + 20)) {
                $("#leftNav").css("position", "fixed")
                $("#leftNav").css("top", "60px")
                // $("#plantContent").css("margin-left","60px")
            } else {
                $("#leftNav").css("position", "static")
                // $("#plantContent").css("margin-left","30px")
            }
        });
    },
    mounted() {
        EventBus.on('changeLeftMenu', this.handleChangeMenu);
        EventBus.on('searchAction', this.refreshAction)
    },
    beforeDestroy() {
        EventBus.off('changeLeftMenu', this.handleChangeMenu);
        EventBus.off('searchAction', this.refreshAction);

    }
}
</script>

<style scoped>
.search {
    display: flex;
    flex-direction: column;
}

.line {
    background-color: #F1F2F5;
    height: 10px;
    width: 100%;
}
</style>