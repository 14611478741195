<template>

	<no-data v-if="isEmpty" description="暂无用户~~" padding="100rpx 0"></no-data>

	<div v-else class="course-content">
		<div class="course-item" v-for="(item ,index) in list" :index="index" :key="index">
			<userItem :item-info="item"></userItem>
		</div>
	</div>
</template>

<script>
	import userItem from '../../components/user-item/user-item.vue';
	import noData from "@/components/no-data/index"

	export default {
		props: {
			list: {
				type: Array,
				default: function() {
					return [];
				}
			},

		},
		components: {
			userItem,
			noData
		},
		computed: {
			isEmpty() {
				return !this.list || this.list.length == 0
			},
		},
		data() {
			return {
				currentPage: 1
			};
		},
	}
</script>

<style lang="less">
	.course-content {
		position: relative;
		display: block;
		background-color: #f9f9f9;
		padding-left: 10rpx;
		padding-right: 10rpx;
		padding-bottom: 64rpx;
		.course-item {
			margin-top: 8rpx;
			margin-bottom: 8rpx;
			background-color: white;
			// display: flex;
			// flex-direction: column;
			// font-size: 28rpx;
			// font-weight: 500;
			// color: $uni-text-color;
			// margin-bottom: 40rpx;

			// image {
			// 	width: 334rpx;
			// 	height: 180rpx;
			// 	border-radius: 20rpx;
			// 	margin-bottom: 24rpx;
			// }
		}
	}
</style>