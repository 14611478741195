<template>
    <div class="component-container">
        <no-data v-if="isEmpty" description="暂无人脉~~" padding="100px 0"></no-data>
        <RefreshLoadMore v-else :refresh="onRefresh" :loadMore="onLoadMore" :finished="finished">
            <van-grid :column-num="3">
                <van-grid-item v-for="(item, index) in remaiList" :key="index" @click="topicAction(item.userPhone)">
                    <img class="renmai-item-img" :src="showUpImg(item.userHeadImg)">
                    <div class="remen1-item-content">
                        <span class="remen1-item-content-title">{{ item.userName }}</span>
                        <span class="remen1-item-content-descr">{{ item.companyShortName }}</span>
                    </div>
                </van-grid-item>
            </van-grid>
        </RefreshLoadMore>
    </div>
</template>

<script>
import { config } from '@/config/axios/config'
const { base_url } = config
import {
    getOnePageSuperUserRequest,
} from '@/api/index.ts'
import noData from "@/components/no-data/index"
import RefreshLoadMore from '../../components/RefreshLoadMore/RefreshLoadMore.vue';
import { mapState } from 'vuex';
import * as imgUtil from '@/utils/imgUtils'

export default {
    components: {
        noData,
        RefreshLoadMore
    },
    data() {
        return {
            remaiList: [],
            finished: false,
            currentPage: 1
        }
    },
    computed: {
        isEmpty() {
            return !this.remaiList || this.remaiList.length == 0
        },
        ...mapState(["userPhone"])
    },
    mounted() {
        this.getOnePageSuperUser();
    },
    methods: {
        topicAction(userId) {
            this.$router.push({
					name: 'UserHome',
					params: { userId:userId }
				});
        },
        getOnePageSuperUser() {
            getOnePageSuperUserRequest({
                "UserPhone": this.userPhone, "page_number": this.currentPage
            }).then(resp => {
                // console.log("人脉 -- ", resp);
                if (this.currentPage == 1) {
                    this.remaiList = resp.info;

                } else {
                    if (resp.info.length > 0) {
                        this.remaiList = this.remaiList.concat(resp.info);
                    } else {
                        this.finished = true
                    }
                }
                // console.log("人脉 remaiList -- ", this.remaiList);
            }).catch(err => {
                console.error('人脉 list失败:', err);
            });
        },
        getImageFullUrl(imageUrl) {
            return base_url + imageUrl;
        },
        onRefresh() {
            console.log('触发了下拉刷新')
            this.finished = false
            this.currentPage = 1;
            this.getOnePageSuperUser();
        },
        onLoadMore() {
            console.log('页面触底了')
            this.finished = false
            this.currentPage += 1;
            this.getOnePageSuperUser();
        },
        showUpImg(image) {
            return imgUtil.getUpImageUrl(image);
        },
        showImg(image) {
            return imgUtil.getImageFullUrl(image);
        },
    },

}
</script>

<style lang="less" scoped>
.component-container {
    position: relative;
    display: block;
    padding-top: 12px;
    border-radius: 5px;
    margin: 0;
    padding: 0;
}

.renmai-item-img {
    width: 54px;
    height: 54px;
    border-radius: 27px;
    object-fit: cover;
    border: solid 1px #efefef;
    margin-right: 10px;
    margin-bottom: 10px;
}

.renmai-item-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: black;
}

.renmai-item-content-title {
    font-size: 14px;
    margin-bottom: 10px;
}

.remen1-item-content-descr {
    color: #696e78;
    font-size: 12px;
    margin-bottom: 10px;
}

.item-image {
    background-color: white;
    padding: 8px;
    height: 240px;
    object-fit: cover;
    width: 100%;
}

.item-info-text {
    padding: 30px 20px;
    color: white;
    position: absolute;
    left: 10px;
    bottom: 10px;
    display: flex;
    flex-direction: column;
    font-size: 24px;
    font-weight: bold;
}
</style>