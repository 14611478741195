<template>
    <div class="login">
      <h2>Login Page</h2>
      <!-- Login form here -->
    </div>
  </template>
  
  <script>
  export default {
    name: 'Login',
  };
  </script>
  
  <style scoped>
  /* Add your styles here */
  </style>
  