<template>
	<div class="listCon">
		<PublishView  class="publish"/>
		<RefreshLoadMore :refresh="onRefresh" :loadMore="onLoadMore" :finished="finished">
			<groupList :list="dynamicList"></groupList>
		</RefreshLoadMore>
	</div>
</template>

<script>
import { get_dynamic_listRequest } from "@/api/index.ts";
import groupList from "./group-list.vue";
import { config } from '@/config/axios/config';
const { base_url } = config;
import { mapState } from 'vuex';
import RefreshLoadMore from '../../components/RefreshLoadMore/RefreshLoadMore.vue';
import PublishView from '@/components/PublishView/PublishView.vue';

export default {
	components: {
		groupList,
		RefreshLoadMore,
		PublishView,
	},
	data() {
		return {
			activeIndex: 0,
			topList: [],
			dynamicList: [],
			currentPage: 1,
			finished: false,
		};
	},
	computed: {
		...mapState(['userPhone']),
	},
	mounted() {
		this.getDynamicList();
	},
	methods: {
		onRefresh() {
			return new Promise((resolve) => {
				this.finished = false;
				this.currentPage = 1;
				this.getDynamicList().then(resolve);
			});
		},
		onLoadMore() {
			return new Promise((resolve) => {
				this.currentPage += 1;
				this.getDynamicList().then(resolve);
			});
		},
		getDynamicList() {
			return get_dynamic_listRequest({
				UserPhone: this.userPhone,
				page: this.currentPage,
			}).then((resp) => {
				if (resp.info.length <=  0) {
					this.finished = true;
				}
				if (this.currentPage === 1) {
					this.dynamicList = resp.info;
				} else {
					if (resp.info.length > 0) {
						this.dynamicList = this.dynamicList.concat(resp.info);
					} 
				}
			}).catch((err) => {
				console.error('动态列表 list失败:', err);
				this.finished = true;
			});
		},
		getImageFullUrl(imageUrl) {
			return base_url + imageUrl;
		},
	},
};
</script>

<style lang="less" scoped>
.publish {
	margin-bottom: 10px;
}
.no-margin {
	margin: 0;
	padding: 0;
}

.listCon {
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: column;
}
</style>