// src/services/websocket.js
import { ref } from 'vue';
import { useWebSocket } from '@vueuse/core';

export const useWebSocketService = (baseUrl, userPhone) => {
    const message = ref('');
    const chatUserInfo = ref({});
    const chatId = ref("");
    const fullUrl = `${baseUrl}${userPhone}/`;

    const { send, data, status, open, close } = useWebSocket(fullUrl, {
        onConnected: () => {
            console.log('WebSocket connected');
        },
        onDisconnected: () => {
            console.log('WebSocket disconnected');
        },
        onError: (e) => {
            console.error('WebSocket error', e);
        },
        onMessage: (msg) => {
            console.log('WebSocket message received:', msg);
            message.value = msg.data;
        },
    });

    const sendMessage = (msg) => {
        console.log('WebSocket sendMessage', status.value);
        if (status.value === 'OPEN') {
            console.log('WebSocket sendMessage -- msg', msg);
            send(msg);
        }
    };

    const closeWebSocket = () => {
        close();
    };

    return {
        sendMessage,
        message,
        chatId,
        chatUserInfo,
        closeWebSocket,
    };
};
