<template>
  <div class="home">
    <group></group>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import noData from "@/components/no-data/index"
import group from "@/views/group/group.vue"

const dynamic_list = ref([])


</script>

<style scoped>
/* Add your styles here */
.home {
  width: 660px;
  background-color: #F1F2F5;
}
</style>