<template>
    <div class="company">
        <ComponyMenu class="company-menu"  @menuSelected="handleMenuSelected" @citySelected="citySelectedAction"></ComponyMenu>
        <div class="line"></div>
        <CompanyCategory :currentTypeId="currentTypeId"></CompanyCategory>
    </div>

</template>


<script>
import ComponyMenu from '@/views/company-category/company-menu.vue'
import CompanyCategory from '@/views/company-category/company-category.vue'


export default {
    components: {
        ComponyMenu,
        CompanyCategory
    },
    data() {
        return {
            menuSelectIndex: 0,
            currentTypeId:0,
            menues: [

            ],
            search_input: '',
            lianxiren: [],
            companyPage: 1,
            userPage: 1,
            dynamicPage: 1,
            dynamicList: [],
            users: [],
            companys: [],
            city:""
        }
    },
  
    methods: {
        handleMenuSelected(id){
            this.currentTypeId = id;
        },
        citySelectedAction() {

        }
    }
}

</script>

<style lang="less" scoped>

.company {
    display:flex;
    flex-direction: column;
}
.line {
    background-color:#F1F2F5;
    height: 10px;
    width: 100%;
}
</style>