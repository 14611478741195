<template>
    <div class="publish" v-if="visible">
        <div class="publish-content">
            <!-- <span class="close" @click="close">&times;</span> -->
            <h2>快捷发布</h2>
            <AutoExpandTextarea v-model="comment" placeholder="说点什么吧~" />
            <div v-if="uploadedImages.length > 0" class="image-grid">
                <!-- 显示已上传的图片 -->
                <div v-for="(image, index) in uploadedImages" :key="index" class="image-container">
                    <img v-if="image.type === 'image'" class="image-container-img" :src="image.url"
                        alt="Uploaded Image">
                    <video v-else class="image-container-img" :src="image.url" controls></video>
                    <img class="delete-button" @click="deleteImage(index)" src="@/assets/image/pison/shanchutupian.png">
                </div>

                <!-- 上传图片按钮 -->
                <label v-if="isImageUploaded" class="upload-button">
                    <input type="file" accept="image/*" @change="handleFileChange('image', $event)">
                    <img src="@/assets/image/pison/tianjiatupian.png">

                </label>
            </div>
            <div class="tag">
                #企业动态
            </div>
            <div class="switch">
                <div class="company-info-item">
                    <img class="company-info-item-img" src="@/assets/image/pison/dingwei.png">
                    <span>附带公司信息</span>
                </div>
                <label class="switch-label">
                    <input type="checkbox" v-model="isChecked" @change="toggleSwitch">
                    <span class="slider round"></span>
                </label>
            </div>
            <div class="bottom-bar">
                <div class="left">
                    <div class="custom-file-input-item">
                        <input class="custom-file-input" type="file" accept="image/*"
                            @change="handleFileChange('image', $event)" :disabled="!isImageUploaded" multiple>
                        <div class="custom-button bottom-bar-item" :disabled="!isImageUploaded">
                            <img class="bottom-bar-icon" src="@/assets/image/pison/tupian.png">
                            <div>图片</div>
                        </div>
                    </div>
                    <div class="custom-file-input-item">
                        <input class="custom-file-input" type="file" accept="video/*"
                            @change="handleFileChange('video', $event)" :disabled="!isVideoUploaded">
                        <div class="custom-button bottom-bar-item" :disabled="!isVideoUploaded">
                            <img class="bottom-bar-icon" src="@/assets/image/pison/shipin.png">
                            <div>视频</div>
                        </div>
                    </div>
                </div>

                <button class="button" @click="submitComment">发布</button>
            </div>
        </div>
    </div>
</template>

<script>
import AutoExpandTextarea from '../AutoExpandTextarea/AutoExpandTextarea.vue';
import { showFailToast, showSuccessToast } from 'vant'
import {
	pub_dynamicRequest,
} from '@/api/index.ts'
import {
	mapState
} from "vuex";
export default {
    props: {
        visible: {
            type: Boolean,
            default: true
        }
    },
    components: {
        AutoExpandTextarea,

    },
    computed: {
		...mapState(['userPhone'])
	},
    data() {
        return {
            comment: '',
            uploadedImages: [],
            updateFiles: [],
            isImageUploaded: true,
            isVideoUploaded: true,
            loading: false
        }
    },
    watch: {
        // visible(newVal) {
        //     if (newVal) {
        //         document.body.classList.add('no-scroll');
        //     } else {
        //         document.body.classList.remove('no-scroll');
        //     }
        // }
    },
    methods: {
        close() {
            this.$emit('close');
        },
        handleFileChange(type, event) {
            const files = event.target.files;
            
            if (files.length === 0) return;

            const file = files[0];
            if (file) {
                this.uploadedFile = file;
                if (type === 'image') {
                    this.isImageUploaded = true;
                    this.isVideoUploaded = false;
                } else if (type === 'video') {
                    this.isImageUploaded = false;
                    this.isVideoUploaded = true;
                }
            }
            // 处理每个上传的文件
            Array.from(files).forEach(file => {
                // const reader = new FileReader();
                // reader.onload = () => {
                //     this.uploadedImages.push({ url: reader.result });
                // };
                // reader.readAsDataURL(file);
                const mediaType = file.type.startsWith('image') ? 'image' : 'video';
                const url = URL.createObjectURL(file); // 使用 Blob URL
                this.updateFiles.push(file);

                this.uploadedImages.push({ type: mediaType, url });
            });
        },
        deleteImage(index) {
            // 释放 Blob URL
            URL.revokeObjectURL(this.uploadedImages[index].url);
            this.uploadedImages.splice(index, 1);
            this.updateFiles.splice(index, 1);
        },
        submitComment() {
            if (!this.comment.trim()) {
                showFailToast('请输入内容');
                return;
            }

            this.publishButtonPressed();
            // this.$emit('submit', this.comment);
            // this.comment = '';
            // this.close();
        },
        chooseImage() {
            // 选择图片的逻辑
            console.log('选择图片');

        },
    async publishButtonPressed() {
        this.loading = true;
        const formData = new FormData();
        formData.append('UserPhone', this.userPhone);
        formData.append('DynamicText', this.comment);
        formData.append('topicTitle', "企业动态");

        if (this.isVideoUploaded) {
          const videoFile = this.updateFiles[0];
          formData.append('Dvideo', videoFile, 'video.mp4');
        } else {
          for (const imagePath of this.updateFiles) {
            formData.append('Dimags', imagePath);
          }
        }

        pub_dynamicRequest(formData)
          .then(response => {
            this.loading = false;
            if (response.code === 200) {
                console.log('Upload 成功');
                showSuccessToast('发布成功');
                this.close();
            } else {
              // Upload failed
              console.log('Upload failed');
              showFailToast('发布失败');
            }
          })
          .catch(error => {
            // Handle any errors that occur during the upload process
            console.log('Error:', error);
          });
    }},
    
    beforeDestroy() {
        // document.body.classList.remove('no-scroll');
    }

}
</script>

<style scoped>
.no-scroll {
    overflow: hidden;
}

.button {
    color: white;
    margin: 0;
}

.image-grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.image-container {
    position: relative;
    width: 80px;
    height: 80px;
    border: solid 0.5px #ccc;
    border-radius: 3px;
}

.image-container-img {
    width: 80px;
    height: 80px;
    object-fit: cover;
}

.delete-button {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
}

.upload-button {
    width: 80px;
    height: 80px;
}

.upload-button input {
    display: none;
}

.tag {
    font-size: 14px;
    border: solid 1px #C6AA87;
    height: 24px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 12px;
    width: fit-content;
    text-align: center;
    line-height: 24px;
    color: #C6AA87;
    margin-top: 20px;
}

.tag-item {
    margin-right: 10px;
}

.switch {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
}

.company-info-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    margin-right: 20px;
}

.company-info-item-img {
    width: 24px;
    height: 24px;
}

.switch-label {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch-label input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background-color: #ccc; */
    transition: .4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

input:checked+.slider {
    background-color: #C6AA87;
}

input:checked+.slider:before {
    transform: translateX(26px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.tag-item:last-child {
    margin-right: 0;
    color: #C6AA87;
}

.bottom-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.left {
    display: flex;
    align-items: center;
}

input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    /* width: 44px; */
    height: 44px;
    opacity: 0;
    /* cursor: pointer; */
}

.custom-file-input-item {
    position: relative;
    display: inline-block;
    overflow: hidden;
    margin-right: 30px;
}

.custom-file-input {
    position: relative;
    display: inline-block;
    overflow: hidden;
}


.bottom-bar-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    /* margin-right: 40px; */
}

.bottom-bar-icon {
    width: 24px;
    height: 24px;
}

.publish {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* max-height: 600px; */
    /* max-width: 600px; */
    /* position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); */
}

.publish-content {
    /* background-color: #fefefe; */
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    border-radius: 5px;

    /* border: 1px solid #888; */
    /* width: 80%; */
    /* max-width: 600px; */
    width: 100%;
    /* border-radius: 5px; */
    background-color: white;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

button {
    padding: 10px 20px;
    margin-top: 20px;
    background-color: #C6AA87;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    float: right;
}

button:hover {
    background-color: #C6AA87;
}
</style>
