<template>
  <div :class="['chat-message', { 'sent': message.sender == userPhone, 'received':  message.sender != userPhone }]">
    <img :src="getUpImageFullUrl(message.sender == userPhone ?  user.UserHeadImg : chatUserInfo.userHeadImg)" class="avatar"  />
    <div class="bubble">
      <p v-if="message.message_type === 'text'">{{ message.content }}</p>
      <img v-if="message.message_type === 'image'" :src="getImageFullUrl(message.imageurl)" alt="Image" class="message-image" @click="previewImges([message.imageurl])"/>
      <div v-if="message.message_type === 'location'" class="location">
        <img src="@/assets/image/pison/LocationIcon.png" alt="">
        <p>{{ message.content }}</p>
      </div>
      <span class="time">{{ message.time }}</span>
    </div>

    <VueEasyLightbox :visible="visible" :imgs="currentImages" :index="currentIndex" @hide="handleHide" />

  </div>

</template>

<script>
import { mapState,mapGetters } from 'vuex';
import { config } from '@/config/axios/config'
const { base_url } = config
import VueEasyLightbox from 'vue-easy-lightbox';

export default {
  name: 'ChatMessage',
  computed: {
    ...mapState(['userPhone','socketService']),
    ...mapGetters(['user']),
  },
  data() {
		return {
			isModalVisible: false,
			visible: false,
			currentIndex: 0,
			currentImages: []
		}
	},
  components: {
		VueEasyLightbox
	},
  props: {
    message: {
      type: Object,
      required: true
    },
    chatUserInfo: {
      type: Object,
      required: true
    }
  },
  methods: {
    getImageFullUrl(imageUrl) {
			return base_url +  imageUrl;
		},
    getUpImageFullUrl(imageUrl) {
			return base_url + "/upimg/" + imageUrl;
		},
    previewImges(imageUrls) {
			let photoList = imageUrls.map((item) => {
				return this.getImageFullUrl(item);
			});
			this.currentImages = photoList;
			this.currentIndex = 0;
			this.visible = true;
		},
		handleHide() {
			this.visible = false;
		},
  }
};
</script>

<style scoped>
.chat-message {
  display: flex;
  align-items: flex-start;
  margin: 10px;
}
.sent {
  flex-direction: row-reverse;
}
.received {
  flex-direction: row;
}
.location {
  display: flex;
  align-items: center;
  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
}
.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 10px;
  object-fit: cover;
}
.bubble {
  max-width: 60%;
  padding: 10px;
  border-radius: 5px;
  position: relative;
  color: white;
}
.sent .bubble {
  background-color: #C6AA87;
}
.received .bubble {
  background-color: grey;
}
.message-image {
  max-width: 100%;
  border-radius: 10px;
}
.time {
  display: block;
  font-size: 0.8em;
  margin-top: 5px;
  color: rgba(255, 255, 255, 0.7);
  text-align: right;
}
</style>
