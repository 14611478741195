<template>
	<div class="pages">
		<NavBar>
			<template v-slot:title>
				详情
			</template>
		</NavBar>
		<div class="bgc_pic" :class="isPlay == 1 ? 'isPlatwp' : isPlay == 2 ? 'isPlafh' : ''">
			<NetImage :src="getImageFullUrl(topicInfo.TopicImage)" :style="{ height: sheight + 'px' }"
				:class="isPlay == 1 ? 'isPlay' : isPlay == 2 ? 'isPlaasf' : ''" mode="aspectFill"/>
			<div class="topic-info">
				<div class="topic-info-title">
					{{ topicTitle }}
				</div>
				<div class="topic-info-content">
					{{ topicInfo.TopicDescription }}
				</div>
			</div>
		</div>
		<!-- <groupList :list="dynamicList"></groupList> -->
		<div class="content">
			<text class="wonderful-case">|| 热门话题</text>
			<RefreshLoadMore :refresh="onRefresh" :loadMore="onLoadMore" :finished="finished">
				<groupList :list="dynamicList"></groupList>
			</RefreshLoadMore>
		</div>
	</div>
</template>

<script>
import groupList from "@/views/group/group-list.vue";
import NavBar from "@/components/navbar/navbar.vue";
import NetImage from "@components/NetImage/NetImage.vue";
import RefreshLoadMore from '@/components/RefreshLoadMore/RefreshLoadMore.vue';

import {
	mapState
} from "vuex";
import {
	getSingleTopicInfoRequest,
	find_dynamics_by_topicRequest
} from '@/api/index.ts'
import { config } from '@/config/axios/config'
const { base_url } = config
export default {
	beforeRouteUpdate(to, from, next) {
        // 处理路由参数变化的逻辑
        this.topicId = to.params.topicId;
        this.topicTitle = to.params.topicTitle;
        next();
		this.getSingleTopicInfo();
		this.find_dynamics_by_topic();
    },
	props: {
	
	},
	components: {
		NavBar,
		groupList,
		NetImage,
		RefreshLoadMore
	},
	computed: {
		...mapState(["userPhone"])
	},
	data() {
		return {
			// 用户操作
			clientData: {
				// 用户手指Y轴
				clientMoveY: '', //按下
				clientEndY: '', //抬起
				// 页面Y轴
				pageY: ''
			},
			sheight: 562, //高度
			isPlay: 0, //是否播放动画
			topicInfo: {},
			topicId: "",
			topicTitle: "",
			typeInfo: {},
			addressInfo: {},
			tabs: ["|| 热门话题"],
			activeIndex: 0,
			offsetTop: 44, //粘性定位布局下与顶部的最小距离
			dynamicList: [],
			showLoadMore: false,
			navbarTop: 44,
			currentPage: 1,
			dynamicBackgroundColor: "transparent",
			finished: false,

		}
	},
	// onPageScroll(e) { //nvue暂不支持滚动监听，可用bindingx代替
	// 	// console.log(e);
	// 	this.clientData.pageY = e.scrollTop;
	// },
	// 页面滚动触发事件
	onPageScroll(e) {
		//页面滚动事件
		// uni.$emit('onPageScroll', e)
		const scrollTop = e.scrollTop;
		// 根据滚动位置设置背景色，这里以一个简单的条件判断作为示例
		if (scrollTop > 100) { // 当滚动超过100px时
			this.dynamicBackgroundColor = '#ffffff';
		} else {
			this.dynamicBackgroundColor = 'transparent';
		}
	},

	created() {
		if (this.$route.params.topicId) {
			this.topicId = this.$route.params.topicId
		}

		if (this.$route.params.topicTitle) {
			this.topicTitle = this.$route.params.topicTitle
		}

	},
	mounted() {
		// this.topicId = option.topicId;
		// this.topicTitle = decodeURIComponent(option.topicTitle);
	
		this.getSingleTopicInfo();
		this.find_dynamics_by_topic();
	},
	methods: {

		getSingleTopicInfo() {
			getSingleTopicInfoRequest({
				"TopicId": this.topicId,
			}).then(resp => {
				this.topicInfo = resp.info;
				console.log("话题详情 -- ", this.topicInfo);
			}).catch(err => {
                console.error('话题详情 list失败:', err);
            });
		},

		move(e) {
			// console.log(e);
			// 先判断用户是否到达了顶部
			if (this.clientData.pageY < 1) {
				// 本次手指移动的位置和上次移动的位置对比 <1 证明用户在下拉
				if (this.clientData.clientMoveY - e.changedTouches[0].clientY < 1) {
					// 拿到下拉的距离
					let distance = this.clientData.clientMoveY - e.changedTouches[0].clientY;
					// console.log(distance);
					// 最大下拉到800px ,放大1.8倍大小
					if (this.sheight < 1862) {
						this.sheight -= distance;
						// this.sheight = 1862
						// 执行动画
						this.isPlay = 1;
					} else {
						//超过800或者1.8倍就return
						return;
					}
				}
				// 上边的代码执行结束之后再把本次手指的位置赋值给data中,用来下一次对比
				this.clientData.clientMoveY = e.changedTouches[0].clientY;
			} else {
				setTimeout(() => {
					this.sheight = 562;
					// 并把动画的class名取消掉
					this.isPlay = 2;
				}, 350);
			}
		},
		// 手指抬起
		end(e) {
			// this.clientData.clientEndY = e.changedTouches[0].clientY;
			// 当图片的高度大于400的时候手指抬起再调用函数
			if (this.sheight > 562) {
				// 执行动画
				this.isPlay = 1;
				// 动画结束后把顶部图片的高度和放大比例该会原来的值
				setTimeout(() => {
					this.sheight = 562;
					// 并把动画的class名取消掉
					this.isPlay = 2;
				}, 350);
			}
		},

		previewImges(imageUrls) {
			let photoList = imageUrls.map((item) => {
				return this.getImageFullUrl(item);
			});
			// uni.previewImage({
			// 	current: 0,
			// 	urls: photoList,
			// });
		},
		handleChange(event) {
			// uni.navigateTo({
			// 	url: "/pages/company-home/compny-case/compny-case?userId=" + this.userId,
			// });
		},

		getImageUrl(imageUrl) {
			return base_url + "/upimg/" + imageUrl;
		},

		getImageFullUrl(imageUrl) {
			return base_url + imageUrl;
		},

		onRefresh() {
			return new Promise((resolve) => {
				this.finished = false;
				this.currentPage = 1;
				this.find_dynamics_by_topic().then(resolve);
			});
		},
		onLoadMore() {
			return new Promise((resolve) => {
				this.currentPage += 1;
				this.find_dynamics_by_topic().then(resolve);
			});
		},

		find_dynamics_by_topic() {
			return find_dynamics_by_topicRequest({
				"topic_title": this.topicTitle,
				"UserPhone": this.userPhone,
				"page": this.currentPage,
				"pageSize":10
			}).then(resp => {
				console.log("用户案例 --resp ", resp);
				if (resp.info.length <= 0) {
					this.finished = true
				}
				if (this.currentPage == 1) {
					this.dynamicList = resp.info;

				} else {
					if (resp.info.length > 0) {
						this.dynamicList = this.dynamicList.concat(resp.data.info);
					} else {
						// uni.showToast({
						// 	title: "暂无更多数据",
						// 	icon: 'none'
						// });
					}

				}
				console.log("用户案例 -- ", this.dynamicList);
			}).catch(err => {
                console.error('用户案例 list失败:', err);
            });
		}

	},

	onPullDownRefresh() {
		console.log('触发了下拉刷新')
		this.currentPage = 1;
		this.find_dynamics_by_topic();
	},
	onReachBottom() {
		// console.log('页面触底了')
		this.currentPage += 1;
		this.find_dynamics_by_topic();
	},
}
</script>

<style lang="less" scoped>
.pages {
	width: 100%;
	color: #191919;
	display: flex;
	flex-direction: column;
	padding-bottom: 120px;
	background-color: #F4F6F8;
}

.bgc_pic {
	width: 100%;
	height: 562px;
	position: relative;
	
}


.topic-info {
	display: flex;
	flex-direction: column;
	position: absolute;
	left: 18px;
	bottom: 48px;

	.topic-info-title {
		font-size: 22px;
		font-weight: bold;
		color: white;
	}

	.topic-info-content {
		font-size: 20px;
		color: white;
		margin-top: 8px;
	}
}

.nav-left-back {
	position: absolute;
}

.nav-left-back-icon {
	margin-right: 16px;
}

.bgc_pic>image {
	width: 100%;
}

.isPlay {
	animation: big 0.2s 1 alternate ease-in-out forwards;
}

@keyframes big {
	100% {
		/* margin-top: 100px; */
	}
}

.isPlaasf {
	animation: biasd 0.5s 1 alternate ease-in-out forwards;
}

@keyframes biasd {
	0% {
		/* margin-top: 100px; */
	}

	100% {
		margin-top: 0;
	}
}

.isPlatwp {
	animation: bis 0.2s 1 alternate ease-in-out forwards;
}

@keyframes bis {
	100% {
		/* height: 1862px; */
	}
}

.isPlafh {
	animation: dgsdf 0.5s 1 alternate ease-in-out forwards;
}

@keyframes dgsdf {
	0% {
		/* height: 1862px; */
	}

	100% {
		height: 562px;
	}
}

.content {
	// border-top-right-radius: 30px;
	// border-top-left-radius: 30px;
	// transform: translateY(-230px);
	background-color: white;
	display: flex;
	flex-direction: column;

}

.user-info {
	display: flex;
	align-items: center;
	padding: 30px;

}

.user-image {
	width: 88px;
	height: 88px;
	border-radius: 44px;
}

.item-user-info {
	display: flex;

}

.name-position {
	display: flex;
	flex-direction: column;
}

.company-name {
	color: black;
	font-size: 38px;
	padding-left: 30px;
}

.company-companyNameDetail {
	color: grey;
	font-size: 30px;
	padding-left: 30px;
	padding-top: 10px;
}

.tag {
	display: flex;
	flex-direction: row;
	/* position: absolute; */
	padding-left: 30px;
	padding-top: 20px;
	margin-bottom: 10px;
}

.tagChild {
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 5px;
	padding-bottom: 5px;
	margin-right: 10px;
	background-color: #ccc;
	color: white;
	font-size: 24px;
	text-align: center;
}

.company-star {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: absolute;
	right: 10px;
	top: 40px;
}

.star-image {
	width: 100px;
	height: 100px;
}

.company-star-content {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 80px;
	color: #C6AA87;
}

.star-title {
	color: #00000030;
	font-size: 40px;
}

.company-companyIntroduce {
	font-size: 30px;
	color: black;
	padding-top: 40px;
	padding-bottom: 20px;
	padding-left: 30px;
}

.scroll {
	white-space: nowrap;
	width: calc(100% - 20px);
	padding: 20px 20px 20px 20px;

	.box1 {
		white-space: nowrap;

		.item {
			display: inline-flex;
			flex-direction: column;
			width: 160px;
			height: 120px;
			margin-right: 20px;
			padding: 5px;
			background-color: white;
			justify-content: center;
			align-items: center;
			border: 1px solid #0000001F;
			border-radius: 6px;
			font-size: 24px;
			box-shadow: #0000001F;
			color: #000000DD;

			:first-child {
				font-size: 40px;
				font-weight: bold;
				color: #C6AA87;
			}
		}
	}
}

.address {
	height: 40px;
	margin-left: 30px;
	margin-top: 10px;
	margin-bottom: 10px;
	padding-top: 5px;
	width: fit-content;
	padding-bottom: 5px;
	padding-left: 25px;
	padding-right: 25px;
	border-radius: 20px;
	background-color: #C6AA8755;
	display: flex;
	align-items: center;

	image {
		width: 30px;
		height: 30px;
		margin-right: 10px;
	}

	text {
		font-size: 20px;
		color: white;
	}
}

.wonderful-case {
	color: #C6AA87;
	font-size: 20px;
	margin-left: 18px;
	margin-top: 15px;
	margin-bottom: 30px;
}

.newpeopleView {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.newpeopleViewTitle {
	font-size: 38px;
	color: #000000;
}

.newpeopleViewSubTitle {
	font-size: 30px;
	color: #999;
	padding-top: 10px;
	padding-bottom: 30px;
}

.swiper {
	// height: px;
	white-space: nowrap;
	width: 100%;
	/* 设置swiper容器的高度 */
	margin-bottom: 30px;
}

.item-content {
	display: flex;
	padding-left: 8px;
}

.item-bg {
	margin-right: 16px;
	align-items: center;
	justify-content: center;
	position: relative;
}

.swiper-item {
	display: flex;
	flex-direction: column;
	box-shadow: 1px 1px 3px 3px rgba(0, 0, 0, 0.03);
	margin: 10px;
}


.item-image {
	// padding: 8px;
	// height: 240px;
	max-width: calc((100vw - 8*3px) / 2);
}

.item-info-text {
	padding: 30px 20px;
	color: white;
	position: absolute;
	left: 10px;
	bottom: 10px;
	display: flex;
	flex-direction: column;
	font-size: 28px;
	font-weight: bold;
}

.item-info-title {
	font-size: 28px;
	color: black;
}

.item-user-name {
	color: #0000008A;
	font-size: 24px;
}

.item-user-total-likes {
	color: #0000008A;
	font-size: 24px;
}

.load-more {
	background-color: white;
	color: #C6AA87;
	font-size: 14px;
	writing-mode: vertical-lr;
	/* 从上到下，从左到右 */
	/* 或者 */
	/* writing-mode: vertical-rl; 从上到下，从右到左 */
	text-align: center;
	/* 可根据需要调整文本水平对齐方式 */
	height: 200px;
	/* 可根据需要调整文本容器的高度 */
	line-height: 1.5;
	/* 可根据需要调整文本行高 */
}
</style>