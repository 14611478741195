<template>
	<div>
		<div class="card" @click="companyAction">
			<div class="index">
				<text>TOP</text>
				<text>{{ indexN }}</text>
			</div>
			<img class="avatar" :src="getImageFullUrl(itemInfo.ComHeadImg)"  />
			<div class="company-info">
				<text class="name">{{ itemInfo.ComName }}</text>
				<text class="text1">{{ itemInfo.ComPanyLabel }}</text>
				<div class="text2-item">
					查阅：
					<text class="text2"> {{ itemInfo.CompanyCollectTimes }}</text>
					次
				</div>
				<text class="text3">{{ itemInfo.AreaInfo.ComProviceName  + "·"+ itemInfo.AreaInfo.ComCityName}}</text>

			</div>

		</div>
	</div>
</template>

<script>
import { config } from '@/config/axios/config'
const { base_url } = config
	export default {
		name: "company-category-item",
		props: {
			itemInfo: {
				type: Object,
				default: function() {
					return {};
				}
			},
			indexN: {
				type: Number,
				default: function() {
					return 0;
				}
			}

		},
		data() {
			return {

			};
		},
		methods: {
			getImageFullUrl(imageUrl) {
				console.log("item - image", imageUrl);
				return base_url + imageUrl;
			},
			companyAction() {
				// uni.navigateTo({
				// 	url: "/pages/company-home/company-home?companyid=" + this.itemInfo.CompanyId
				// });
				console.log("company-item - companyid", this.itemInfo.CompanyId);

				this.$router.push({
				name: 'CompanyHome',
				params: { companyId:this.itemInfo.CompanyId }
			});
			},
		}
	}
</script>

<style lang="less" scoped>
	.card {
		display: flex;
	}

	.index {
		display: flex;
		flex-direction: column;
		color: #C6AA87;
		margin-left: 15px;
		align-items: center;
	}

	.avatar {
		width: 100px;
		height: 100px;
		margin-left: 15px;
	}

	.company-info {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: space-between;
		margin-left: 15px;
	}

	.name {
		font-size: 18px;
		color: #C6AA87;
	}

	.text1 {
		font-size: 14px;
		color: #000000DD;
	}

	.text2-item {
		display: flex;
		font-size: 14px;
		color: #0000008A;
	}

	.text2 {
		font-size: 14px;
		color: #C6AA87;
	}

	.text3 {
		font-size: 14px;
		color: grey;
	}

	.time {
		font-size: 28px;
		margin-top: 10px;
	}
</style>