<template>
	<div>
		<div class="userHeadCard" @click="itemAction">
			<NetImage class="avatar" :src="getImageFullUrl(itemInfo.UserHeadImg)"  />
			<div class="person-info">
				<div class="person-info-company">
					<text class="name">{{ itemInfo.UserName }}</text>
					<text class="company-name">{{ itemInfo.UserComPan.companyName }}</text>
				</div>
				<text class="userIntro">{{ itemInfo.UserIntro }}</text>
				<div class="tag">
					<text class="tag-item">{{ itemInfo.UserType.RegesTypeName }}</text>
					<text class="tag-item">{{ itemInfo.UserType.OneType[0].OneTypeName }}</text>
					<text class="tag-item">{{ itemInfo.UserType.SecendType[0].SecendTypeeName }}</text>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { config } from '@/config/axios/config'
import NetImage from "@components/NetImage/NetImage.vue";

const { base_url } = config
	export default {
		name: "user-item",
		props: {
			itemInfo: {
				type: Object,
				default: function() {
					return {};
				}
			},

		},
		components: {
			NetImage
		},
		data() {
			return {

			};
		},
		methods: {
			getImageFullUrl(imageUrl) {
				console.log("item - image", imageUrl);
				return base_url + "/upimg/" + imageUrl;
			},
			itemAction() {

				this.$router.push({
					name: 'UserHome',
					params: { userId:this.itemInfo.UserId }
				});
				
			},
		}
	}
</script>

<style lang="less" scoped>
	.userHeadCard {
		display: flex;
		// padding: 20px 16px;
		padding-top: 10px;
	}

	.avatar {
		width: 64px;
		height: 64px;
		border-radius: 32px;
		object-fit: cover;
	}

	.person-info {
		display: flex;
		flex-direction: column;
		margin-left: 25px;
	}

	.person-info-company {
		display: flex;
		align-items: center;
		margin-bottom: 10px;
	}

	.name {
		font-size: 24px;
		color: #C6AA87;
		font-weight: bold;
	}

	.company-name {
		font-size: 17px;
		color: #00000030;
		margin-left: 10px;
	}

	.userIntro {
		color: #99999999;
		font-size: 16px;
		margin-bottom: 10px;
	}

	.tag {
		display: flex;
		margin-bottom: 10px;
	}

	.tag-item {
		margin-right: 10px;
		padding: 5px;
		font-size: 14px;
		color: white;
		font-weight: bold;
		background-color: #0000001F;
	}

	.time {
		font-size: 28px;
		margin-top: 10px;
	}
</style>