<template>
    <div id="section1">
        <left-nav />
        <right-nav />
        <div class="content">
            <router-view />
        </div>

        <!-- <plant-content /> -->
    </div>
</template>


<script>
import LeftNav from './LeftNav.vue'
// import PlantContent from './PlantContent.vue'
import RightNav from './RightNav.vue'
export default {
    name: 'section1',
    components: {
        LeftNav,
        // PlantContent,
        RightNav
    }
}
</script>

<style>
#section1 {
    width: 1160px;
    /* width: 100%; */
    margin: 0 auto;
    /* padding-top: 80px; */
    margin-top: 20px;
    background-color: white;
    /* height: 100px; */
    /* background-color: aqua; */
}

.content {
    width: 660px;
    /* height: 400px; */
    float: right;
    margin-right: 10px;
    /* margin-right: calc((100% - 660px - 340px - 200px) / 2); */
    overflow: hidden;
    background-color: white;
    border-radius: 5px;
    position: relative;
}
</style>