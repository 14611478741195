<template>
	<div class="item-content">
		<div class="userHeadCard">
			<NetImage class="avatar" :src="getImageFullUrl(itemInfo.DynamicUser.Avatar)" @click="avatarAction"
				borderRadius="27px" />
			<div class="person-info">
				<div class="position">
					<div class="position-info">
						<div class="position-left">
							<text class="name">{{ itemInfo.DynamicUser.UserName }}</text>
							<text class="position-user">{{ itemInfo.DynamicUser.UserPosition }}</text>
						</div>
						<text class="time">{{ itemInfo.PublicationTime }}</text>
					</div>
					<div class="guanzhu" @click="followButtonAction(itemInfo)">
						{{ followButtonTitle(itemInfo) }}
					</div>
				</div>
				<div class="position-right">
					<div class="position-right-info">
						<text class="position-right-title">{{ itemInfo.DynamicUser.UserPosition }}</text>
						<text class="position-right-text">{{ itemInfo.DynamicUser.UserPositionText }}</text>
					</div>
				</div>

			</div>
		</div>
		<div class="dynamicText">
			<text>{{ itemInfo.DynamicText }}</text>

		</div>
		<!-- 视频展示 -->
		<VideoItem class="video" controls @video-playing="handleVideoPlaying"
			v-if="itemInfo.DynamicImgType == false && itemInfo.VideosUrl != ''"
			:videoSrc="getImageFullUrl(itemInfo.VideosUrl)">
		</VideoItem>
		<!-- 两张图片展示 -->
		<div v-else-if="itemInfo.ImageUrls.length == 2" class="double">
			<NetImage :src="getImageFullUrl(itemInfo.ImageUrls[0])" class="double-image" @click="previewImges(0)" />
			<NetImage :src="getImageFullUrl(itemInfo.ImageUrls[1])" class="double-image" @click="previewImges(1)" />
		</div>

		<!-- 三张图片展示 -->
		<div v-else-if="itemInfo.ImageUrls.length >= 3" class="triple">
			<NetImage :src="getImageFullUrl(itemInfo.ImageUrls[0])" class="triple-image" @click="previewImges(0)" />
			<NetImage :src="getImageFullUrl(itemInfo.ImageUrls[1])" class="triple-image" @click="previewImges(1)" />
			<NetImage :src="getImageFullUrl(itemInfo.ImageUrls[2])" class="triple-image" @click="previewImges(2)" />
		</div>
		<!-- 单张图片展示 -->
		<NetImage v-else-if="itemInfo.ImageUrls.length == 1" class="one-image"
			:src="getImageFullUrl(itemInfo.ImageUrls[0])" @click="previewImges(0)" />

		<div class="zan">
			<div v-if="likeNum > 0" class="zan-info">
				<div class="zan-info-user" v-for="(item, index) in itemInfo.likes.list" :key="index">
					<NetImage class="zan-info-user-img" :src="getImageUrl(item.headimg)" borderRadius="17px" />
				</div>
				<text class="zan-info-title">等{{ likeNum }}人觉得很棒</text>
			</div>
			<div v-else class="no-zan">
				<text>别跑 赞一下</text>
				<img class="zan-info-user-img" src="@/assets/image/pison/rainbow.png" mode="aspectFit" />
			</div>
			<!-- <uni-icons type="heart-filled" size="34" color="grey"  @click="buttonAction"></uni-icons> -->

			<img class="zan-info-like-img" :src="likeIcon(item)" @click="handleLike" />
		</div>
		<div class="comment-title">
			善语结善缘
		</div>
		<div class="comment-list" v-for="(item, index) in itemInfo.comments" :key="index">
			<div class="comment-name">
				{{ item.username }}
			</div>
			{{ item.content }}
		</div>

		<!-- <div class="comment-button" @click="showModal"> -->
			<!-- 好言一句三冬暖 -->
			<CommentView class="comment-button" @submit="handleCommentSubmit" ></CommentView>
		<!-- </div> -->
		<div class="topic" @click="topicAction(itemInfo.DynamicTopic.TopicId, itemInfo.DynamicTopic.TopicTitle)">
			#
			{{ itemInfo.DynamicTopic.TopicTitle }}
		</div>
		<div class="company" @click="companyAction(itemInfo.DynamicUser.ComPanyInfo.companyId)">
			<img src="@/assets/image/pison/iconPoistion.png" />
			<text>{{ itemInfo.DynamicUser.ComPanyInfo.companyName }}</text>
		</div>

		<!-- <CommentModal :visible="isModalVisible" @close="hideModal" @submit="handleCommentSubmit" /> -->

		<VueEasyLightbox :visible="visible" :imgs="currentImages" :index="currentIndex" @hide="handleHide" />
	</div>
</template>

<script>
import { config } from '@/config/axios/config'
const { base_url } = config
import VideoItem from '../VideoItem/VideoItem.vue';
import VueEasyLightbox from 'vue-easy-lightbox';
import { mapState } from 'vuex';
import { showFailToast,showSuccessToast } from 'vant'
import NetImage from "@components/NetImage/NetImage.vue";
import CommentView from '../CommentView/CommentView.vue';

import {
	userCollectUserRequest,
	comment_dynamicRequest,
	press_dynamicRequest
} from '@/api/index.ts'

export default {

	name: "dynamic-item",
	components: {
		CommentView,
		VideoItem,
		VueEasyLightbox,
		NetImage
	},
	computed: {
		...mapState(['userPhone', 'isLoggedIn', 'user'])
	},
	props: {
		item: {
			type: Object,
			default: function () {
				return {};
			}
		},

	},
	data() {
		return {
			isModalVisible: false,
			visible: false,
			currentIndex: 0,
			currentImages: [],
			itemInfo: {},
			likeNum: 0,
			currentLiked: false
		};
	},
	//生命周期函数
	created() {
		// 获取传过来的数据，并赋值给本页面中的数据
		this.itemInfo = this.item
		this.likeNum = this.itemInfo.likes.list.length
		this.currentLiked =  this.itemInfo.likes.list.some(user => user.userphone === this.userPhone);
	},
	methods: {
		companyAction(CompanyId) {
			// uni.navigateTo({
			// 	url: "/pages/company-home/company-home?companyid=" + this.itemInfo.CompanyId
			// });
			this.$router.push({
				name: 'CompanyHome',
				params: { companyId:CompanyId }
			});
		},
		topicAction(topicId, topicTitle) {
            // uni.navigateTo({
            // 	url: "/pages/topic/topic-home/topic-home?topicId=" + topicId
            // });
            console.log("话题 topicTitle -- ", topicTitle);

            const currentRoute = this.$route;
            const targetRoute = { name: 'TopicDetail', params: { topicId: topicId, topicTitle: topicTitle } };
            if (currentRoute.name === 'TopicDetail') {
                this.$router.replace(targetRoute);
            } else {
                this.$router.push(targetRoute);
            }
            // this.$router.push({
            //     name: 'TopicDetail',
            //     params: { topicId: topicId, topicTitle: topicTitle }
            // });
        },
		getImageFullUrl(imageUrl) {
			console.log("item - image", imageUrl);
			return base_url + imageUrl;
		},
		getImageUrl(imageUrl) {
			return base_url + "/upimg/" + imageUrl;
		},
		avatarAction() {
			// uni.navigateTo({
			// 	url: "/pages/user/user-home/user-home?userId=" + this.itemInfo.DynamicUser.UserId
			// });
		},
		followButtonTitle(itemInfo) {
			return itemInfo.CurrentUserFollowed ? "已关注" : "关注"
		},
		likeIcon(item) {
			return this.currentLiked == true ? require("@/assets/image/pison/aixin-2.png") : require("@/assets/image/pison/aixin.png")
		},
		followButtonAction(itemInfo) {
			if (this.isLoggedIn) {
				userCollectUserRequest({
					"FansUserPhone": itemInfo.DynamicUser.UserId,
					'AdminUserPhone': this.userPhone
				}).then(resp => {
					console.log("关注 -- ", resp);
					itemInfo.CurrentUserFollowed = true
				}).catch(err => {
					console.error('关注失败:', err);
					showFailToast("关注失败")
				});
			} else {
				showFailToast("请先登录")
			}
		},
		previewImges(index) {
			let photoList = this.itemInfo.ImageUrls.map((item) => {
				return this.getImageFullUrl(item);
			});
			this.currentImages = photoList;
			this.currentIndex = index;
			this.visible = true;
		},
		handleHide() {
			this.visible = false;
		},
		showModal() {
			this.isModalVisible = true;
		},
		hideModal() {
			this.isModalVisible = false;
		},
		handleCommentSubmit(comment) {
			console.log('提交的评论:', comment);
			if (this.isLoggedIn) {
			// 在这里处理评论提交逻辑，例如发送到服务器
			comment_dynamicRequest({
				'UserPhone': this.userPhone,
				'dynamicId': this.itemInfo.DynamicId,
				'comment': comment,
			}).then(res => {
				console.log('评论提交成功:', this.user);
				showSuccessToast('评论成功');
				var commentInfo = {
					username: this.user.UserName,
					content: comment,
					company: this.user.UserComPan.companyName,
					press_time: new Date().toLocaleString(),
					headimg: this.user.UserHeadImg,
					userphone: this.user.UserPhone,
					userposition: this.user.UserPosition
				}
				// 更新评论列表
				this.itemInfo.comments.push(commentInfo);
			}).catch(err => {
				console.error('评论提交失败:', err);
				showFailToast("评论提交失败")
			});
		}else {
				showFailToast("请先登录")
			}
		},
		handleLike() {
			if (this.isLoggedIn) {
			press_dynamicRequest({
				'UserPhone': this.userPhone,
				'dynamicId': this.itemInfo.DynamicId,
			}).then(res => {
				console.log('评论提交成功:', this.user);
				if (this.currentLiked) {
					this.likeNum--;
					let condition = (element) => element.headimg == this.user.UserHeadImg;

					
				} else {
					this.likeNum++;
				}
				this.itemInfo.likes.list = res.list
				this.likeNum = res.list.length
				this.currentLiked =  this.itemInfo.likes.list.some(user => user.userphone === this.userPhone);

			}).catch(err => {
				console.error('点赞提交失败:', err);
				showFailToast("点赞提交失败")
			});
		}else {
			showFailToast("请先登录")
		}
		},
		handleVideoPlaying(video) {
			if (this.currentPlayingVideo && this.currentPlayingVideo !== video) {
				this.currentPlayingVideo.pause();
			}
			this.currentPlayingVideo = video;
		},
	},

}
</script>

<style lang="less" scoped>
.item-content {
	padding: 15px 15px 15px;
	margin-bottom: 10px;
	border-radius: 5px;
	overflow: hidden;
}

.userHeadCard {
	display: flex;
}

.avatar {
	width: 54px;
	height: 54px;
	border-radius: 27px;
	object-fit: cover;
	margin: 0;
	/* 移除图片的外边距 */
	padding: 0;
	/* 移除图片的内边距 */
}

.person-info {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-left: 10px;
	width: 100%;
	flex: 1;
}

.position {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.position-info {
	display: flex;
	flex-direction: column;
}

.position-left {
	display: flex;
	align-items: center;
}

.guanzhu {
	font-size: 16px;
	font-weight: bold;
	color: #000000DD;
	height: 34px;
	padding-left: 24px;
	padding-right: 24px;
	border-radius: 17px;
	border: 0.5px solid #C6AA87;
	display: flex;
	align-items: center;
}


.name {
	font-size: 18px;
	font-weight: bold;
}

.position-user {
	font-size: 16px;
	color: #C6AA87;
	margin-left: 10px;
	font-weight: 400;
}


.time {
	font-size: 16px;
	margin-top: 5px;
	color: #0000008A;
}

.dynamicText {
	font-size: 22px;
	margin-top: 15px;
	margin-bottom: 15px;
}

.one-image {
	height: 450px;
	width: 50%;
	object-fit: cover;
}

.video {
	height: 450px;
	width: 50%;
}

.zan {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 16px;
}

.zan-info {
	display: flex;
	align-items: center;
}

.zan-info-user {
	display: flex;
	align-items: center;

	.zan-info-user-img {
		width: 34px;
		height: 34px;
		border-radius: 17px;
		object-fit: cover;
	}



}

.zan-info-like-img {
	width: 34px;
	height: 34px;
	border-radius: 17px;
	object-fit: cover;
}

.zan-info-title {
	margin-left: 20px;
	font-weight: bold;
	font-size: 16px;
}

.comment-title {
	color: #999999;
	font-size: 18px;
	margin-top: 10px;
}

.comment-list {
	display: flex;
	align-items: center;
	font-size: 14px;
	font-weight: 400;
	color: #000000DD;
	margin-top: 10px;

}

.comment-name {
	font-weight: bold;
	color: #000000;
	font-size: 16px;
	margin-right: 16px;
}

.comment-button {
	// height: 44px;
	display: flex;
	flex-direction: column;
	justify-content: center;	
	display: flex;
	align-items: center;
	// padding-left: 30px;
	border-radius: 22px;
	// margin-top: 15px;
	// margin-bottom: 15px;
	font-size: 16px;
	color: #999999;
}

.topic {
	display: flex;
	align-items: center;
	justify-content: center;
	color: #C6AA87;
	border: 1px solid #C6AA87;
	margin-top: 15px;
	margin-bottom: 15px;
	height: 24px;
	padding-left: 20px;
	padding-right: 20px;
	width: fit-content;
	border-radius: 12px;
	font-size: 14px;
}

.company {
	display: flex;
	align-items: center;
	justify-content: center;
	width: fit-content;
	padding-left: 20px;
	padding-right: 20px;
	color: white;
	background-color: #C6AA8755;
	height: 34px;
	border-radius: 17px;
	font-size: 14px;

	img {
		width: 24px;
		height: 24px;
	}
}

.no-zan {
	display: flex;
	align-items: center;
	font-size: 14;
	font-weight: bold;

	img {
		height: 60px;
		width: 80px;
	}
}

.double {
	display: flex;
	justify-content: space-between;
}

.double-image {
	width: calc(50% - 5px);
	height: 350px;
	object-fit: cover;
}

.triple {
	display: flex;
	justify-content: space-between;
}

.triple-image {
	width: 33.33%;
	width: calc(33.33% - 10px);
	object-fit: cover;
}
</style>