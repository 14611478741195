<template>
    <div class="infinite-scroll">
        <van-pull-refresh :refreshing="isRefreshing" @refresh="onRefresh">
            <van-list :loading="isLoading" :finished="finished" finished-text="没有更多了" @load="onLoadMore">
                <slot></slot>
            </van-list>
        </van-pull-refresh>
    </div>
</template>

<script setup>
import { ref, defineProps } from 'vue';

const props = defineProps({
    refresh: {
        type: Function,
        required: true,
    },
    loadMore: {
        type: Function,
        required: true,
    },
    finished: {
        type: Boolean,
        default: false,
    },
});

const isRefreshing = ref(false);
const isLoading = ref(false);

const onRefresh = () => {
    isRefreshing.value = true;
    const result = props.refresh();
    if (result instanceof Promise) {
        result.finally(() => {
            isRefreshing.value = false;
        });
    } else {
        isRefreshing.value = false;
    }
};

const onLoadMore = () => {
    // console.log('上拉加载更多');
    if (isLoading.value == true) {
        return;
    }
    isLoading.value = true;
    const result = props.loadMore();
    if (result instanceof Promise) {
        result.finally(() => {
            isLoading.value = false;
        });
    } else {
        isLoading.value = false;
    }
};
</script>

<style scoped>
.no-margin {
    margin: 0;
    padding: 0;
}

.infinite-scroll {
    width: 100%;
}

.loading {
    text-align: center;
    padding: 16px;
}
</style>
