<template>
    <div id="rightNav">
        <!-- 登录 -->
        <div v-if="!isLoggedIn" class="sign">
            <div class="sign1">
                <ul class="horizontal-list">
                    <!-- <li class="hong" @click=clickTrue>账号登录</li> -->
                    <li class="hong" @click=clickFalse>快速登录</li>
                </ul>
                <div class="form">
                    <form action="#" method="get">
                        <div class="yonghuming" v-if=tureORflase>
                            <span class="iconfont1">&#xe610;</span>
                            <input type="text" id="haoma" required autocomplete="off" placeholder="邮箱/会员账号/手机号">
                        </div>
                        <div class="shouji" v-else>
                            <span class="iconfont1">&#xe618;</span>
                            <input class="login-input" type="text" v-model="phoneNumber" id="haoma" required
                                autocomplete="off" placeholder="手机号码">
                        </div>

                        <div class="mima" v-if=tureORflase>
                            <span class="iconfont1">&#xe60d;</span>
                            <input type="password" id="mima" required placeholder="请输入密码">
                        </div>
                        <div class="erweima" v-else>
                            <input type="text" v-model="phoneCode" id="mima" autocomplete="off" placeholder="短信验证码">
                            <CountdownTimer :phoneNumber="phoneNumber" />
                        </div>

                        <!-- <div class="danxuan clearfix">
                            <div class="fl">
                                <input type="checkbox" id="jizhuwo" value="记住我">&nbsp;&nbsp;&nbsp;<label for="jizhuwo"
                                    style="color:#9c9c9c;">记住我</label>
                            </div>
                            <div class="fr">
                                <a href="#">忘记密码</a>
                            </div>
                        </div> -->
                        <div class="login-btn" @click="loginAction">
                            登录
                        </div>
                        <!-- <div class="dibu">
                            还没有即刻工厂？<a href="#">立即注册!</a>
                        </div> -->
                    </form>
                </div>
                <!-- <div class="qita">
                    其他登录：<a href="#" class="iconfont1">&#xe608;</a>
                    <a href="#" class="iconfont1">&#xe60a;</a>
                    <a href="#" class="iconfont1">&#xe615;</a>
                    <a href="#" class="iconfont1">&#xe602;</a>
                </div> -->
            </div>
        </div>
        <Recommend></Recommend>
        <!-- 联系人 -->
        <!-- <div class="remen">
            <div class="remen1">
                <h3>联系人</h3>
                <div class="remen1-item" v-for="(item, index) in lianxiren" :key="index">
                    <img class="renmai-item-img" :src="showUpImg(item.chater_head_img)">
                    <div class="remen1-item-content">
                        <span class="remen1-item-content-title">{{ item.chater_name + item.chater_company_name }}</span>
                        <span class="remen1-item-content-descr">{{
                            showLastMessage(item.unread_message_response)
                            }}</span>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- 热门 -->
        <div v-if="topNavIndex != 2" class="remen">
            <div class="remen1">
                <h3>即刻工厂热门话题</h3>
                <div class="remen1-item" v-for="(item, index) in remenHuati" :key="index"
                    @click="topicAction(item.TopicId, item.TopicTitle)">
                    <img class="remen1-item-img" :src="showImg(item.TopicImage)">
                    <div class="remen1-item-content">
                        <span class="remen1-item-content-title">{{ item.TopicTitle }}</span>
                        <span class="remen1-item-content-descr">{{ item.TopicDescription }}</span>
                    </div>
                </div>
            </div>
            <!-- <div class="more">
                <a href="#">查看更多>></a>
            </div> -->
        </div>

        <div v-if="topNavIndex != 2" class="remen">
            <div class="remen1">
                <h3>大厂人脉</h3>
                <div class="remai-grid-container">
                    <div class="remai-grid-item" v-for="(item, index) in remai" :key="index"
                        @click="renmaiAction(item.userPhone)">
                        <img class="renmai-item-img" :src="showUpImg(item.userHeadImg)">
                        <div class="remen1-item-content">
                            <span class="remen1-item-content-title">{{ item.userName }}</span>
                            <span class="remen1-item-content-descr">{{ item.companyShortName }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 即刻工厂找人 -->


        <!-- 隐藏的登录 -->
        <div v-if="!isLoggedIn" class="sign signa">
            <div class="sign1">
                <ul class="horizontal-list">
                    <!-- <li class="hong" @click=clickTrue>账号登录</li> -->
                    <li class="hong" @click=clickFalse>手机登录</li>
                </ul>
                <div class="form">
                    <form action="#" method="get">
                        <div class="yonghuming" v-if=tureORflase>
                            <span class="iconfont1">&#xe610;</span>
                            <input type="text" id="haoma2" required autocomplete="off" placeholder="邮箱/会员账号/手机号">
                        </div>
                        <div class="shouji" v-else>
                            <span class="iconfont1">&#xe618;</span>
                            <input class="login-input" type="text" v-model="phoneNumber" id="haoma2" required
                                autocomplete="off" placeholder="手机号码" :message="{ required: '请填写手机号码' }">
                        </div>

                        <div class="mima" v-if=tureORflase>
                            <span class="iconfont1">&#xe60d;</span>
                            <input type="password" id="mima2" required placeholder="请输入密码">
                        </div>
                        <div class="erweima" v-else>
                            <input type="text" v-model="phoneCode" id="mima2" autocomplete="off" placeholder="短信验证码">
                            <CountdownTimer :phoneNumber="phoneNumber" />
                        </div>

                        <!-- <div class="danxuan clearfix">
                            <div class="fl">
                                <input type="checkbox" id="jizhuwo2" value="记住我">&nbsp;&nbsp;&nbsp;<label for="jizhuwo"
                                    style="color:#9c9c9c;">记住我</label>
                            </div>
                            <div class="fr">
                                <a href="#">忘记密码</a>
                            </div>
                        </div> -->
                        <div class="login-btn" @click="loginAction">
                            登录
                        </div>
                        <!-- <div class="dibu">
                            还没有即刻工厂？<a href="#">立即注册!</a>
                        </div> -->
                    </form>
                </div>
                <!-- <div class="qita">
                    其他登录：<a href="#" class="iconfont1">&#xe608;</a>
                    <a href="#" class="iconfont1">&#xe60a;</a>
                    <a href="#" class="iconfont1">&#xe615;</a>
                    <a href="#" class="iconfont1">&#xe602;</a>
                </div> -->
            </div>
        </div>

        <!-- 返回顶部 -->
        <div class="fanhui" @click=clickFanHui>
            返回 顶部
        </div>
        <!-- <div class="zanwei"></div> -->
    </div>
</template>


<script>
import $ from 'jquery'
// import remen from '@/./assets/remen.json'
// import redian from '@/./assets/redian.json'
import CountdownTimer from '@/components/CountdownTimer/CountdownTimer.vue'
import Recommend from '@/views/recommend/recommend.vue'

import { userLoginRequest, userInfoRequest, getOnePageSuperUserRequest, getTopicListRequest, getChatRoomRequest } from '@/api/index.ts'
import { showFailToast, showSuccessToast } from 'vant'
import * as authUtil from '@/utils/auth'
import { mapState, mapActions } from 'vuex';
import * as imgUtil from '@/utils/imgUtils'

export default {
    components: {
        CountdownTimer,
        Recommend
    },
    data() {
        return {
            phoneNumber: '',
            phoneCode: '',
            res: [],
            lianxiren: [],
            remenHuati: [],
            remai: [],
            tureORflase: false,
            readArray: [],
            discussArray: [],
            hideDengLu: false
        }
    },
    computed: {
        ...mapState(['isLoggedIn', 'userPhone', 'topNavIndex']) // 映射 isLoggedIn 到组件的计算属性
    },
    methods: {
        ...mapActions(['login']), // 映射 login 和 logout 到组件的方法
        topicAction(topicId, topicTitle) {
            // uni.navigateTo({
            // 	url: "/pages/topic/topic-home/topic-home?topicId=" + topicId
            // });
            console.log("话题 topicTitle -- ", topicTitle);

            const currentRoute = this.$route;
            const targetRoute = { name: 'TopicDetail', params: { topicId: topicId, topicTitle: topicTitle } };
            if (currentRoute.name === 'TopicDetail') {
                this.$router.replace(targetRoute);
            } else {
                this.$router.push(targetRoute);
            }
            // this.$router.push({
            //     name: 'TopicDetail',
            //     params: { topicId: topicId, topicTitle: topicTitle }
            // });
        },
        renmaiAction(userId) {
            const currentRoute = this.$route;
            const targetRoute = { name: 'UserHome', params: { userId: userId } };
            if (currentRoute.name === 'UserHome') {
                this.$router.replace(targetRoute);
            } else {
                this.$router.push(targetRoute);
            }
            // this.$router.push({
            //     name: 'UserHome',
            //     params: { userId: userId }
            // });
        },
        clickTrue: function (event) {
            let even = event || window.event,
                tag = even.target || even.srcElement;
            this.tureORflase = true;
            $(tag).addClass("hong")
            $(tag).siblings().removeClass("hong")
        },
        clickFalse: function (event) {
            let even = event || window.event,
                tag = even.target || even.srcElement;
            this.tureORflase = false;
            $(tag).addClass("hong")
            $(tag).siblings().removeClass("hong")
        },
        clickFanHui: function () {
            $(document).scrollTop(0)
        },
        // 判断滚动条是否拉到底
        handleScroll() {
            if ($(document).scrollTop() - 330 - 20 >= $(document).height() - $(window).height()) {
                $(".fanhui").css("bottom", "310px")
            } else {
                $(".fanhui").css("bottom", "40px")
            }
        },
        async loginAction() {
            if (!(/^1[3456789]\d{9}$/.test(this.phoneNumber))) {
                showFailToast("手机号格式不正确")
                return
            }

            if (this.phoneCode != "0211") {
                showFailToast("请检查验证码")
                return
            }


            try {
                const res = await userLoginRequest({ "UserPhone": this.phoneNumber })
                console.log(res);
                showSuccessToast("登录成功")
                this.userInfo()
            } catch (error) {
                console.log('登录', error);
                showFailToast("登录失败")
            }
        },
        async userInfo() {
            try {
                const res = await userInfoRequest({ "UserPhone": this.phoneNumber })
                console.log("用户信息", res)
                authUtil.setLoginForm(res.info)
                this.login(res.info);
            } catch (error) {
                console.log('用户信息', error);
            }
        },
        onePageSuperUser() {
            getOnePageSuperUserRequest({
                "UserPhone": this.userPhone, "page_number": 1
            }).then(resp => {
                this.remai = resp.info.slice(0, 5);
                console.log("推荐 list -- ", this.remai);
            }).catch(err => {
                console.error('推荐 list失败:', err);
            });
        },
        getTopicList() {
            getTopicListRequest({
                "page_number": 1
            }).then(resp => {
                this.remenHuati = resp.info
                console.log("热门话题 -- ", this.remenHuati);
            }).catch(err => {
                console.error('热门话题 list失败:', err);
            });
        },
        getChatRoom() {
            getChatRoomRequest({
                "UserPhone": this.userPhone
            }).then(resp => {
                this.lianxiren = resp.chat_rooms
                console.log("联系人 -- ", this.lianxiren);
            }).catch(err => {
                console.error('联系人 list失败:', err);
            });
        },
        showUpImg(image) {
            return imgUtil.getUpImageUrl(image);
        },
        showImg(image) {
            return imgUtil.getImageFullUrl(image);
        },
        showLastMessage(unreadMessageResponse) {
            return unreadMessageResponse?.latest_message_type == "location" ? "【位置】" : unreadMessageResponse?.latest_message_type == "image" ? "【图片】" : unreadMessageResponse?.unread_message_content
        }

    },
    created() {
        // this.res = remen;
        this.getChatRoom()
        this.getTopicList()
        this.onePageSuperUser()
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll, false)
        // this.res = remen;
        $(document).scroll(function () {
            let res = $(this).scrollTop();
            if (res >= (330 + 20)) {
                $(".fanhui").css("visibility", "visible")
            } else {
                $(".fanhui").css("visibility", "hidden")
            }
            if (res >= 330 + 2220) {
                $(".signa").css("visibility", "visible")
            } else {
                $(".signa").css("visibility", "hidden")
            }
            if (res >= (330 + 20)) {
                $("#recommend").css("position", "fixed")
                $("#recommend").css("top", "60px")

                // $("#plantContent").css("margin-left","60px")
            } else {
                $("#recommend").css("position", "static")
                // $("#plantContent").css("margin-left","30px")
            }
        })

    }
}
</script>

<style>
@charset "utf-8";
@import '../assets/css/rightNav.css';

.zanwei {
    width: 300px;
    height: 180px;
}

#recommend {
    width: 300px;
}

.remen1-item-img {
    width: 64px;
    height: 64px;
    margin-right: 10px;
    margin-bottom: 10px;
}

.remai-grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 10px;
    padding: 10px;
    background-color: white;
}

.remai-grid-item {
    background-color: #ffffff;
    border: 1px solid #d0d0d0;
    padding: 20px;
    text-align: center;
    font-size: 16px;
}

.remen1-item-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: black;
}

.remen1-item-content-title {
    font-size: 14px;
    margin-bottom: 10px;
}

.remen1-item-content-title:hover {
    color: #C6AA87;
}

.remen1-item-content-descr {
    color: #696e78;
    font-size: 12px;
    margin-bottom: 10px;
}

.renmai-item-img {
    width: 54px;
    height: 54px;
    border-radius: 27px;
    object-fit: cover;
    border: solid 1px #efefef;
    margin-right: 10px;
    margin-bottom: 10px;
}

.renmai-item-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: black;
}

.renmai-item-content-title {
    font-size: 14px;
    margin-bottom: 10px;
}


#rightNav {
    width: 300px;
    /* height: 300px; */
    float: right;
    box-sizing: border-box;
    border-radius: 5px;
    position: relative;
}


/* 登录 */
#rightNav .signa {
    position: fixed;
    top: 60px;
    visibility: hidden;
    margin-bottom: 10px;
}

#rightNav .sign {
    box-sizing: border-box;
    width: 300px;
    /* height: 306px; */
    /* background-color: #f2f2f5; */
    /* padding: 20px; */
}

#rightNav .sign .sign1 {
    box-sizing: border-box;
    width: 300px;
    /* height: 306px; */
    margin: 0 auto;
    /* border: 1px solid #efefef; */
    background-color: #fff;
    padding: 10px 10px;
    border-radius: 5px;
    margin-bottom: 16px;

}

#rightNav .sign .sign1 ul li {
    box-sizing: border-box;
    float: left;
    list-style: none;
    text-align: center;
    font-size: 14px;
    color: #9c9c9c;
    width: 135px;
    line-height: 44px;
    border-bottom: 1px solid #efefef;
}

.horizontal-list {
    list-style-type: none;
    /* 移除列表项的默认样式 */
    padding: 0;
    /* 移除列表的内边距 */
    margin: 0;
    /* 移除列表的外边距 */
    display: flex;
    /* 使用 Flex 布局 */
    line-height: 44px;
    justify-content: center;
    border-bottom: 1px solid #efefef;
}

#rightNav .sign .sign1 ul li:hover {
    cursor: pointer;
}

#rightNav .sign .sign1 ul li.hong {
    color: #333;
    border-bottom: 2px solid #ff8140;
}

#rightNav .sign .sign1 .form {
    width: 280px;
    height: 191px;
    box-sizing: border-box;
    /* border-bottom: 1px solid #efefef; */
    margin-top: 10px;
}

#rightNav .sign .sign1 .form .yonghuming {
    border-radius: 2px;
    padding-top: 2px;
    width: 280px;
    height: 34px;
    box-sizing: border-box;
    border: 1px solid #efefef;
}

#rightNav .sign .sign1 .form .yonghuming span {
    text-align: center;
    display: inline-block;
    font-size: 20px;
    width: 12%;
    height: 28px;
    line-height: 28px;
}

#rightNav .sign .sign1 .form .yonghuming input {
    width: 85%;
    height: 24px;
    line-height: normal;
    margin-bottom: 8px;
    vertical-align: middle;
    border: none;
    box-shadow: none;
    outline: none;
}

.shouji {
    border-radius: 2px;
    width: 280px;
    height: 44px;
    box-sizing: border-box;
    border: 1px solid #efefef;
    display: flex;
    align-items: center;
    margin-top: 20px;

}

.iconfont1 {
    text-align: center;
    display: inline-block;
    font-size: 20px;
    width: 12%;
    height: 28px;
    line-height: 28px;
}

.login-input {
    width: 85%;
    height: 34px;
    line-height: normal;
    vertical-align: middle;
    border: none;
    box-shadow: none;
    outline: none;
}

#rightNav .sign .sign1 .form .mima {
    border-radius: 2px;
    padding-top: 2px;
    width: 280px;
    height: 34px;
    box-sizing: border-box;
    border: 1px solid #efefef;
    margin-top: 12px;
}

#rightNav .sign .sign1 .form .mima span {
    text-align: center;
    display: inline-block;
    font-size: 20px;
    width: 12%;
    height: 28px;
    line-height: 28px;
}

#rightNav .sign .sign1 .form .mima input {
    width: 85%;
    height: 44px;
    line-height: normal;
    vertical-align: middle;
    border: none;
    box-shadow: none;
    outline: none;
}

#rightNav .sign .sign1 .form .erweima {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 280px;
    height: 44px;
    box-sizing: border-box;
    margin-top: 15px;
}


#rightNav .sign .sign1 .form .erweima input {
    border: 1px solid #efefef;
    border-radius: 2px;
    padding-left: 10px;
    box-sizing: border-box;
    width: 165px;
    height: 44px;
    outline-color: #ff8140
}

#rightNav .sign .sign1 .form .danxuan {
    padding-top: 2px;
    width: 280px;
    height: 15px;
    line-height: 15px;
    box-sizing: border-box;
    margin-top: 12px;
    color: #9c9c9c;
}

#rightNav .sign .sign1 .form .danxuan input {
    vertical-align: middle;
}

#rightNav .sign .sign1 .form .danxuan a {
    color: #9c9c9c;
}

#rightNav .sign .sign1 .form .danxuan a:hover {
    color: #ff8140;
}

.login-btn {
    width: 280px;
    height: 44px;
    box-sizing: border-box;
    margin-top: 12px;
    background-color: #ff8140;
    border-radius: 2px;
    margin-top: 30px;
    text-align: center;
    line-height: 44px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
}

.login-btn:hover {
    cursor: pointer;
    background-color: #eb7350;
}

#rightNav .sign .sign1 .form .btn input {
    width: 280px;
    height: 44px;
    background-color: #ff8140;
    border-radius: 2px;
    line-height: 34px;
    text-align: center;
    border: 1px solid #f77c3d;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
}

#rightNav .sign .sign1 .form .btn input:hover {
    cursor: pointer;
    background-color: #eb7350;
}

#rightNav .sign .sign1 .form .dibu {
    width: 280px;
    height: 26px;
    box-sizing: border-box;
    margin-top: 12px;
    color: #9c9c9c;
}

#rightNav .sign .sign1 .form .dibu a {
    color: #eb7350;
}

#rightNav .sign .sign1 .qita {
    width: 280px;
    height: 16px;
    line-height: 16px;
    margin-top: 10px;
    color: #9c9c9c;
}

#rightNav .sign .sign1 .qita a {
    color: #ff8140;
    margin-left: 10px;
}


/* 热门 */
#rightNav .remen {
    box-sizing: border-box;
    width: 300px;
    background-color: white;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 10px;
}

.remen1-item {
    display: flex;

}

#rightNav .remen .remen1 {
    box-sizing: border-box;
    width: 300px;
    padding-left: 10px;
    padding-right: 10px;
}

#rightNav .remen .remen1 h3 {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 16px;
}

#rightNav .remen .remen1 ul {
    /* background-color: red; */
    padding-left: 0;
    /* 移除内边距 */
    margin-left: 0;
    /* 移除外边距 */
    list-style-type: none;
    /* 移除项目符号 */
}

#rightNav .remen .remen1 li {
    list-style: none;
    height: 50px;
    margin-top: 20px;
}

#rightNav .remen .remen1 li a {
    display: block;
    width: 300px;
    height: 50px;
    color: black;
}

#rightNav .remen .remen1 li a img {
    float: left;
    width: 50px;
    height: 50px;
}

#rightNav .remen .remen1 li a div {
    /* padding-top:  */
    /* margin-top: 6px; */
    margin-left: 12px;
    float: right;
    width: 238px;
    height: 44px;
}

#rightNav .remen .remen1 li a div h4 {
    width: 238px;
    overflow: hidden;
    font-weight: normal;
    font-size: 14px;
    height: 22px;
    line-height: 22px;
    text-overflow: ellipsis;
    white-space: nowrap;
}

#rightNav .remen .remen1 li a div h4:hover {
    color: #ff8140;
    cursor: pointer;
}

#rightNav .remen .remen1 li a div span {
    display: inline-block;
    margin-top: 5px;
    color: #9c9c9c;
    height: 22px;
    line-height: 22px;
    width: 238px;
    overflow: hidden;
}

#rightNav .remen .more {
    box-sizing: border-box;
    width: 300px;
    height: 34px;
    line-height: 34px;
    border-top: 1px solid #9c9c9c;
    text-align: center;
}

#rightNav .remen .more a {
    display: inline-block;
    width: 300px;
    height: 34px;
    line-height: 34px;
}


/* 热点 */
#rightNav .redian {
    box-sizing: border-box;
    width: 300px;
    height: 444px;
    margin-top: 10px;
    background-color: white;
    border-radius: 5px;
    overflow: hidden;
}

#rightNav .redian .redian1 {
    box-sizing: border-box;
    width: 300px;
    height: 410px;
    padding-left: 20px;
    padding-right: 20px;
}

#rightNav .redian .redian1 h3 {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 16px;
}

#rightNav .redian .redian1 ul {
    /* background-color: red; */
    padding-left: 0;
    /* 移除内边距 */
    margin-left: 0;
    /* 移除外边距 */
    list-style-type: none;
    color: black;
    /* 移除项目符号 */
}

#rightNav .redian .redian1 li {
    width: 300px;
    list-style: none;
    height: 50px;
    margin-top: 20px;
    color: black;
}

#rightNav .redian .redian1 li a {
    display: block;
    width: 300px;
    height: 50px;
    color: black;
}

#rightNav .redian .redian1 li a img {
    float: left;
    width: 50px;
    height: 50px;
}

#rightNav .redian .redian1 li a div {
    /* padding-top:  */
    /* margin-top: 6px; */
    margin-left: 12px;
    float: right;
    width: 238px;
    height: 44px;
}

#rightNav .redian .redian1 li a div h4 {
    width: 238px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: normal;
    font-size: 14px;
    height: 22px;
    line-height: 22px;
}

#rightNav .redian .redian1 li a div h4:hover {
    color: #ff8140;
    cursor: pointer;
}

#rightNav .redian .redian1 li a div span {
    display: inline-block;
    margin-top: 5px;
    color: #9c9c9c;
    height: 22px;
    line-height: 22px;
    width: 238px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

#rightNav .redian .more {
    box-sizing: border-box;
    width: 340px;
    height: 34px;
    line-height: 34px;
    border-top: 1px solid #9c9c9c;
    text-align: center;
}

#rightNav .redian .more a {
    display: inline-block;
    width: 340px;
    height: 34px;
    line-height: 34px;
}


/* 即刻工厂找人 */
#rightNav .zhaoren {
    box-sizing: border-box;
    width: 340px;
    height: 827px;
    border-top: 1px solid #fb3c31;
    margin-top: 20px;
    background-color: #f2f2f5;
    padding: 20px;
}

#rightNav .zhaoren h3 {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 16px;
}

#rightNav .zhaoren .mingren {
    box-sizing: border-box;
    width: 300px;
    height: 107px;
    border-bottom: 1px solid #9c9c9c;
    color: #9c9c9c;
}

#rightNav .zhaoren .mingren h4 {
    font-size: 16px;
    font-weight: normal;
    color: #9c9c9c;
    margin-bottom: 16px;
}

#rightNav .zhaoren .mingren li {
    height: 15px;
    line-height: 15px;
    list-style: none;
    width: 33.3%;
    margin-bottom: 20px;
    float: left;
}

#rightNav .zhaoren .mingren li i {
    color: #9c9c9c;
}

#rightNav .zhaoren .mingren li i:hover {
    cursor: pointer;
}

#rightNav .zhaoren .mingren li span {
    margin-left: 5px;
    display: inline-block;
    vertical-align: middle;
    line-height: 14px;
    color: #9c9c9c;
}

#rightNav .zhaoren .mingren li span:hover {
    color: #ff8140;
    cursor: pointer;
}

#rightNav .zhaoren .zhuanjia {
    box-sizing: border-box;
    margin-top: 20px;
    width: 300px;
    height: 247px;
    border-bottom: 1px solid #9c9c9c;
}

#rightNav .zhaoren .zhuanjia h4 {
    font-size: 16px;
    font-weight: normal;
    color: #9c9c9c;
    margin-bottom: 16px;
}

#rightNav .zhaoren .zhuanjia li {
    height: 15px;
    line-height: 15px;
    list-style: none;
    width: 33.3%;
    margin-bottom: 20px;
    float: left;
}

#rightNav .zhaoren .zhuanjia li i {
    color: #9c9c9c;
}

#rightNav .zhaoren .zhuanjia li i:hover {
    cursor: pointer;
}

#rightNav .zhaoren .zhuanjia li span {
    margin-left: 5px;
    display: inline-block;
    vertical-align: middle;
    line-height: 14px;
    color: #9c9c9c;
}

#rightNav .zhaoren .zhuanjia li span:hover {
    color: #ff8140;
    cursor: pointer;
}

#rightNav .zhaoren .xingqu {
    box-sizing: border-box;
    margin-top: 20px;
    width: 300px;
    height: 282px;
    border-bottom: 1px solid #9c9c9c;
}

#rightNav .zhaoren .xingqu h4 {
    font-size: 16px;
    font-weight: normal;
    color: #9c9c9c;
    margin-bottom: 16px;
}

#rightNav .zhaoren .xingqu li {
    height: 15px;
    line-height: 15px;
    list-style: none;
    width: 33.3%;
    margin-bottom: 20px;
    float: left;
}

#rightNav .zhaoren .xingqu li i {
    color: #9c9c9c;
}

#rightNav .zhaoren .xingqu li i:hover {
    cursor: pointer;
}

#rightNav .zhaoren .xingqu li span {
    margin-left: 5px;
    display: inline-block;
    vertical-align: middle;
    line-height: 14px;
    color: #9c9c9c;
}

#rightNav .zhaoren .xingqu li span:hover {
    color: #ff8140;
    cursor: pointer;
}

#rightNav .zhaoren .jigou {
    box-sizing: border-box;
    margin-top: 20px;
    width: 300px;
    height: 71px;
}

#rightNav .zhaoren .jigou h4 {
    font-size: 16px;
    font-weight: normal;
    color: #9c9c9c;
    margin-bottom: 16px;
}

#rightNav .zhaoren .jigou li {
    height: 15px;
    line-height: 15px;
    list-style: none;
    width: 33.3%;
    margin-bottom: 20px;
    float: left;
}

#rightNav .zhaoren .jigou li i {
    color: #9c9c9c;
}

#rightNav .zhaoren .jigou li i:hover {
    cursor: pointer;
}

#rightNav .zhaoren .jigou li span {
    margin-left: 5px;
    display: inline-block;
    vertical-align: middle;
    line-height: 14px;
    color: #9c9c9c;
}

#rightNav .zhaoren .jigou li span:hover {
    color: #ff8140;
    cursor: pointer;
}


/* 投诉 */
#rightNav .touSu {
    box-sizing: border-box;
    padding: 20px;
    width: 340px;
    height: 57px;
    border-top: 1px solid #fb3c31;
    margin-top: 20px;
    background-color: #f2f2f5;
}


/* 返回顶部 */
#rightNav .fanhui {
    width: 50px;
    height: 50px;
    line-height: 25px;
    text-align: center;
    position: fixed;
    bottom: 40px;
    background-color: #9c9c9c;
    margin-left: 345px;
    word-break: keep-all;
    cursor: pointer;
    font-size: 12px;
    color: #fff;
    font-weight: bold;
    visibility: hidden;
}

#rightNav .fanhui:hover {
    background-color: #696e78;
}
</style>