<template>
    <div class="search-component">
        <div class="search-box">
            <img src="@/assets/image/pison/sousuoM.png" class="nav-search-icon">
            <input v-model="searchQuery" @keyup.enter="onSearch" placeholder="请输入搜索内容..." />
        </div>
        <AreaSelect name="cjPovo" :selectValue="cjPovo" :readonly="true" :keyValue="`code`" :columnsNum="3"
            @confirm="areaConfirm" :required="true" :rules="rules.cjPovo" />
    </div>
</template>

<script>
import AreaSelect from "@/components/City-Selecter/CitySelecter";

export default {
    name: 'SearchComponent',
    components: {
        //  Area,
        //  Popup,
        // Field,
        AreaSelect
    },
    data() {
        return {
            searchQuery: '',
            showPicker: false,
            selectedArea: [],
            cjPovo: "",
            rules: {
                cjPovo: [
                    {
                        required: true,
                        message: "选择地址",
                    },
                ],
            },
        };
    },
    methods: {
        areaConfirm(data) {
            this.cjPovo = data;
            console.log("地址选择", this.cjPovo);
            this.$emit('citySelected', this.cjPovo);
        },
        onSearch() {
            this.$emit('search', this.searchQuery);
        }
    }
};
</script>

<style scoped>
.search-component {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 54px;

}

.nav-search-icon {
    width: 24px;
    height: 24px;
    margin-left: 10px;
    /* position: absolute; */
    /* left: 10px; */
}

.search-box {
    flex: 2;
    height: 40px;
    margin-top: 0;
    margin-left: 10px;
    position: relative;
    border-radius: 22px;
    background-color: #F0F2F5;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.search-box input {
    flex: 2;
    margin-right: 10px;
    border: none;
    outline: none;
    background: transparent;
    /* width: calc(100% - 44px); */
    padding: 5px;
    background-color: transparent;
    font-size: 16px;
}
</style>