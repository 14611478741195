<template>
  <div>
    <img :src="computedSrc" @error="handleImageError" :style="{ borderRadius: borderRadius }" />
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: () => require('@/assets/image/pison/image_placeHolder.png') // 默认占位图地址
    },
    borderRadius: {
      type: String,
      default: '0px' // 默认圆角为 0
    }
  },
  data() {
    return {
      isError: false
    };
  },
  computed: {
    computedSrc() {
      console.log('Computed source:', this.isError ? this.placeholder : this.src);
      return this.isError ? this.placeholder : this.src;
    }
  },
  methods: {
    handleImageError() {
      console.log('Image load error, displaying placeholder');
      this.isError = true;
    }
  },
  watch: {
    src() {
      // Reset error state if src prop changes
      this.isError = false;
    }
  }
};
</script>

<style scoped>
img {
  width: 100%;
  /* 设置图片宽度 */
  height: 100%;
  /* 设置图片高度 */
  object-fit: cover;
  /* 保持图片的比例 */
}
</style>
