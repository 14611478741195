<template>
	<no-data v-if="isEmpty" description="暂无企业~~" padding="100px 0"></no-data>
	<div v-else class="course-content">
		<div class="course-item" v-for="(item ,index) in list" :index="index" :key="index">
			<companyCategoryItem v-if="isCategory" :item-info="item" :indexN="index"></companyCategoryItem>
			<companyItem v-else :item-info="item" :indexN="index"></companyItem>
		</div>
	</div>
</template>

<script>
	import companyItem from '../../components/company-item/company-item.vue';
	import companyCategoryItem from '../company-category/company-category-item.vue';
	import noData from "@/components/no-data/index"

	export default {
		props: {
			list: {
				type: Array,
				default: function() {
					return [];
				}
			},
			isCategory: {
				type: Boolean,
				default: function() {
					return false;
				}
			},

		},
		components: {
			companyItem,
			companyCategoryItem,
			noData
		},
		computed: {
			isEmpty() {
				return !this.list || this.list.length == 0
			},
		},
		onLoad() {
			console.log("公司----", this.list);
		},
		data() {
			return {

			};
		}
	}
</script>

<style lang="less">
	.course-content {
		position: relative;
		display: block;

		.course-item {
			background-color: white;
		
			
		}
	}
</style>