<template>
  <div id="app">
    <TopSearch />
    <head-nav />
    <section1 />
    <div style="clear:both"></div>
    <!-- <foot /> -->
  </div>
</template>

<script>
import HeadNav from './components/HeadNav.vue'
import Section1 from './components/Section.vue'
import TopSearch from './components/TopSearch.vue'
// import Foot from './components/Foot.vue'
export default {
  name: 'App',
  components: {
    HeadNav,
    Section1,
    TopSearch
    // Foot
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: auto;
  color: #2c3e50;
  margin: 0;
  padding: 0;
  background-color: #F1F2F5;
  max-width: 100%;
  max-height: 100%;
  overflow-x: auto;
  /* overflow: hidden; */
  /* 防止内容超出容器 */
  box-sizing: border-box;
  /* 包括边框和内边距在内的总宽度和高度 */
  /* 设置所有元素的鼠标样式为默认箭头 */
* {
  cursor: pointer;
}

/* 设置所有链接的鼠标样式为手型指针 */
a {
  cursor: pointer;
}

/* 设置特定类的鼠标样式为文本输入指针 */
.text-input {
  cursor: text;
}
}
</style>
