<template>
  <div class="company-menu">
    <div class="menu-wrapper" ref="menuWrapper">
      <span
        v-for="(item, index) in menues"
        :key="index"
        class="pointer-cursor"
        @click="itemAction(index)"
      >
        <div :class="setupItemStyle(index)">{{ item.BaseTypeName }}</div>
      </span>
    </div>
    <!-- <AreaSelect name="cjPovo" :selectValue="cjPovo" :readonly="true" :keyValue="`code`" :columnsNum="3"
        @confirm="areaConfirm" :required="true" :rules="rules.cjPovo" /> -->
  </div>
</template>

<script>
import $ from 'jquery';
import EventBus from '@/eventBus';
import { getOneTypeListRequest } from '@/api/index.ts';
import { Popup, Area, Field } from 'vant';
// import AreaSelect from "@/components/City-Selecter/CitySelecter";

export default {
  components: {
    //  Area,
    //  Popup,
    // Field,
    // AreaSelect
  },
  data() {
    return {
      menuSelectIndex: 0,
      menues: [],
      topMenuIndex: 0,
      showPicker: false,
      selectedArea: [],
      cjPovo: "",
      rules: {
        cjPovo: [
          {
            required: true,
            message: "选择地址",
          },
        ],
      },
     
      
    };
  },
  computed: {
    // selectedAreaText() {
    //   console.log("areaList",this.areaList)
    //   return this.selectedArea.join(' / ');
    // }
  },
  methods: {
     // 点击确定
     areaConfirm(data) {
      this.cjPovo = data;
      console.log("地址选择",this.cjPovo);
      this.$emit('citySelected', this.cjPovo );

    },
    // 提交
    submitOn() {
      console.log(this.cjPovo);
    },
    setupItemStyle(index) {
      return index == this.menuSelectIndex ? 'hong' : 'default-color';
    },
    selectedAreaText() {
      // return this.selectedArea.join(' / ');
    },
    itemAction(index) {
      this.menuSelectIndex = index;
      // this.$router.push({
      //   name: 'CompanyCategory',
      //   params: { currentTypeId: this.menues[index].id },
      // });

      this.$emit('menuSelected', this.menues[index].id);
    },
    showAreaPicker() {
      this.showPicker = true;
    },
    hideAreaPicker() {
      this.showPicker = false;
    },
    onAreaConfirm(area) {
      this.selectedArea = area;
      console.log('选中的地区：', area);
      console.log("areaList ---",this.areaList)
      this.showPicker = false; // 确认选择后关闭地区选择器
    },
    handleChangeMenu(index) {
      console.log('index', index);
      this.topMenuIndex = index;
    },
    getOneTypeList() {
      getOneTypeListRequest()
        .then((resp) => {
          console.log('行业一级分类 -- ', resp);
          this.menues = resp.info;
          if (this.menues.length > 0) {
            this.currentTypeId = this.menues[0].id;
            this.itemAction(0);
          }
          console.log('行业一级分类 types -- ', this.menues);
        })
        .catch((err) => {
          console.error('行业以及分类失败:', err);
        });
    },
  },
  created() {
    // 滚动悬浮左侧的导航栏
    $(document).scroll(() => {
      let res = $(this).scrollTop();
      if (res >= 350) {
        $('#leftNav').css('position', 'fixed').css('top', '60px');
      } else {
        $('#leftNav').css('position', 'static');
      }
    });
    this.getOneTypeList();
  },
  mounted() {
    EventBus.on('changeMenu', this.handleChangeMenu);

    // 初始化水平滚动
    $(this.$refs.menuWrapper).css('overflow-x', 'auto');

    // 如果需要在每次路由切换后重新计算菜单宽度，请解开以下注释
    // this.$watch('$route', () => {
    //   this.$nextTick(() => {
    //     this.calculateMenuWidth();
    //   });
    // });
  },
  beforeDestroy() {
    EventBus.off('changeMenu', this.handleChangeMenu);
  },
};
</script>

<style scoped>
.pointer-cursor {
  cursor: pointer;
}

.company-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  height: 54px;
  padding-left: 20px;
}

.menu-wrapper {
  display: flex;
  flex-wrap: nowrap; /* 不换行 */
}

.menu-wrapper span {
  white-space: nowrap; /* 不换行 */
  margin-right: 20px; /* 可根据需要调整间距 */
}

.default-color {
  color: #000000;
}

.hong {
  color: #d18f25;
}

#leftNav span:hover {
  background-color: #f2f2f2;
}
</style>
