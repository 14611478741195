import request from '@/config/axios'

// 获取验证码
export const sendCodeRequest = async (data: Map<string, any>) => {
    return await request.upload({ url: '/UserAdmin/SendCode/', data })
}

// 登录
export const userLoginRequest = async (data: Map<string, any>) => {
    return await request.upload({ url: '/UserAdmin/UserLogin/', data })
}

// 用户信息
export const userInfoRequest = async (data: Map<string, any>) => {
    return await request.upload({ url: '/UserAdmin/UserLogin/', data })
}

// 动态顶部话题
export const getTopicListRequest = async (data: Map<string, any>) => {
    return await request.upload({ url: '/Dynamic/GetTopicList/', data })
}

// 动态列表
export const get_dynamic_listRequest = async (data: Map<string, any>) => {
    console.log("动态请求 -- ", data);
    return await request.upload({ url: '/Dynamic/get_dynamic_list/', data })
}

// 评论
export const comment_dynamicRequest = async (data: Map<string, any>) => {
    return await request.upload({ url: '/Dynamic/comment_dynamic/', data })
}

// 首页推荐的公司接口
export const getOnePageSuperUserRequest = async (data: Map<string, any>) => {
    return await request.upload({ url: '/Companyinfo/getOnePageSuperUser/', data })
}
// 推荐
export const getOnepageInfoRequest = async (data: Map<string, any>) => {
    return await request.upload({ url: '/Companyinfo/getOnepageInfo/', data })
}

// 聊天室消息
export const getChatRoomRequest = async (data: Map<string, any>) => {
    return await request.upload({ url: '/Chat/getChatRoom/', data })
}

// 聊天详情用户信息

export const getChatUserInfoRequest = async (data: Map<string, any>) => {
    return await request.upload({ url: '/Chat/ChatUserInfo/', data })
}

// 聊天详情列表
export const getGetUserMessageRequest = async (data: Map<string, any>) => {
    return await request.upload({ url: '/Chat/GetUserMessage/', data })
}


// 话题详情
export const getSingleTopicInfoRequest = async (data: Map<string, any>) => {
    return await request.upload({ url: '/Dynamic/GetSingleTopicInfo/', data })
}

// 话题动态
export const find_dynamics_by_topicRequest = async (data: Map<string, any>) => {
    return await request.upload({ url: '/Dynamic/find_dynamics_by_topic/', data })
}


// 公司分类
export const getOneTypeListRequest = async () => {
    return await request.upload({ url: '/TypeAdmin/GetOneTypeList/' })
}

// 公司列表
export const getTypeCompanyListRequest = async (data: Map<string, any>) => {
    return await request.upload({ url: '/Companyinfo/TypeCompanyList/',data })
}

// 搜索公司
export const companySearchRequest = async (data: Map<string, any>) => {
    return await request.upload({ url: "/Companyinfo/CompanySearch/",data })
}

// 搜索用户
export const userSearchRequest = async (data: Map<string, any>) => {
    return await request.upload({ url: "/UserAdmin/UserSearch/",data })
}

// 搜索动态
export const get_dynamic_ByQueryRequest = async (data: Map<string, any>) => {
    return await request.upload({ url: "/Dynamic/get_dynamic_ByQuery/",data })
}

/** 用户模块 */
export const get_mine_dynamicRequest = async (data: Map<string, any>) => {
    return await request.upload({ url: "/Dynamic/get_mine_dynamic/",data })
}

/** 公司详情*/
export const companydetailRequest = async (data: Map<string, any>) => {
    return await request.upload({ url: "/Companyinfo/Companydetail/",data })
}

/** 公司信息 */
export const getCompanyShowRequest = async (data: Map<string, any>) => {
    return await request.upload({ url: "/Dynamic/GetCompanyShow/",data })
}

/** 发布 */
export const pub_dynamicRequest = async (data: Map<string, any>) => {
    return await request.upload({ url: "/Dynamic/pub_dynamic/",data })
}

// 关注
export const userCollectUserRequest = async (data: Map<string, any>) => {
    return await request.upload({ url: "/Collecting/UserCollectUser/",data })
}

// 点赞
export const press_dynamicRequest = async (data: Map<string, any>) => {
    return await request.upload({ url: "/Dynamic/press_dynamic/",data })
}

// 首页封面视频
export const getWelComeVideoinfoRequest = async () => {
    return await request.upload({ url: "/AdverAdmin/GetWelComeVideoinfo/" })
}

// 首页logo
export const getLogoIconinfoRequest = async () => {
    return await request.upload({ url: "/AdverAdmin/GetLogoIconinfo/" })
}


	