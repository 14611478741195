<template>
	<div id="nav" class="nav-bar">
		<div class="nav-left">
			<slot name="left">
				<img class="default-back-button" @click="goBack" src="@/assets/image/pison/back-arrow.png">
			</slot>
		</div>
		<div class=" nav-title">
			<slot name="title"></slot>
		</div>
		<div class="nav-right">
			<slot name="right"></slot>
		</div>
	</div>
</template>

<script>
import $ from 'jquery'
export default {
	name: 'NavBar',
	data() {
		return {

		};
	},
	methods: {
		goBack() {
			window.history.back();
		}
	},
	created() {
		// 滚动悬浮左侧的导航栏
		$(document).scroll(function () {
			let res = $(this).scrollTop();
			if (res >= (330 + 20)) {
				$("#nav").css("position", "fixed")
				$("#nav").css("top", "60px")
			} else {
				$("#nav").css("position", "static")
			}
		});
	},
};
</script>

<style scoped>
.nav-bar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 64px;
	background-color: white;
	padding: 0 10px;
	border-bottom: 1px solid #ddd;
	width: calc(660px - 20px);
	margin-right: 10px;
	overflow: hidden;
	z-index: 996 !important;
}

.default-back-button {
	width: 34px;

}

.nav-left,
.nav-right {
	flex: 0 0 auto;
}

.nav-title {
	flex: 1 1 auto;
	text-align: center;
	font-size: 18px;
	font-weight: bold;
}
</style>
