<template>

	<div class="listCon">
		<RefreshLoadMore :refresh="onRefresh" :loadMore="onLoadMore" :finished="finished">
			<groupList :list="dynamicList"></groupList>
		</RefreshLoadMore>
	</div>
</template>
<script>
import {
	get_dynamic_listRequest
} from "@/api/index.ts"
import groupList from "@/views/group/group-list.vue";
import { config } from '@/config/axios/config'
const { base_url } = config
import { mapState } from 'vuex';
import RefreshLoadMore from '@/components/RefreshLoadMore/RefreshLoadMore.vue';
export default {
	components: {
		groupList,
		RefreshLoadMore,
	},
	data() {
		return {
			activeIndex: 0,
			topList: [],
			dynamicList: [],
			currentPage: 1,
			finished: false
		}
	},
	computed: {
		...mapState(['userPhone'])
	},

	mounted() {
		this.getDynamicList();
	},

	methods: {
		onRefresh() {
			console.log("onRefresh -- ");
			this.finished = false;
			this.currentPage = 1
			this.getDynamicList()
		},
		onLoadMore() {
			console.log("onLoadMore -- 加载更多");
			this.finished = false;
			this.currentPage += 1
			this.getDynamicList()
		},
		getDynamicList() {
			get_dynamic_listRequest({
				"UserPhone": this.userPhone,
				"page": this.currentPage
			}).then(resp => {
				console.log("动态 -- ", resp);
				if (this.currentPage == 1) {
					this.dynamicList = resp.info;

				} else {
					if (resp.info.length > 0) {
						this.dynamicList = this.dynamicList.concat(resp.info);
					} else {
						this.finished = true
						console.log("动态 --  finished", this.finished);
					}
				}
				console.log("动态 dynamicList -- ", this.dynamicList);
			}).catch(err => {
                console.error('用户动态 list失败:', err);
            });
		},

		getImageFullUrl(imageUrl) {
			return base_url + imageUrl;
		},

	},

}
</script>

<style lang="less" scoped>
.no-margin {
	margin: 0;
	padding: 0;
}

.listCon {
	margin: 0;
	padding: 0;
}
</style>