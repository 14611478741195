<template>

	<div class="pages">
		<!-- 	<div class="bgc_pic" :class="isPlay==1?'isPlatwp':isPlay==2?'isPlafh':''">
			<img :src="getImageFullUrl("/upimg/" + userInfo.UserHeadImg)" :style="{height: sheight  + 'px'}"
				:class="isPlay==1?'isPlay':isPlay==2?'isPlaasf':''" mode="top">
			</image>
		</div>
		<div class="nav-left-back" @click="navBack" :style="{top: navbarTop + 'px'}">
			<uni-icons class="nav-left-back-icon" type="left" size="30"></uni-icons>
		</div> -->
		<NavBar v-if="userId != userPhone && userId != ''">
			<template v-slot:title>
				详情
			</template>
		</NavBar>
		<no-data v-if="userInfo == undefined" description="暂无企业~~" padding="100px 0"></no-data>
		<div v-else class="content">
			<div class="user-info">
				<NetImage class="user-image" :src="getImageUrl(userInfo.UserHeadImg)" borderRadius="32px" />
				<div class="name-position">
					<text class="company-name">{{ userInfo.UserName }}</text>
					<text class="company-companyNameDetail">{{ userInfo.UserPosition }}</text>
				</div>
			</div>
			<text class="company-companyIntroduce">{{ userInfo.UserIntro }}</text>
			<div class="tag">
				<text class="tagChild">{{ userInfo.UserComPan.msg }}</text>
			</div>

			<div class="address">
				<!-- <img src="../../static/images/LocationIcon.png" mode="aspectFit"> -->
				<text>{{ addressInfo.proviceName + "·" + addressInfo.cityName }}</text>
			</div>
			<div class="scroll">
				<div class="box1">
					<div class="item">
						<text>5</text>
						<text>同事</text>
					</div>
					<div class="item">
						<text>{{ absAction(userInfo.UserFollowNum) }}</text>
						<text>关注</text>
					</div>
					<div class="item">
						<text>{{ userInfo.UserFansNum }}</text>
						<text>粉丝</text>
					</div>
					<div class="item">
						<text>5</text>
						<text>获赞</text>
					</div>
					<div class="item">
						<text>5</text>
						<text>收获评论</text>
					</div>
					<div class="item">
						<text>5</text>
						<text>我点赞的</text>
					</div>
				</div>
			</div>

			<text class="wonderful-case">动态</text>
			<groupList :list="dynamicList"></groupList>
		</div>
	</div>
</template>

<script>

import groupList from "@/views/group/group-list.vue";
import NavBar from "@/components/navbar/navbar.vue";
import NetImage from "@components/NetImage/NetImage.vue";
import {
	userInfoRequest,
	get_mine_dynamicRequest
} from "@/api/index.ts"
import { config } from '@/config/axios/config'
const { base_url } = config
import { mapState } from 'vuex';
import noData from "@/components/no-data/index"

export default {
	name: 'UserHome',
	// props: {
	// 	userId: {
	// 		type: String,
	// 		required: false, // 如果参数是可选的，可以设置为 false
	// 	}
	// },
	components: {
		NavBar,
		groupList,
		noData,
		NetImage
	},
	computed: {
		...mapState(['userPhone'])
	},
	beforeRouteUpdate(to, from, next) {
        // 处理路由参数变化的逻辑
        this.userId = to.params.userId;
        next();
		this.userLoginInfo();
		this.get_mine_dynamic();
    },
	watch: {
		userId(newUserId, oldUserId) {
			console.log('Type ID changed from', newUserId, 'to', oldUserId);
			this.userLoginInfo();
			this.get_mine_dynamic();
		}
	},

	data() {
		return {
			// 用户操作
			clientData: {
				// 用户手指Y轴
				clientMoveY: '', //按下
				clientEndY: '', //抬起
				// 页面Y轴
				pageY: ''
			},
			sheight: 562, //高度
			isPlay: 0, //是否播放动画
			userId: "",
			userInfo: undefined,
			typeInfo: {},
			addressInfo: {},
			dynamicList: [],
			showLoadMore: false,
			navbarTop: 0,
			currentPage: 1,
		}
	},
	// onPageScroll(e) { //nvue暂不支持滚动监听，可用bindingx代替
	// 	// console.log(e);
	// 	this.clientData.pageY = e.scrollTop;
	// },
	mounted() {
		if (this.$route.params.userId) {
				this.userId = this.$route.params.userId
			}

			console.log("UserHome mounted",this.userId);

		this.userLoginInfo();
		this.get_mine_dynamic();
	},
	methods: {
		navBack() {
			// uni.navigateBack();
		},
		absAction(value) {
			return Math.abs(value);
		},
		move(e) {
			// console.log(e);
			// 先判断用户是否到达了顶部
			if (this.clientData.pageY < 1) {
				// 本次手指移动的位置和上次移动的位置对比 <1 证明用户在下拉
				if (this.clientData.clientMoveY - e.changedTouches[0].clientY < 1) {
					// 拿到下拉的距离
					let distance = this.clientData.clientMoveY - e.changedTouches[0].clientY;
					// console.log(distance);
					// 最大下拉到800px ,放大1.8倍大小
					if (this.sheight < 1862) {
						this.sheight -= distance;
						// this.sheight = 1862
						// 执行动画
						this.isPlay = 1;
					} else {
						//超过800或者1.8倍就return
						return;
					}
				}
				// 上边的代码执行结束之后再把本次手指的位置赋值给data中,用来下一次对比
				this.clientData.clientMoveY = e.changedTouches[0].clientY;
			} else {
				setTimeout(() => {
					this.sheight = 562;
					// 并把动画的class名取消掉
					this.isPlay = 2;
				}, 350);
			}
		},
		// 手指抬起
		end(e) {
			// this.clientData.clientEndY = e.changedTouches[0].clientY;
			// 当图片的高度大于400的时候手指抬起再调用函数
			if (this.sheight > 562) {
				// 执行动画
				this.isPlay = 1;
				// 动画结束后把顶部图片的高度和放大比例该会原来的值
				setTimeout(() => {
					this.sheight = 562;
					// 并把动画的class名取消掉
					this.isPlay = 2;
				}, 350);
			}
		},

		created() {
			if (this.$route.params.userId) {
				this.userId = this.$route.params.userId
			}
		},
		// previewImges(imageUrls) {
		// 	let photoList = imageUrls.map((item) => {
		// 		return this.getImageFullUrl(item);
		// 	});
			// uni.previewImage({
			// 	current: 0,
			// 	urls: photoList,
			// });
		// },
		// handleChange(event) {
			// uni.navigateTo({
			// 	url: "/pages/company-home/compny-case/compny-case?userId=" + this.userId,
			// });
		// },

		getImageUrl(imageUrl) {
			return encodeURI(base_url + "/upimg/" + imageUrl);
		},

		getImageFullUrl(imageUrl) {
			return encodeURI(base_url + imageUrl);
		},

		userLoginInfo() {
			userInfoRequest({
				"UserPhone": this.userId ?? this.userPhone,
			}).then(resp => {
				console.log("用户详情 -- ", resp);
				this.userInfo = resp.info;
				this.typeInfo = this.userInfo.typeinfo;
				this.addressInfo = this.userInfo.AreaInfo;

			}).catch(err => {
				console.error('用户详情失败:', err);
			});
		},

		get_mine_dynamic() {
			get_mine_dynamicRequest({
				"UserPhone": this.userId ?? this.userPhone,
				'page': this.currentPage
			}).then(resp => {
				console.log("用户案例 -- ", resp);
				if (this.currentPage == 1) {
					this.dynamicList = resp.info;

				} else {
					if (resp.data.length > 0) {
						this.dynamicList = this.dynamicList.concat(resp.info);
					} else {
						// uni.showToast({
						// 	title: "暂无更多数据",
						// 	icon: 'none'
						// });
					}

				}

			}).catch(err => {
				console.error('用户案例失败:', err);
			});
		}

	},

	// onPullDownRefresh() {
	// 	console.log('触发了下拉刷新')
	// 	this.currentPage = 1;
	// 	this.get_mine_dynamic();
	// },
	// onReachBottom() {
	// 	console.log('页面触底了')
	// 	this.currentPage += 1;
	// 	this.get_mine_dynamic();
	// },
}
</script>

<style lang="less" scoped>
.pages {
	width: 100%;
	color: #191919;
	display: flex;
	flex-direction: column;
}

.bgc_pic {
	width: 100%;
	/* height: 562px; */
	/* height: 100%; */
	position: relative;
	/* margin-top: -100px; */
}

.nav-left-back {
	position: absolute;
}

.nav-left-back-icon {
	margin-right: 16px;
}

.bgc_pic>image {
	width: 100%;
}

.isPlay {
	animation: big 0.2s 1 alternate ease-in-out forwards;
}

@keyframes big {
	100% {
		/* margin-top: 100px; */
	}
}

.isPlaasf {
	animation: biasd 0.5s 1 alternate ease-in-out forwards;
}

@keyframes biasd {
	0% {
		/* margin-top: 100px; */
	}

	100% {
		margin-top: 0;
	}
}

.isPlatwp {
	animation: bis 0.2s 1 alternate ease-in-out forwards;
}

@keyframes bis {
	100% {
		/* height: 1862px; */
	}
}

.isPlafh {
	animation: dgsdf 0.5s 1 alternate ease-in-out forwards;
}

@keyframes dgsdf {
	0% {
		/* height: 1862px; */
	}

	100% {
		height: 562px;
	}
}

.content {
	border-top-right-radius: 30px;
	border-top-left-radius: 30px;
	// transform: translateY(-230px);
	background-color: white;
	display: flex;
	flex-direction: column;

}

.user-info {
	display: flex;
	align-items: center;
	padding: 30px;

}

.user-image {
	width: 64px;
	height: 64px;
	padding: 0;
	margin: 0;
	border-radius: 32px;
}

.item-user-info {
	display: flex;

}

.name-position {
	display: flex;
	flex-direction: column;
}

.company-name {
	color: black;
	font-size: 18px;
	padding-left: 15px;
}

.company-companyNameDetail {
	color: grey;
	font-size: 15px;
	padding-left: 15px;
	padding-top: 10px;
}

.tag {
	display: flex;
	flex-direction: row;
	/* position: absolute; */
	padding-left: 15px;
	padding-top: 15px;
	margin-bottom: 10px;
}

.tagChild {
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 5px;
	padding-bottom: 5px;
	margin-right: 10px;
	background-color: #ccc;
	color: white;
	font-size: 14px;
	text-align: center;
}

.company-star {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: absolute;
	right: 10px;
	top: 15px;
}

.star-image {
	width: 100px;
	height: 100px;
}

.company-star-content {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 15px;
	color: #C6AA87;
}

.star-title {
	color: #00000030;
	font-size: 20px;
}

.company-companyIntroduce {
	font-size: 15px;
	color: black;
	padding-left: 15px;
}

.scroll {
	white-space: nowrap;
	width: calc(100% - 20px);
	padding: 20px 20px 20px 15px;
	width: 100%;
	//   height: 150px; /* 你可以根据需要调整高度 */
	overflow-x: auto;
	overflow-y: hidden;
	white-space: nowrap;

	/* 防止内容换行 */
	.box1 {
		white-space: nowrap;
		display: inline-flex;
		align-items: center;

		.item {
			display: inline-flex;
			flex-direction: column;
			width: 80px;
			height: 60px;
			margin-right: 20px;
			padding: 5px;
			background-color: white;
			justify-content: center;
			align-items: center;
			border: 1px solid #0000001F;
			border-radius: 6px;
			font-size: 14px;
			box-shadow: #0000001F;
			color: #000000DD;

			:first-child {
				font-size: 20px;
				font-weight: bold;
				color: #C6AA87;
			}
		}
	}
}

.address {
	height: 30px;
	margin-left: 15px;
	margin-top: 10px;
	width: fit-content;
	padding-left: 25px;
	padding-right: 25px;
	border-radius: 20px;
	background-color: #C6AA8755;
	display: flex;
	align-items: center;

	image {
		width: 24px;
		height: 24px;
		margin-right: 10px;
	}

	text {
		font-size: 14px;
		color: white;
	}
}

.wonderful-case {
	color: black;
	font-size: 18px;
	margin-left: 15px;
	margin-top: 15px;
	margin-bottom: 15px;
}

.newpeopleView {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.newpeopleViewTitle {
	font-size: 38px;
	color: #000000;
}

.newpeopleViewSubTitle {
	font-size: 30px;
	color: #999;
	padding-top: 10px;
	padding-bottom: 30px;
}

.swiper {
	// height: px;
	white-space: nowrap;
	width: 100%;
	/* 设置swiper容器的高度 */
	margin-bottom: 30px;
}

.item-content {
	display: flex;
	padding-left: 8px;
}

.item-bg {
	margin-right: 16px;
	align-items: center;
	justify-content: center;
	position: relative;
}

.swiper-item {
	display: flex;
	flex-direction: column;
	box-shadow: 1px 1px 3px 3px rgba(0, 0, 0, 0.03);
	margin: 10px;
}


.item-image {
	// padding: 8px;
	// height: 240px;
	max-width: calc((100vw - 8*3px) / 2);
}

.item-info-text {
	padding: 30px 20px;
	color: white;
	position: absolute;
	left: 10px;
	bottom: 10px;
	display: flex;
	flex-direction: column;
	font-size: 28px;
	font-weight: bold;
}

.item-info-title {
	font-size: 28px;
	color: black;
}

.item-user-name {
	color: #0000008A;
	font-size: 24px;
}

.item-user-total-likes {
	color: #0000008A;
	font-size: 24px;
}

.load-more {
	background-color: white;
	color: #C6AA87;
	font-size: 14px;
	writing-mode: vertical-lr;
	/* 从上到下，从左到右 */
	/* 或者 */
	/* writing-mode: vertical-rl; 从上到下，从右到左 */
	text-align: center;
	/* 可根据需要调整文本水平对齐方式 */
	height: 200px;
	/* 可根据需要调整文本容器的高度 */
	line-height: 1.5;
	/* 可根据需要调整文本行高 */
}
</style>