<template>
  <div class="chat-window">
    <div class="messages">
      <ChatMessage v-for="(message, index) in messages" :key="index" :message="message" :chatUserInfo="chatUserInfo" />
    </div>
    <ChatInput @send-message="handleSendMessage" />
  </div>
</template>

<script>
import ChatMessage from './chat-message.vue';
import ChatInput from './ChatInput.vue';
import { getChatUserInfoRequest,getGetUserMessageRequest } from '@/api/index.ts';
import { mapState } from 'vuex';

export default {
  name: 'ChatWindow',
  // props: {
  //   chatId: {
  //     type: String,
  //     required: true
  //   }
  // },
  computed: {
    ...mapState(['userPhone','socketService'])
  },
  components: {
    ChatMessage,
    ChatInput
  },
  data() {
    return {
      messages: [
        // {
        //   content: 'Hello!',
        //   isSentByUser: true,
        //   type: 'text',
        //   time: '10:00 AM',
        //   avatar: 'path/to/user-avatar.jpg'
        // },
        // {
        //   content: 'Hi! How are you?',
        //   isSentByUser: false,
        //   type: 'text',
        //   time: '10:01 AM',
        //   avatar: 'path/to/other-avatar.jpg'
        // }
      ]
      ,
      chatId:0,
      chatUserInfo:{}
    };
  },
  created () {
    this.getGetUserMessage()
   
    if (this.$route.params.chatId) {
			this.chatId = this.$route.params.chatId
      this.socketService.chatId = this.chatId
      this.getChatUserInfo()
		}
  },
  methods: {
    getChatUserInfo() {
      getChatUserInfoRequest({
        "UserPhone": this.chatId
      })
        .then(response => {
          console.log("聊天用户",response);
          this.chatUserInfo = response.userbaseinfo
          this.socketService.chatUserInfo = this.chatUserInfo 
        })
        .catch(error => {
          console.error(error);
        });
    },
    getGetUserMessage () {
      getGetUserMessageRequest({
        "UserPhone": this.userPhone
      })
        .then(response => {
          console.log("聊天历史",response);
          this.messages = response.MsgList  
        })
        .catch(error => {
          console.error(error);
        });
    },
    handleSendMessage(message) {
      const newMessage = {
        ...message,
        // isSentByUser: true,
        // time: new Date().toLocaleTimeString(),
        // avatar: 'path/to/user-avatar.jpg' // 替换为实际用户头像路径
      };
      this.messages.push(newMessage);

      // 模拟接收到新消息
      setTimeout(() => {
        const replyMessage = {
          content: 'Got your message!',
          isSentByUser: false,
          type: 'text',
          time: new Date().toLocaleTimeString(),
          avatar: 'path/to/other-avatar.jpg' // 替换为实际对方用户头像路径
        };
        this.messages.push(replyMessage);
      }, 1000);
    }
  }
};
</script>

<style scoped>
.chat-window {
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-width: 100%;
  margin: auto;
  /* padding: 10px; */
}

.messages {
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
</style>